/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function MostActiveSpraysPerDay() {

	const { data } = useContext(DataContext);
	const { mostActive, sprays } = data;

	const total = mostActive.reduce((prev, u) => {
		const userId = u.id
		const sprays_per_day = sprays.filter((s) => s.user === userId);
		return prev + sprays_per_day.length;
	}, 0)

	return <PlainValue
		className="most-active-avg-sprays-per-day"
		label="Sprays in interval"
		value={total ? total : ''}
	/>
}

export default MostActiveSpraysPerDay;
