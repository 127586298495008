/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function MostActiveIntervalJournals() {

	const { data } = useContext(DataContext);
	const { mostActive, journals } = data;

	const total = mostActive.reduce((prev, u) => {
		const userId = u.id
		const journals_per_day = journals.filter((s) => s.user === userId);
		return prev + journals_per_day.length;
	}, 0)

	return <PlainValue
		className="most-active-journals-in-interval"
		label="Journals in interval"
		value={total ? total : ''}
	/>
}

export default MostActiveIntervalJournals;
