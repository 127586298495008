/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import { isFirefox } from 'utils/browser';


export default function MessageBackground({width, heigth}) {
    
    return <svg width="176" height="94" viewBox="0 0 176 94" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
        <clipPath id="message-background-blur">
            <path d="M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5H164C170.351 0.5 175.5 5.64873 175.5 12V78C175.5 84.3513 170.351 89.5 164 89.5H96.4853C94.7614 89.5 93.1081 90.1848 91.8891 91.4038C89.7412 93.5517 86.2588 93.5517 84.1109 91.4038C82.8919 90.1848 81.2386 89.5 79.5147 89.5H12C5.64872 89.5 0.5 84.3513 0.5 78V12Z" stroke="url(#paint1_linear_929_2791)" stroke-opacity="0.2"/>
        </clipPath>
    </defs>

    <foreignObject clipPath='url(#message-background-blur)' width='176' height='90'>
        <div style={{ 
            height: '100%', 
            width: '100%', 
            backdropFilter: 'blur(20px)', 
            WebkitBackdropFilter: 'blur(40px)',
            borderRadius: 10,
            overflow: 'hidden', 
            WebkitClipPath: 'url(#message-background-blur)', 
            clipPath: 'url(#message-background-blur)'}} />
    </foreignObject>
    

    <path  d="M0 12C0 5.37258 5.37258 0 12 0H164C170.627 0 176 5.37258 176 12V78C176 84.6274 170.627 90 164 90H96.4853C94.894 90 93.3679 90.6321 92.2426 91.7574C89.8995 94.1005 86.1005 94.1005 83.7574 91.7574C82.6321 90.6321 81.106 90 79.5147 90H12C5.37258 90 0 84.6274 0 78V12Z" fill="white" fill-opacity={isFirefox ? 0.7: 0.3}/>
    <path d="M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5H164C170.351 0.5 175.5 5.64873 175.5 12V78C175.5 84.3513 170.351 89.5 164 89.5H96.4853C94.7614 89.5 93.1081 90.1848 91.8891 91.4038C89.7412 93.5517 86.2588 93.5517 84.1109 91.4038C82.8919 90.1848 81.2386 89.5 79.5147 89.5H12C5.64872 89.5 0.5 84.3513 0.5 78V12Z" stroke="url(#paint0_linear_929_2791)" stroke-opacity="0.19"/>
    <path d="M0.5 12C0.5 5.64873 5.64873 0.5 12 0.5H164C170.351 0.5 175.5 5.64873 175.5 12V78C175.5 84.3513 170.351 89.5 164 89.5H96.4853C94.7614 89.5 93.1081 90.1848 91.8891 91.4038C89.7412 93.5517 86.2588 93.5517 84.1109 91.4038C82.8919 90.1848 81.2386 89.5 79.5147 89.5H12C5.64872 89.5 0.5 84.3513 0.5 78V12Z" stroke="url(#paint1_linear_929_2791)" stroke-opacity="0.2"/>
    
    <defs>
    <filter id="filter0_b_929_2791" x="-23" y="-23" width="222" height="139.515" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
    <feFlood flood-opacity="0" result="BackgroundImageFix"/>
    <feGaussianBlur in="BackgroundImage" stdDeviation="11.5"/>
    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_929_2791"/>
    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_929_2791" result="shape"/>
    </filter>
    <linearGradient id="paint0_linear_929_2791" x1="120" y1="70.1835" x2="56" y2="70.1835" gradientUnits="userSpaceOnUse">
    <stop stop-color="white"/>
    <stop offset="1" stop-color="white" stop-opacity="0"/>
    </linearGradient>
    <linearGradient id="paint1_linear_929_2791" x1="120" y1="70.1835" x2="56" y2="70.1835" gradientUnits="userSpaceOnUse">
    <stop stop-color="#0180F5"/>
    <stop offset="1" stop-color="#00F6CA" stop-opacity="0"/>
    </linearGradient>
    </defs>
    </svg>
}

