import React, { useContext, useState } from 'react';
import PlainValue from 'components/plain-value';
import { DataContext } from 'context/data';

function SpraysToday() {

	const { data } = useContext(DataContext);
	const { lastActive, sprays } = data;

	const localDate = new Date().toLocaleDateString('en-US');

	const count = sprays.reduce((prev, spray) => {
		const user = lastActive.filter((u) => u.id === spray.user)[0];
		if (!user) return prev; 
		if (!spray?.date?.toLocaleDateString) return prev;
		const userDate = spray.date.toLocaleDateString('en-US', { timeZone: user.timeZone  });
		return userDate === localDate ? prev + 1 : prev
	}, 0)

	const today = new Date().toLocaleDateString('en-GB')

    
	return <PlainValue
		className="sprays-today"
		label={"Sprays on " + today }
		value={count}
	/>
};

export default SpraysToday;