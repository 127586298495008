const mapDay = (day, length) => {
    while (day > length - 1) {
        day = mapDay(day - length, length)
    }
    return day;
}


const getRandom = (array) => {
    const index = mapDay(new Date().getDay(), array.length)
    return array[index]
}

const SLEEP_RESULTS = (code, sleep_var = '', intro_value_units_adj = '') => {
    
    const messages = {
        'result_copy_wait': [
            "patience is a virtue… still trying to figure it out!",
            "not quite there yet... but remember, results can take time! in the meantime, keep up the good work",
            "you're wonderful and marvellously unique! it's going to take some more time to accurately learn about you",
            "we don't have enough data yet, but we can't wait to share results as soon as we have any!"
        ],
        'result_copy_sleep_fail': [
            `we are confident that your current formulation has not made enough improvement to your ${sleep_var}. all good things take time and it's time to change to a new plant`,
            `your data tells us this plant might not be working for you and your ${sleep_var}. let's turn over a new leaf together and select a different formulation for you!`,
            `we are confident that your current formulation has not made enough improvement to your ${sleep_var}. luckily, they say a change is as good as rest; let's give another plant a try.`,
        ],
        'result_copy_sleep_bad': [
            `we are confident that your current formulation is not making an improvement to your ${sleep_var}. all good things take time and it's time to change to a new plant`,
            `your data tells us this plant isn't working for you and your ${sleep_var}. let's turn over a new leaf together and select a different formulation for you!`,
            `we are confident that your current formulation has not made an improvement to your ${sleep_var}. luckily, they say a change is as good as rest; let's give another plant a try.`,
        ], 
        'result_copy_sleep': [
            `brilliant! we are confident that your current formulation ${intro_value_units_adj}`,
            `we are confident that your current formulation ${intro_value_units_adj}. a beautiful friendship, indeed!`,
            `we are confident that your current formulation ${intro_value_units_adj}. may you always follow your dreams`,
            `incredible! we are confident that your current formulation ${intro_value_units_adj}.`,
            `we are confident that your current formulation ${intro_value_units_adj}. thank you for being uniquely you!`,
        ]
    }

    return getRandom(messages[code])
}

export const getMoodResults = (mood_p_result, mood_n_result) => {
    

    if ((mood_p_result) === null && (mood_n_result) === null) {
        return SLEEP_RESULTS('result_copy_wait');
    }

    if((mood_n_result) === null) {
        return mood_p_result > 0 
        ? 'we are confident that your current formulation is increasing the intensity of your positive emotions.'
        : 'we are confident that your current formulation is decreasing the intensity of your positive emotions.'
    } else if((mood_p_result)  === null) {
        return mood_n_result > 0 
        ? 'we are confident that your current formulation is increasing the intensity of your negative emotions.'
        : 'we are confident that your current formulation is decreasing the intensity of your negative emotions.'
    } else if (mood_p_result > 0) {
        return mood_n_result > 0
        ? 'we are confident that your current formulation is increasing the intensity of both your positive and negative emotions.'
        : 'we are confident that your current formulation is increasing the intensity of your positive emotions and decreasing the intensity of your negative emotions.'
    } else if (mood_p_result < 0) {
        return mood_n_result > 0
        ? 'we are confident that your current formulation is decreasing the intensity of your positive emotions and increasing the intensity of your negative emotions.'
        : 'we are confident that your current formulation is decreasing the intensity of both your positive and negative emotions.'
    }
}

export const getMoodDelay = (mood_p_result, mood_n_result, max_delay) => {
    if (!(mood_p_result) == null || !mood_n_result == null) return `spraying today has an effect for up to ${max_delay} days.`
}

export const getSleepDelay = (result, max_delay) => {
    if (result > 0) return `spraying today has an effect for up to ${max_delay} days.`
}

export const getSleepHoursResult = (sleep_h_result) => {
    if (sleep_h_result === null) return SLEEP_RESULTS('result_copy_wait')
    if (sleep_h_result > 0) return SLEEP_RESULTS('result_copy_sleep', null, `is helping you sleep ${sleep_h_result} hours more`)
    if (sleep_h_result < 0) return SLEEP_RESULTS('result_copy_sleep_bad', 'time asleep', null)
    if (sleep_h_result === 0) return SLEEP_RESULTS('result_copy_sleep_fail', 'time asleep', null)
}

export const getSleepQualityResult = (sleep_q_result) => {
    if (sleep_q_result === null) return SLEEP_RESULTS('result_copy_wait')
    if (sleep_q_result > 0) return SLEEP_RESULTS('result_copy_sleep', null, `is helping you sleep ${sleep_q_result}% better`)
    if (sleep_q_result < 0) return SLEEP_RESULTS('result_copy_sleep_bad', 'sleep quality', null)
    if (sleep_q_result === 0) return SLEEP_RESULTS('result_copy_sleep_fail', 'sleep quality', null)
}

