import React, { useContext } from 'react';
import './style.scss';
import firebase from 'firebase';
import MessageModal from 'components/message-modal';
import { useHistory } from 'react-router';
import { UserContext } from 'context/user';
import { DataContext } from 'context/data';
import { MeContext } from 'context/me';

function SignOutModal({ visible, setVisible }) {
	const history = useHistory();
  const { setUser } = useContext(UserContext)
  const { setData, setLoaded, clearData } = useContext(DataContext)
  const me = useContext(MeContext)

    const signOut = async () => {
    try {
      await firebase.auth().signOut();
      await sessionStorage.clear();
      await localStorage.clear();
      setUser({loaded: true});
      setData({});
      me.setData({});
      me.setLoaded(false);
      await clearData()
      setLoaded(false)
      setVisible(false);
      history.replace('/sign-in')
    } catch (err) {
    }
  }

  return <>
	  <MessageModal 
	  onConfirm={signOut}
		visible={visible}
		setVisible={setVisible} 
		prompt='are you sure you want to log out?' />
  </>
}

export default SignOutModal;
