/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import Card from 'components/card';
import Mood from 'pages/journalling/mood-last-entry/mood';
import { DataContext } from 'context/data';

const defaultMoods = [
  "anxiety",
  "tired",
  "refreshed",
  "excitement",
  "sadness", 
  "confident",
  "joy",
  "anger",
]

/*
{
        mood: defaultMoods[parseInt(mood)],
        percentage: 100 * (_moods[mood].length / totalUsers)
      }
*/

function MoodLastEntry(props) {

  const { data } = useContext(DataContext);
  const { journals } = data;

  const sorted_journals = [...journals].sort((a, b) => (
    b.endDate.getTime() - a.endDate.getTime()
  ))

  const mapped_users = [];

  let moods = sorted_journals.reduce((prev, journal) => {
    if (journal.type !== 1) return prev;
    if (mapped_users.includes(journal.user)) return prev;
    mapped_users.push(journal.user);
    
    const mood = defaultMoods[journal.mood]
    if (!prev[mood]) prev[mood] = 0;
    prev[mood] += 1;
    return prev;
  }, {});

  const total = Object.values(moods).reduce((prev, next) => prev + next, 0);

  moods = Object.keys(moods).map((mood) => ({
    mood,
    percentage: (moods[mood]/total) * 100
  }))

  moods.sort((a, b) => b.percentage - a.percentage);

  return <div className="mood-last-entry" style={props}>
    {moods.map((mood, i) => (
      <>
      <Mood {...mood} key={i}/>
      </>
    ))}
  </div>
}

export default Card(MoodLastEntry, false, 'Mood - Last Entry');