import React from 'react';
import long_arrow from 'assets/icons/long-arrow.svg';
import './style.scss';
import CardHOC from 'components/card';

const Card = ({
    name,
    description,
    url
}) => {

    return <div className='paper-card'>
        <h1>{name}</h1>
        <p>{description}</p>
        <div style={{flex: 1}} />
        <a className='paper-card__paper-link' href={url} target='_blank' rel="noreferrer">
            <div>read paper</div>
            <img src={long_arrow} alt='' />
        </a> 
    </div>

}

const WithCard = CardHOC(Card, false, '');

export default function paperCard(props) {

    const {className} = props;

    return <div className={className}>
        <WithCard {...props}/>
    </div>

}

