import { signIn, validateInvitation } from 'api/auth';
import { MessageContext } from 'context/message';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import './style.scss';

export default function ValidateSignUp() {
	const location = useLocation();
  const history = useHistory();
  const { pushError, pushMessage } = useContext(MessageContext);
  
  const validateToken = async () => {
    const token = location.search.replace('?token=', '');
    const result = await validateInvitation(token);
    if (result.data.userCreated) {
      await signIn(result.data.email, result.data.temporaryPassword);
      pushMessage("Welcome to Sofi!");
      history.replace('/create-password');
    }
    else {
      pushError("An error happened creating your user.")
      history.replace('/sign-in')
    }
  }
  
  useEffect(() => {
    validateToken();
  }, []);
	
	return <div className='sign-in'>
		<h1>We're validating your registration!</h1>
	</div>
}
