/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react';
import PlainValue from 'components/plain-value';
import ScssWrapper from 'components/scss-wrapper';
import './style.scss';
import LoadingUI from 'components/loading';
import MyResult from 'components/my-result';
import MyScore from 'components/my-score';
import UserSleepIndicator from 'components/user-sleep-indicator';
import EmotionalThermometer from 'components/emotional-thermometer';
import TreehouseTopics from 'components/treehouse-topics';
import { MeContext } from 'context/me';
import Invite from 'components/invite';
import { getLocaleTime } from 'utils/time';


const PlainValueWrapper = (props) => <PlainValue parent="me-dashboard" {...props} /> 
const Wrapper = (props) => <ScssWrapper parent="me-dashboard" {...props}/>

function Me() {

  const { loaded, sprays, journals, fetchTreehouseData} = useContext(MeContext);
  const meData = useContext(MeContext);

  
  useEffect(() => {
    fetchTreehouseData();
  }, [])

  if (!loaded) return <LoadingUI />;

  

  const lastSprayDate = sprays?.length 
  ? getLocaleTime(new Date(sprays[0].date))
  : '--'

  const lastJournalDate = journals?.length 
  ? getLocaleTime(new Date(journals[0].endDate))
  : '--'

  const mappedJournals = journals?.map((j) => ({
    ...j,
    endDate: new Date(j.endDate)
  }))

  const lastSprayFormulation = sprays?.length 
  ? 'no.' + (sprays[0].formulation + 1).toString().substring(0,1)
  : '--'
  
  const totalSprays = sprays ? sprays?.reduce((prev, spray) => {
    return prev + spray.sprayCount
  }, 0) : 0;

  return <>
    <div className="me-dashboard">
      <PlainValueWrapper 
        className="active-formulation" 
        label="Active Formulation" value={lastSprayFormulation}/>
      <PlainValueWrapper 
        className="total-sprays" 
        label="Total Sprays" value={totalSprays}/>
      <PlainValueWrapper 
        className="last-spray" 
        label="Last Spray" value={lastSprayDate}/>
      <PlainValueWrapper 
        className="last-journal" 
        label="Last Journal" value={lastJournalDate}/>
      <Wrapper className="emotional-thermometer">
        <EmotionalThermometer />
      </Wrapper>
      <Wrapper className="sleep-quality">
        <UserSleepIndicator journals={mappedJournals}/>
      </Wrapper>
      <Wrapper className="invite">
        <Invite/>
      </Wrapper>
      <Wrapper className="my-result">
        <MyResult meData={meData} />
      </Wrapper>
      <Wrapper className="my-score">
        <MyScore meData={meData} />
      </Wrapper>
      <Wrapper className="treehouse-trending">
        <TreehouseTopics name='trend' title='top conversations' />
      </Wrapper>
      <Wrapper className="treehouse-latest">
        <TreehouseTopics name='latest' title='you might be interested in' />
      </Wrapper>
    </div>
  </>
}

export default Me;
