import React from 'react';
import './style.scss';
import DownloadAllData from 'components/download-all-data';

export default function Footer() {

    return <footer className="footer">
        <div className="footer__company">
            sofi.health
        </div>
        {<div className="footer__download-report">
            <DownloadAllData />
        </div>}
        <div className="footer__rights">
            <div style={{flex: 1}} />
            all rights reserved 2021
        </div>
    </footer>

}