import React, { useContext } from 'react';
import LineChart from 'graphs/line-chart-with-fill';
import Card from 'components/card';
import { DataContext } from 'context/data';


const EmotionalSlider = (props) => {
  
  const { data } = useContext(DataContext);
  const { journals } = data;

  const closestMultiple = (n, x) => {
    
    const division = Math.floor(n / x);
    return division * x;
      
  }

  const HOUR_NUMBER = 17;
  const user_checked = []

  const users_average = journals.reduce((prev, journal) => {
    if (user_checked.includes(journal.user)) return prev;
    if (journal.type !== 0) return prev;
    user_checked.push(journal.user);
    const { values } = journal;

    const cM = closestMultiple(values.length, HOUR_NUMBER);
    const trimmed = values.slice(0, cM);
    const array_length = trimmed.length / HOUR_NUMBER;

    const hour_values =  trimmed.reduce((p, n, i) => {
      if (i % array_length === 0) p.push([]);
      p[p.length - 1].push(n);
      return p;
    }, []);

    return [...prev, hour_values.reduce((p, values) => {
      return [...p, values.reduce((_p, value) => _p + value, 0) / values.length];
    }, [])]   
  }, []);

  const averages = [];

  for (let i = 0; i < users_average.length; i++) {
    averages.push(users_average.reduce((prev, avgs) => prev + avgs[i], 0)/users_average.length)
  }

  props = {
    ...props,
    height: props.height - 20,
    width: props.width - 40,
  }

  return (
    <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
      <LineChart 
        {...props}
        data={averages} />
    </div>
  );
}

export default Card(
  EmotionalSlider, 
  false, 
  'Emotional Slider - Average',
);