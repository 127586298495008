import React, { useEffect, useState } from 'react';
import './style.scss';
import { useHistory } from 'react-router-dom';
import logo from 'assets/icons/logo.svg';
import { isFirefox } from 'utils/browser';

export default function Header({
	home =  '/sign-in', 
	midContent = <div />, 
	endContent = <div />
}) {
  const [blurDisabled, setBlurDisabled] = useState(true);
  const history = useHistory();
  
  const goToHome = () => history.replace(home);

  useEffect(() => {
    const onScroll = (e) => {
      const offset = window.pageYOffset
      if (offset > 80) setBlurDisabled(false);
      if (offset < 80) setBlurDisabled(true);
    }
    onScroll();
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, []);

  return <header>
    <div className="header__logo-wrapper">
      <img src={logo} onClick={goToHome} alt="logo" />
    </div>

    <div className="header__mid-wrapper">
      {midContent}
    </div>
      
    <div className="header__end-wrapper">
      {endContent}
    </div>

    <div
      style={isFirefox ? { backgroundColor: '#06081E'} : {}} 
      className={`header__background-gradient 
      ${blurDisabled ? 'disabled' : ''}`}/>
  </header>
}