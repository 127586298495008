/* eslint-disable eqeqeq */
import firebase from 'firebase';

const emulators = () => {

    if (process.env.REACT_APP_FIRESTORE_EMULATOR) firebase.firestore().useEmulator("localhost", 8080);
    if (process.env.REACT_APP_AUTH_EMULATOR) firebase.auth().useEmulator("http://localhost:9099");
    if (process.env.REACT_APP_STORAGE_EMULATOR) firebase.storage().useEmulator('localhost', 9199);

    firebase.functions().useEmulator("localhost", 5001);
};

export const getApp = () => {

    if (firebase.apps.length) return firebase.apps[0];

    firebase.initializeApp({
        apiKey: "AIzaSyCs_mQppiDU907xKlrllAyrUpkvjoilbPI",
        authDomain: "sofi-326000.firebaseapp.com",
        projectId: "sofi-326000",
        storageBucket: "sofi-326000.appspot.com",
        messagingSenderId: "144293003619",
        appId: "1:144293003619:web:69adcd14f31fbeb9849356",
        measurementId: "G-2X6991JVRX"
    });
    
    if (process.env.REACT_APP_STAGING) {
        emulators();
    };

    return firebase.apps[0];
};


export const getFunctions = () => {
    const thisApp = getApp();

    if (process.env.REACT_APP_STAGING) {
        return thisApp.functions()
    } else {
        return thisApp.functions('europe-west2');
    }
}

export const fn = (name) => {
    
    if (process.env.REACT_APP_STAGING) {
        return name;
    } else {
        return 'v2_' + name;
    }
}