const oneHour = 60 * 60 * 1000

export const timeOptions = [
  {
    name: '24 hours',
    value: oneHour * 24
  },
  {
    name: '72 hours',
    value:  oneHour * 72
  },
  {
    name: 'One Week',
    value: oneHour * 24 * 7
  },
  {
    name: '30 days',
    value: oneHour * 24 * 30
  },
  {
    name: 'All Time',
    value: oneHour * 24 * 10000
  }
]

export const programOptions = [
  {
    name: 'S',
    value: 'S',
  },
  {
    name: 'C',
    value: 'C',
  },
  {
    name: 'ROGUE',
    value: 'NULL',
  },
]

export const groupOptions = [
  {
    name: '1',
    value: 1,
  },
  {
    name: '2',
    value: 2,
  },
]


export const getGender = (gender) => {
    return gender === '0' ? 'women' : gender === '1' ? 'nb' : 'men'
  }
  
export const ageRanges = [{
    upper: 20
  },
  {
    lower: 21,
    upper: 30
  },
  {
    lower: 31,
    upper: 40
  },
  {
    lower: 41,
    upper: 50,
  },
  {
    lower: 51,
    upper: 60
  },
  {
    lower: 61
}]
  