/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect }from 'react';
import LineChart from 'graphs/line-chart-locale';
import Card from 'components/card';
import { DataContext } from 'context/data';





const AverageSprayTime= () => {
  
  const { data } = useContext(DataContext);
  const {lastActive, sprays} = data;

  const sprayData = sprays.reduce((prev, spray) => {
    const user = lastActive.filter((u) => u.id === spray.user);
    if (!user[0]) return prev
    if (!spray?.date) return prev;
    
    const hourAtUser = new Date(spray.date).toLocaleTimeString('en-US', {hour12:false, timeZone: user[0].timezone })
    .substr(0, 2);

    const date = parseInt(hourAtUser) > new Date().getHours() 
    ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    : new Date();

    date.setHours(parseInt(hourAtUser));

    return [...prev, {
      date
    }]

  }, [])


  return (
      <LineChart data={sprayData} />
  );
}


export default Card(AverageSprayTime, false);