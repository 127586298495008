/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,  useState } from 'react';
import './style.scss';
import Dot from 'assets/icons/dot';
import { ReactComponent as Three } from 'assets/sleep-circle/three.svg';
import { ReactComponent as Six } from 'assets/sleep-circle/six.svg';
import { ReactComponent as Nine } from 'assets/sleep-circle/nine.svg';
import { ReactComponent as Glow } from 'assets/sleep-circle/glow.svg';
import { degToRad } from 'three/src/math/MathUtils';
import * as d3 from 'd3';

//Sleep quality between 0 and 1;
const SleepCircleBorder = ({ sleepQuality }) => {

    let interval;
    let minAngle = 90;
    let maxAngle = 180;
    const mapper = d3.scaleLinear().domain([1, 0]).range([minAngle, maxAngle]);

    const [angle, setAngle] = useState(0)
    const finalAngle = mapper(sleepQuality);
    
    useEffect(() => {
        interval = setInterval(() => {
            step();
        }, 10)
    }, [])

    function step() {
        setAngle((a) => {
            if (a >= finalAngle) clearInterval(interval);
            return a + 1;
        });
    }

    const size = 305;
    const radius = size/2;
    const dotRadius = radius - 1;
    const xPos = (size/2) + (radius * Math.sin(degToRad(angle)));
    const yPos = (size/2) + (radius * Math.cos(degToRad(angle)));
    const xPosDot = (size/2) + (dotRadius * Math.sin(degToRad(angle)));
    const yPosDot = (size/2) + (dotRadius * Math.cos(degToRad(angle)));

    const x = xPos + 'px';
    const y = yPos + 'px';
    const xDot = xPosDot + 'px';
    const yDot = yPosDot + 'px';

    const maskOuterRadius = (radius) + 'px';
    const maskInnerRadius = (radius - 2) + 'px';
    const center = (size/2) + 'px';

    return <div style={{height: '100%', width: '100%'}}>
    <Dot x={xDot} y={yDot} />
    <svg height='100%' width='100%' >
        <defs>

            <radialGradient id="grad1" cx="50%" cy="50%" r="50%" >
                <stop offset="0%" stop-color='white'/>
                <stop offset="30%" stop-color='white' stopOpacity='0.2'/>
                <stop offset="80%" stop-color='white' stopOpacity='0'/>
            </radialGradient>`
            <mask id='clip'>
                <circle cx={center} cy={center} r={maskOuterRadius} fill='white'/>
                <circle cx={center} cy={center} r={maskInnerRadius} fill='black'/>

            </mask>
            </defs>
            <g mask='url(#clip)' height='100%' width='100%'>
                <circle cx={y} cy={x} r='200px' fill='url(#grad1)' />
            </g> 
        </svg>
    </div>

    
    
}

export default function SleepCircle({topText = 'two nights ago', sleepHours = 9, sleepQuality = 0.0, visible}) {
        
    if (!visible) return <div style={{display: 'none'}}/>

    const Background = sleepHours < 6 
    ? Three
    : sleepHours < 9 
    ? Six
    : Nine; 

    const sleepQualityClass = sleepQuality < 0.35 
    ? 'poorly'
    : sleepQuality < 0.75 
    ? 'neutral'
    : 'great'

    const Eclipse = () => <div 
        className={'sleep-circle__eclipse '+sleepQualityClass}
    ></div>

    return <div className='sleep-circle'> 
        <h3 className='sleep-circle__top-text'>{topText}</h3>
        <div className='sleep-circle__border'>
            <SleepCircleBorder sleepQuality={sleepQuality} />
            <h3 className='sleep-circle__poor'>poor</h3>
            <h3 className='sleep-circle__amazing'>amazing</h3>
        </div>
        <div className='sleep-circle__inner'>
            <div className='sleep-circle__wrapper'>
                <Eclipse />
            <Background />
            </div>
            <div className='sleep-circle__glow'>
                <Glow />
            </div>
            <h1 className={'sleep-circle__hours ' + sleepQualityClass}>
                {sleepHours.toFixed(1)}
                <p>
                    hours
                </p>
        </h1>
    </div>
    </div>
}