import firebase from 'firebase';

export const uploadProfilePic = async (userId, event) => {
  
	try {
		var file = event.target.files[0];
		const extension = file.type.replace('image/', '');
		const storage = firebase.app().storage();
  		let ref = storage.ref();
		ref = ref.child(`profile_pic/${userId}.${extension}`);
		var uploadTask = ref.put(file);
		
		await new Promise((resolve, reject) => uploadTask.on('state_changed', function(snapshot){
			var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
			if ( progress === 100) resolve();
			else if (snapshot.state === firebase.storage.TaskState.PAUSED ||
				snapshot.state === firebase.storage.TaskState.ERROR) reject();
			}
		));
	} catch (err) {
	}
		
}

export const getProfilePicURL = async (userId) => {
	try {
		const storage = firebase.storage().ref();
		const url = await storage.child(`profile_pic/${userId}.jpeg`).getDownloadURL();
		return url;
	} catch (err) {
	}
}