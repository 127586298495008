/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import * as d3 from  'd3';
import './/style.scss'
import { color as getGenderColor } from 'utils/gender';
import { useVisible, useWrapperSize } from 'utils/hooks';

export default function BarCharts (props) {

  const { 
    data,
    wrapper,
  } = props;

  const { height } = useWrapperSize(wrapper)

  const ref = useRef(null);
  
  const visible = useVisible(ref, 100)

  const chartRelativeTotals = data.map((d) => {
    const singleChartTotal = Object.values(d.values).reduce((prev, next) => prev + next, 0);
    return singleChartTotal
  });
    
  const renderSingleChart = (data) => {
    const values = Object.values(data.values);
    const keys = Object.keys(data.values);

    const colorPicker = (i) => {
      const color = getGenderColor(keys[i]);      
      return `linear-gradient(0deg, ${color},0) 0%, ${color},1) 100%)`;
    }
    
    const singleChartTotal = values.reduce((prev, next) => prev + next, 0);

    const heightScale = d3.scaleLinear()
    .domain([0, d3.max(chartRelativeTotals)])
    .range([5, height])

    const yScale = d3.scaleLinear()
    .domain([0, singleChartTotal])
    .range([5, heightScale(singleChartTotal)]);

    const barChart = d3.select('#bar-chart')
    .append('div')
    .attr('class', 'single-bar-chart')
    .style('height', height + 'px')
  
    const wrapper = barChart.append('div')
    .attr('class', 'single-bar-chart__wrapper')

    barChart.append('div')
    .attr('class', 'single-bar-chart__underline')

    barChart.append('div')
    .attr('class', 'single-bar-chart__ages')
    .html(data.ages)
    
    const bar = wrapper.selectAll('whatever')
    .data(values)
    .enter()
    .append('div')
    .attr('class', 'single-bar-chart__bar')
    
    bar.style('height', '0px')
    .style('background', (key, i) => colorPicker(i));

    bar.transition().duration(1000).style('height', (d) => yScale(d) + 'px') 
  }

  useEffect(() => {
    if (height < 0 || !visible) return;
    data.forEach(renderSingleChart);
  }, [height, visible]);

  return <div 
  ref={ref} id="bar-chart" style={props} />

}