/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './style.scss';
import deltaArrow from 'assets/icons/arrow.svg';

const mockOptions = [{
    name: 'option 1'
}, {
    name:'option 2'
}]

export default function Dropdown({
    options = mockOptions, 
    disabled,
    selected = mockOptions[0], 
    setSelected = () => {},
}) {

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const _setSelected = (option) => {
        setDropdownVisible(false);
        setSelected(option)
    }   

    const disabledStyle = disabled ? {
        opacity: 0.5,
        pointerEvents: 'none'
    } : {}

    return <div className='dropdown-clean' style={disabledStyle}>
        <div onClick={() => setDropdownVisible(true)} style={{display: 'flex'}} className='dropdown-clean__box'>
            <div style={{ marginRight: 10 }}>{selected.name ? selected.name : 'none'}</div>
            <img 
                src={deltaArrow} 
                style={dropdownVisible ? {transform: 'rotate(180deg)', opacity: 0.5} : {opacity: 0.5}}/>
        </div>
        {dropdownVisible && <div className='dropdown-clean__options'>
            {options.map((option) => 
                <div 
                    onClick={() => _setSelected(option)} 
                    className='dropdown__option'>
                        {option.name}
                </div>)}
        </div>}
        {dropdownVisible && <div onClick={() => setDropdownVisible(false)} className='dropdown-clean__mask'/>}
    </div>
}