/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import Card from 'components/card';
import React, { useEffect, useState } from 'react';
import { getCleanFullDate } from 'utils/time';
import './style.scss';
import LineChartEmotional from 'graphs/line-chart-emotional';
import { 
  freeFormEntry, 
  largeFormulationSurveyEntry, 
  shortSurveyEntry,
  formulationSurveyEntry,
  emotionalSliderEntry, } from 'utils/survey';
import { color } from 'utils/formulation';

function _EmotionalSurvey(props) {


  const { date, formulation } = props;
  const line_color = color(
    formulation + 1, 
    'borderColor');

  return <div className='all-surveys__survey' >
  <div 
  style={line_color} 
  className="all-surveys__header">
    <h2>emotional survey</h2>
    <h3>{getCleanFullDate(date)}</h3>
    <div className='all-surveys__dot' style={color(formulation + 1)}></div>
  </div>
  <LineChartEmotional number={props.number} data={props.results} width={375} height={250}/>
  </div>

}

function _Survey(props) {
  const { name, date, results, formulation } = props;
  const line_color = color(
    formulation + 1, 
    'borderColor');

  const Field = ({answer, question}) => (
    <div className="main-pioneer-data__survey-field">
    <div className="main-pioneer-data__field-name">{question}</div>
    <div className="main-pioneer-data__field-answer">{answer}</div>
    </div>
  )

  return <div className='all-surveys__survey' >
    <div 
    style={{borderColor: line_color}} 
    className="all-surveys__header">
			<h2>{name}</h2>
			<h3>{getCleanFullDate(date)}</h3>
      <div className='all-surveys__dot' style={color(formulation + 1)}></div>
		</div>
    {results.map((d) => <Field {...d} /> )}
  </div>
}

const style = { height: 'min-content', marginRight: 20}
const Survey = Card(_Survey, true, '', style);
const EmotionalSurvey = Card(_EmotionalSurvey, true, '', style);

function AllSurveys({journals, ...props}) {

  const [data, setData] = useState([]);

  const formatData = () => {

    const _data = journals.flatMap((survey) => {
      survey.date = new Date(survey.endDate);
      const type = survey.type;
      const formatted = type == 0 
      ? emotionalSliderEntry(survey)
      : type == 2
      ? freeFormEntry(survey)
      : type == 4
      ? largeFormulationSurveyEntry(survey)
      : type == 5
      ? formulationSurveyEntry(survey)
      : type == 6
      ? shortSurveyEntry(survey)
      : [];

      return formatted;
    })
    
    _data.sort((a, b) => b.date - a.date);
    setData(_data);
  }

  useEffect(() => {
    formatData();
  }, [journals])

  if (!data?.length) return <div />

	return <div className='all-surveys__wrapper'>
    <div className='all-surveys' >
      {
        data.map((d, i) => (
          d.isEmotionalSliderEntry ? <EmotionalSurvey {...d} number={i} /> :
          <Survey {...d} />
        ))
      }
    <div style={{width: 60}} />
    </div>
  </div>

}

export default AllSurveys;

