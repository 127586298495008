/* eslint-disable react-hooks/exhaustive-deps */
import * as d3 from "d3";
import React, { useEffect, useRef } from 'react';
import { useVisible } from "utils/hooks";

function Doughnut({width, height, data}) {

  const ref = useRef(null);
  const visible = useVisible(ref)
  useEffect(() => {

    const render = () => {
      var width = 270;
      var height = 270;

      var svg = d3.select("#doughnut")
      svg.attr("width", width)
      svg.attr("height",height)

      const defs = svg.append('svg:defs')

      const start = 0.3;
      var pie = d3
      .pie()
      .value((d) => d.value)
      .padAngle(0.1)
      .startAngle(start)
      .endAngle(2 * Math.PI + start);

      const final_data = pie(data)

      final_data.forEach((d, i) => {

        let {startAngle, endAngle} = d;
        const { color } = data[i]

        
        const g = defs
        .append('clipPath')
        .attr("id", `clip${i}`)
        .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")");
        
        svg.append('foreignObject')
        .attr('x', 0)
        .attr('y', 0)
        .attr('clip-path', `url(#clip${i})`)
        .attr('width', width)
        .attr('height', height)
        .append('xhtml:div')
        .style('width', '100%')
        .style('height', '100%')
        .style('background-image', `conic-gradient(from ${startAngle}rad, rgba(0,0,0,0.0) 0%, ${color} ${endAngle - startAngle}rad)`)
        //.style('background-color', `red`)
        .html(" ")
       

        var arcs = g.selectAll("arc")
        .data([final_data[i]])
        .enter();

        var arc = d3.arc()
        .startAngle(startAngle + 0.2)
        .endAngle(startAngle)
        .innerRadius((width/2) - 20)
        .outerRadius(width/2)
        .padAngle(0.04)
        .padRadius(100)
        .cornerRadius(1000)

        /*
        var final = d3.arc()
        .innerRadius((width/2) - 20)
        .outerRadius(width/2)
        .padAngle(0.04)
        .padRadius(100)
        .cornerRadius(1000);
        */

        var final = d3.arc()
        .startAngle(startAngle)
        .innerRadius((width/2) - 20)
        .outerRadius(width/2)
        .padAngle(0.04)
        .padRadius(100)
        .cornerRadius(1000)

        var result = arcs.append("path")
        .attr("strokeWidth", 20)
        .attr("strokeLinecap", "round")
        .attr("d", arc)
        

        result
        .transition()
        .duration(1500)
        .ease(d3.easeCubic)
        .attrTween('d', () => {
          const interpolateEnd = d3.interpolate(startAngle, endAngle);
          return (t) => {
            const d = {
              endAngle: interpolateEnd(t)
            }
            return final(d);
          }
        })
        
        
      })
        
    }


    if (visible) render();
  },[visible, height, width, data]);


  return (
    <svg 
    ref={ref}
    style={{display: visible ? 'initial' : 'none'}}
    className="doughnut__wrapper" 
    id="doughnut" height={height} width={width} />
  );
};


export default Doughnut;
