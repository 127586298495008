import React, { useContext, useState, useEffect } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function BottleDuration() {

	const { data } = useContext(DataContext);
	const { sprays } = data;
	
	const counts = sprays.reduce((prev, spray) => {
		if (!prev[spray.user]) prev[spray.user] = 0
 		prev[spray.user] += spray.sprayCount;
		return prev;
	}, {})

	
	Object.keys(counts).forEach((user) => {
		counts[user] = 71/counts[user]
	});

	const total_avg = Object.values(counts).reduce((prev, next) => prev + next, 0)/Object.keys(counts).length
	
	return <PlainValue
		className="bottle-lasts"
		label="A bottle lasts" 
		value={total_avg.toFixed(0) + ' days'}
	/>
}

export default BottleDuration;

