import { acceptTerms } from 'api/auth';
import ConfirmButton from 'components/confirm-button';
import { UserContext } from 'context/user';
import React, { useContext, useState } from 'react';
import './Terms.scss';

export default function Terms() {

    const [loading, setLoading] = useState(false);
    const { user, forceUpdateUser } = useContext(UserContext);

    const _acceptTerms = async () => {
        setLoading(true);
        await acceptTerms(user.uid);
        forceUpdateUser();
        setLoading(false);
    }

    return <div className='terms'>
        <h1>S1 Pioneer Program</h1>
        <h2>Terms + Conditions</h2>
        <p>Dear Pioneer,</p>
        <p>
            The terms and conditions of our Pioneer Program at sofi are explained here. We have tried to be clear and
            concise in explaining why we capture your experience with plants, what types of data we collect during the
            Pioneer Program and how we use that to make discoveries as to whether or not any of the formulations have had
            a positive effect.
        </p>
        <p>
            We set this out in three parts, firstly explaining how we use the data to make better discoveries for you. We have
            also made a complete list of all the data points captured when you create an account, fill out questionnaires, and
            journal. To identify if a plant is making a meaningful impact for you, we must understand your usage history and
            response to that plant.
        </p>
        <p>
            Finally, we have outlined a set of legal principles consistent with any pilot program at the end. It is critical that
            you fully see why we are capturing the data, what data we are capturing, how we are using it and that you feel
            comfortable with this and wish to be an active part of the Program.
        </p>
        <ul>
            <li>Part I – The importance of you</li>
            <li>Part II – The data we collect during the S1 Pioneer Program</li>
            <li>Part III – Your consent to take part in the S1 Pioneer Program</li>
        </ul>
        <p>
        We are not fans of long and convoluted terms and conditions designed to get you to scroll to the end and accept.
        To that end, we have tried our best to keep this short, clear and concise. Please read parts I and II before
        proceeding to the consent section in part III, and if you have any questions, comments or requests, don&#39;t hesitate
        to get in touch with hello@sofi.health. Our objective is to be fully transparent at all times and to be worthy of your
        trust.
        </p>
        <h3>
            Part I – The importance of you
        </h3>
        <p>
            Plants are our oldest technology, and, at sofi, it's our mission to help you reconnect with them.        </p>
        <p>
        The heart of sofi is not technology, an algorithm or some biochemistry. It's you. And that makes you the
essential part of this adventure. You are the Pioneer on this journey, and our mission is to chart your progress
and help discover which plants make a difference for you. Without your input, we can't go very far. So we will
ask questions and encourage you to journal often.
        </p>
        <p>
        We aim to chart how you feel over time to see which plants, when, and how they can help create balance in the
right direction. To do this, we need to listen very carefully, and you need to share often. We will not share your
data with any third party. We will only collect personal information that helps us understand which formulations
work for you. This will enable us to work on sofi continually and, in turn, make it a more effective platform for all.
        </p>
        <p>
        We thank you for sharing your data and promise to use it respectfully. sofi will collect data to be analysed and
interpreted for you, not for the sake of collecting data, nor selling it, nor sending targeted advertising to you.
sofi will use the data you provide through your journaling and pod use to analyse the effectiveness of plants on
you and you alone. We know that we are all unique and must listen at the individual level to understand how
plants can improve how we feel and sleep. In the scientific community, we call this type of statistical analysis
“N-of-1”. Nonetheless, anonymised aggregated data from all Pioneers will also be used to suggest
improvements to your dosage, journaling and pod use habits; and to benchmark the effect of plants against the
wider population. Much like your data proper, this data is produced for you alone and will not be sold or shared
other than with you.
        </p>
        <p>
            TTypically, when a product in human health is created, it is made for the herd. It's not specifically for any one of
us. One hundred people are put in a study group and another hundred in a control group. One group is given
the product, and the other placebo to see what happens. The goal is to find a product that works for some
people, most of the time. What sofi does differently is that we break that single study of 100 people into one
hundred studies of one person. Our sample size is at the "N-of-1" level - you. sofi’s "statistical brain" will rigorously assess the effects of your sofi experience on how you feel and how you sleep based on your unique
experience with a particular plant
        </p>
        <p>
        Once we are 95% confident that we have modelled the relationship between your desired outcome and your
plant experience correctly, sofi will let you know your result through the dashboard at my.sofi.health. In the
meantime, your sofi score is also displayed on that dashboard. The sofi score is there to help guide your plant
use and journaling routine to maximise the positive effects and accelerate the N-of-1 analysis as much as
possible.
        </p>
        <p>
        For a step-by-step explanation of how to activate your sofi account, baseline yourself with the sofi app, use your
sofi stick or journal your experience during the Pioneer Program, please visit our "how to pioneer" page by
clicking here. If you require any additional support or encounter an issue with the sofi Pioneer kit that requires
assistance from sofi, please let us know at hello@sofi.health. We are here to support you throughout the
Pioneer Program.
        </p>
        <h3>
            Part II – On what data we collect during the S1 Pioneer Program
        </h3>
        <p>sofi will generate and capture journal, pod use, user and survey data to enable statistical analysis and make
meaningful discoveries for you. If you would like to see a complete list of all data generated and analysed
during the S1 Pioneer Program, please <a href='https://sofi.health/s1pioneerdata/' target='_blank'>click here</a></p>
        <p>
        We will be anonymising the data we collect; this means we will replace information in the dataset that identifies
you with one or more artificial identifiers or pseudonyms. We will store the additional information needed for
linking the pseudonymised data to you separately and securely. For information on how your data may be
used, how you might correct it and have access to it, please see our Privacy Policy by <a href='https://sofi.health/privacy-policy/'>clicking here</a>.
        </p>
        <h3>
            Part III – On consenting to take part in the S1 Pioneer Program
        </h3>
        <ol>
            <li>
            I understand that sofi will provide me access to a sofi app and deliver the Pioneer kit to me at its
expense (which will remain sofi’s property). I agree to test and evaluate the sofi Pioneer kit following
the instructions provided to me by sofi. I will notify sofi by using the contact feature within the sofi
app or by email (hello@sofi.health) of any functional flaws, errors or problems I discover while using
the Sofi kit. I agree to provide sofi with the necessary responses to the S1 Pioneer Program and
promptly respond to all journaling, reasonable enquiries, or questionnaires sent to me via the sofi
app or by email as required. I agree that any feedback, ideas, modifications, suggestions,
improvements and the like that I send to sofi will be my own thoughts and will become the sole
property of sofi.
            </li>
            <li>
            I agree to take care of the sofi Pioneer kit and return it to sofi upon request at sofi’s expense. I will
notify sofi as soon as I can if the sofi Pioneer kit is lost, stolen or damaged.
            </li>
            <li>
            I represent that the information about me, provided to sofi is and will be, to the best of my
knowledge, truthful and accurate. I will not:
            </li>
            <ol type='A'>
                <li>disclose or share my sofi Pioneer account login information with anyone else;</li>
                <li>create more than one Pioneer account;</li>
                <li>knowingly provide inaccurate information;</li>
                <li>access or use my participation as a Pioneer to build a competitive product or service or
                benchmark with another product or service.</li>
            </ol>
            <li>
            I understand that as a Pioneer, I will have privileged access to valuable information belonging to sofi
and which sofi considers confidential. I confirm that I will not, at any time, without sofi’s prior written
permission, disclose this information to third parties or use it for my own or a third party's potential
or actual commercial benefit. I understand that the information relates to a new product, so this
confidentiality is designed to protect sofi’s intellectual property.
            </li>
            <li>
            I understand that sofi is endeavouring to ensure that the sofi Pioneer kit is compliant with all relevant
safety and performance standards. As the sofi Pioneer kit is at a pre-release product at the testing
and validation stage, I acknowledge that sofi is not in a position to provide any warranty of any kind.
To the extent not prohibited by law, I agree sofi will not be liable for any damages arising out of my
use of the sofi Pioneer kit. I agree that sofi is not obliged to provide or maintain insurance for the
sofi Pioneer kit.
            </li>
            <li>
            I understand that my participation is voluntary. I am free to withdraw my consent from taking part in
the S1 Pioneer Program at any point without giving any reason and without my legal rights being affected. If I wish to do so, I will notify you by emailing hello@sofi.health and arrange to return my
sofi Pioneer kit (including the sofi stick and the sofi plant formulation pods) to you.
            </li>
            <li>
            I understand that relevant sections of my data collected during the S1 Pioneer Program will be
reviewed and analysed by the sofi team. I permit sofi and these individuals to have access to my data
and to process it.
            </li>
            <li>
            I understand that the information collected about me by sofi will be used to support other research
in the future and may be published in an aggregated and anonymised way such that no personally
identifiable information about me is ever released by sofi.
            </li>
            <li>
            I understand that my data collected during the S1 Pioneer Program will not be disclosed, transferred
or sold to any third party.
            </li>
            <li>
            I agree that sofi may communicate with me electronically regarding my participation in the sofi
Pioneer Program.
            </li>
            <li>
            I confirm that I am either over 18 years of age or, if younger, am over 13 and have the consent of my
parent or guardian.
            </li>
            <li>
            I understand that the laws of England & Wales govern them and that even if I enrol in the Program
from elsewhere, I submit to the jurisdiction of the courts of England & Wales for all purposes of my
participation in the S1 Pioneer Program.
            </li>
            <li>
            I have had the opportunity to consider the Terms + Conditions and ask questions. Any questions
have been answered satisfactorily.
            </li>
            <li>
                I have read, understood, and agree to sofi&#39;s Terms + Conditions.
            </li>
        </ol>
        <p><i>
            Thank you so much for being a sofi Pioneer and for giving us your trust.
            We are committed to finding out if medicinal plants can make a
            difference for you. Should you have any questions about anything in this
            document or about the Pioneer Program, please get in touch. Also, if
            you think we have missed capturing something important to you, please
            let us know. You can message us anytime. Xo
        </i></p>
        <div className='terms__confirm-button-wrapper'>
        <ConfirmButton 
            type='submit'
            label="I accept the terms"
            onClick={_acceptTerms}
            loading={loading}
            disabled={false} />
        </div>
    </div>
}
