import React from 'react';
import './style.scss'
import ReactLoading from 'react-loading';

export default function LoadingPane() {
  return <>
  <div className="loading-pane">
    <ReactLoading type="spinningBubbles" color="white" />
  </div>
  </>
}