import ConfirmButton from 'components/confirm-button';
import OneLineInput from 'components/one-line-input/password';
import React, { useContext, useState } from 'react';
import firebase from 'firebase';
import { useHistory, useLocation } from 'react-router';
import './style.scss';
import { MessageContext } from 'context/message';

export default function NewPassword() {

	const [newPassword, setNewPassword] = useState('');
	const { pushMessage, pushError } = useContext(MessageContext);
	const location = useLocation();
	const history = useHistory();
	const params = new URLSearchParams(location.search);
	const oobCode = params.get('oobCode');
  	const handlePasswordChange = async () => {
		try {
			await firebase.auth().confirmPasswordReset(oobCode, newPassword);
			pushMessage("You successfully changed your password. Please sign in again.")
		} catch (err) {
			pushError("An error happened, please request your password change again");
		} finally {
			history.push('/sign-in');
		}
	};

  	const canSubmitPassword = newPassword.length > 5;

	return <div className='forgot-password'>
		<h1>Set your new password</h1>
    	<OneLineInput label="new password" value={newPassword} setValue={setNewPassword} />
		<ConfirmButton label="continue" onClick={handlePasswordChange} disabled={!canSubmitPassword} />
	</div>

}