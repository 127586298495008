/* eslint-disable react-hooks/exhaustive-deps */
import * as d3 from "d3";
import React, { useEffect, useRef, useState } from 'react';
import "./style.scss"
import {
  getNiceYesterday,
  getNiceNow,
  getMinutesBetweenDates,
  getHoursBetweenInterval,
} from 'utils/time';
import Night from 'assets/icons/night.js';
import Sunset from 'assets/icons/sunset.js';
import Sunrise from 'assets/icons/sunrise.js';
import Day from 'assets/icons/day.js';
import reactElementToJSXString from "react-element-to-jsx-string";
import { useVisible, useWrapperSize } from "utils/hooks";

function LineChart({data, visibleOffset}) {
  
  const ref = useRef(null);
  
  const {height, width} = useWrapperSize(ref)

  useEffect(() => {
    if (ref.current) {
      const card = ref.current.parentNode.parentNode;
      card.style.overflow = "visible"
    }
  }, [])

  const [clusters, setClusters] = useState([]);
  
  const visible = useVisible(ref, visibleOffset);

  const clusterData = () => {
    let _clusters = {}

    const allHours = getHoursBetweenInterval(getNiceYesterday(), getNiceNow())

    const cluster = (d) => {
      const date = new Date(d.date);
      date.setMilliseconds(0);
      date.setMinutes(0);
      date.setSeconds(0);
      const key = date.toISOString();
      if (date < getNiceYesterday() || date > getNiceNow()) return;
      if (!_clusters[key]) _clusters[key] = 1;
      else _clusters[key] += 1;
    }
    
    if (!clusters) return

    if (!data.forEach) return

    data.forEach(cluster);

    _clusters = allHours.map((date) => {
      const pioneers = _clusters[date.toISOString()] ? 
      _clusters[date.toISOString()] : 0
      return { date , pioneers  }});

    _clusters.sort((a, b) => new Date(a.date) - new Date(b.date))
        
    var dates = getMinutesBetweenDates(getNiceYesterday(), getNiceNow());
    const interpolator = d3.interpolateBasis(_clusters.map((c) => c.pioneers));
    const pioneers = d3.quantize(interpolator,dates.length);
    _clusters = dates.map((d, i) => ({ date: d, pioneers: pioneers[i] }))
    setClusters(_clusters);
  };

  


  useEffect(() => {

    
    if (!ref.current) return;
    
    const xScale = d3.scaleTime()
    .domain([getNiceYesterday(), getNiceNow()])
    .range([0, width]);

    const yScale = d3.scaleLinear()
    .range([height - 90, 30 ]) //height of data
    .domain(d3.extent(clusters.map((d) => d.pioneers)));

    const extent = d3.extent(clusters.map((d) => d.pioneers));
    const mid = (extent[0] + extent[1]) / 2

    
    const generateLine = (svg, classNumber = '') => {  
      const line = svg.append('path')
      .datum(clusters)
      .attr('fill', 'none')
      .attr('stroke', classNumber ? '#65b4fd' : 'white')
      .attr('strokeWidth', 0.8)
      .attr('class', 'line-chart__line'+classNumber)
      .attr('d',
      d3.line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(mid))
      .curve(d3.curveCardinal))
      
      line
      .transition()
      .duration(1000)
      .attr('d', 
      d3.line()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.pioneers))
      .curve(d3.curveCardinal))

      return line
    }


    const generateHoverPoint = (svg) => {
      var focus = svg
      .append('g')
      .append('circle')
      .attr('strokeWidth', 2)
      .style("fill", "black")
      .attr("stroke", '#65b4fd')
      .attr('r', 6)
      .style("opacity", 0);

      const focusText = d3
      .select(".card__children")
      .append("div")
      .style("position", "absolute")
      .style("color", "white")
      .style("opacity", 0)
      .attr("text-anchor", "left")
      .attr("alignment-baseline", "middle");
    
      svg
      .append('rect')
      .style("fill", "none")
      .style("pointer-events", "all")
      .attr('width', width)
      .attr('height', height)
      .on('mouseover', () => mouseover(focus, focusText))
      .on('click', (event) => mousemove(event, focus, focusText))
      .on('mousemove', (event) => mousemove(event, focus, focusText))
      .on('mouseout', () => mouseout(focus, focusText));
    }

    const mouseover = (focus, focusText) => {
      focus.style("opacity", 1)
      focusText.style("opacity",1)
    }

    const mouseout = (focus, focusText) => {
      focus.style("opacity", 0)
      focusText.style("opacity", 0)
    }

    const mousemove = (event, focus, focusText, line) => {
      // recover coordinate we need
      const xCursor = d3.pointer(event)[0]
      const xPos = xScale.invert(xCursor);
      const bisector = d3.bisector((a) => a.date).left
      const bisectorText = d3.bisector((a) => a.date).right
      const tagIndex = bisector(clusters, xPos);
      const selected = clusters[tagIndex]
      if (!selected) return;
      const {date, pioneers} = selected;
    
      focus
        .attr("cx", xScale(date))
        .attr("cy", yScale(pioneers))

      const dateString = 
      `${date.getHours().toString().padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`    
      
      focusText
      .style("left", `${xScale(date)+15}px`)
      .style("top", `${yScale(pioneers)-15}px`)
      .html(tag(dateString, Math.floor(pioneers)))
      
    }


    const render = () => {
      
      const svg = d3.select("#line-chart");

      while (svg.node().firstChild) {
        svg.node().removeChild(svg.node().firstChild);
      }

      const axisGenerator = d3
      .axisBottom(xScale)
      .tickSize(0)
      .tickPadding(3)
      .ticks(d3.utcHour.every(1))
      .tickFormat((d, i) => {
        return d.getHours()
      });

      svg.append('g').call(axisGenerator).attr('transform', `translate(0,${height - 60})`);
      
      svg.selectAll(".tick text")
      .nodes()
      .reverse()
      .forEach(function(t, i){
        if (!(i % 2)) t.innerHTML = ""
      })

      const hoursWithIcon = new Date().getHours() % 2 === 0 ?
      ["5", "11", "17", "23"] : 
      ["6", "12", "18", "0"]
      
      svg.selectAll(".tick")
      .nodes()
      .forEach((t, i) => {
        switch(t.lastChild.innerHTML) {
          case hoursWithIcon[0]:
            t.innerHTML = t.innerHTML + Sunrise;
            break;
          case hoursWithIcon[1]:
            t.innerHTML = t.innerHTML + Day;
            break;
          case hoursWithIcon[2]:
            t.innerHTML = t.innerHTML + Sunset;
            break;
          case hoursWithIcon[3]:
            t.innerHTML = t.innerHTML + Night;
            break;
          default:
            break;
        }
      })

      svg.selectAll(".tick svg")
      .attr("y", "40")
      .attr("x",  "-9.5")

      svg.selectAll(".tick text")
      .attr("class","line-chart__text");

      svg.selectAll("path.domain")
      .attr("class","line-chart__line");
      
      generateLine(svg, clusters)

      for (let i = 1; i < 6; i++) {
        generateLine(svg, i)
      }

      generateHoverPoint(svg);
      
    }

    if (visible && clusters) render();
  },[visible, clusters, ref]);


  
  useEffect(() => {
    if (data) clusterData();
  }, [data])


  return <svg ref={ref}
    style={{
      height: '93%',
      width: '90%',
      margin: 'auto',
      marginTop: '10%',
      display: visible ? 'initial' : 'none'}}
    className="line-chart__wrapper" 
    id="line-chart" />
  ;
}


const tag = (hour, pioneers) => {
  return reactElementToJSXString(<div class="line-chart__tag">
    <div class="line-chart__tag-content">
      <div class="line-chart__tag-title">{pioneers} pioneers</div>
      <div class="line-chart__tag-hour">{hour}</div>
    </div>
    <div class="line-chart__tag-background"></div>
  </div>)
};

export default LineChart;
