/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import { signIn } from 'api/auth';
import ConfirmButton from 'components/confirm-button';
import OneLineInput from 'components/one-line-input';
import PasswordInput from 'components/one-line-input/password';
import { MessageContext } from 'context/message';
import { UserContext } from 'context/user';
import React, { useState } from 'react';
import { useContext } from 'react';
import './style.scss';

export default function SignIn() {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
  const { pushError } = useContext(MessageContext);
  const { setLoadingNewUser, loadingNewUser } = useContext(UserContext);

	const canProceed = email.length > 5 && password.length > 4;

  const _signIn = async (e) => {
    try {
      e.preventDefault();
      setLoadingNewUser(true);
      await new Promise(r => setTimeout(r, 1000));
      await signIn(email.toLocaleLowerCase().replace(' ', ''), password);
    } catch (error) {
      setLoadingNewUser(false);
      pushError(error.message);
    }
  }
  

	return <form autocomplete="off" onSubmit={_signIn} className='sign-in'>
		<h1>Welcome Back</h1>
		<OneLineInput label='email' value={email} setValue={setEmail} />
		<PasswordInput label='password' value={password} setValue={setPassword}/>
		<a className='sign-in__forgot-password' href={'/forgot-password'}>Forgot Password</a>
		<ConfirmButton 
      type='submit'
      label="sign in"
      loading={loadingNewUser}
      disabled={!canProceed || loadingNewUser} />
	</form>
}


