import Card from 'components/card';
import React from 'react';
import { lookingFor, sleeplessness } from 'utils/survey';
import './style.scss';



function MainSurvey({user}) {


  const data = [
    {
        question: "I'm primarily looking for...",
        answer: lookingFor(user?.survey?.lookingFor)
    },
    {
        question: "in an average month, how often do I struggle to sleep...",
        answer: sleeplessness(user?.survey?.difficultyToSleep)
    },
    {
        question: "I have received advice from a professional in the last 12 months regarding my health concerns...",
        answer: user?.survey?.receivedAdvice ? 'Yes' : 'No'
    },
  ];

  const Field = ({answer, question}) => (
    <div className="main-pioneer-data__survey-field">
    <div className="main-pioneer-data__field-name">{question}</div>
    <div className="main-pioneer-data__field-answer">{answer ? answer : <i style={{opacity: 0.2}}>not answered</i>}</div>
    </div>
  )

  return <div className='main-pioneer-data'>
    <h1>survey</h1>
    {data.map((d) => <Field {...d} /> )}
  </div>
}

export default Card(MainSurvey, true, '');
