/* eslint-disable eqeqeq */
import React, { 
	useEffect, 
	useState } from 'react';
import Dashboard from 'pages/dashboard';
import Journalling from 'pages/journalling';
import Formulations from 'pages/formulations';
import Pioneers from 'pages/pioneers';
import Pioneer from 'pages/pioneer';
import ValidateSignUp from 'pages/validate-sign-up';
import {
	Switch,
	Route,
	useLocation,
	useHistory,
} from "react-router-dom";
import SignIn from 'pages/sign-in';
import ForgotPassword from 'pages/forgot-password';
import NewPassword from 'pages/new-password';
import Settings from 'pages/settings';
import MeJournalling from 'pages/me-journalling';
import MeDashboard from 'pages/me-dashboard';
import MeHeader from 'components/header/me';
import AdminHeader from 'components/header/admin';
import Header from 'components/header';
import CreatePassword from 'pages/create-password';
import Terms from 'pages/terms';
import Science from 'pages/science';
import { useScrollToTop } from 'utils/hooks';
import Fulfillment from 'pages/fulfillment';
import Pebbles from 'pages/pebbles';

function UserRoutes() {
	return <>
		<Route path="/me" exact component={MeDashboard} /> 
		<Route path="/me/science" exact component={Science} />
		<Route path="/me/journalling" exact component={MeJournalling} /> 
	</>
}


function AdminRoutes() {
	return <>
		<Route path="/admin/home" exact component={Dashboard} /> 
		<Route path="/admin/settings" exact component={Settings} />
		<Route path="/admin/new-password" exact component={NewPassword} /> 
		<Route path="/admin/journalling" exact component={Journalling} />
		<Route path="/admin/fulfillment" exact component={Fulfillment} />
		<Route path="/admin/pebbles" exact component={Pebbles} />
		<Route path="/admin/formulations" exact component={Formulations} />
		<Route path="/admin/pioneers" exact component={Pioneers} />
		<Route path="/admin/pioneer/:id" component={Pioneer} />
	</>
}

function Routes() {
	const [styleOverride, setStyleOverride] = useState({})

	const { pathname } = useLocation();
	
	useScrollToTop()

	useEffect(() => {
		if (pathname.includes('/pioneer/')) setStyleOverride(() => Object.assign({
			marginLeft: 0,
			marginRight: 0,
		}));  
	}, [pathname])

	useEffect(() => {
		window.dispatchEvent(new Event('resize'))
	}, [styleOverride])

	const ThisHeader = pathname.includes('/me') 
	? MeHeader
	: pathname.includes('/admin') 
	? AdminHeader
	: Header
	

	return <>
	<ThisHeader />
	<div className="contents" style={styleOverride}>
		<Switch>
			<Route path="/create-password" exact component={CreatePassword} />
			<Route path="/" exact component={RedirectToSignIn} />
			<Route path="/sign-in" exact component={SignIn} />
			<Route path="/forgot-password" component={ForgotPassword} />
			<Route path="/new-password" component={NewPassword} />
			<Route path="/validate-sign-up" component={ValidateSignUp} />
			<Route path="/admin" component={AdminRoutes} />
			<Route path="/me" component={UserRoutes}  />
			<Route path="/terms" component={Terms} />
		</Switch>
	</div>
	</>
	
}

const RedirectToSignIn = () => {

	const history = useHistory();
	history.replace('/sign-in');
	return <div />

}
export default React.memo(Routes);
