import Card from 'components/card';
import Wrapper from 'components/scss-wrapper';
import React from 'react';
import './style.scss'

function PlainValue({ height, width, label, value}) {
  

  let value0, value1;

  value = value ? value : '---'
  value = value.toString();

  if (value.split(' ').length > 1) {
    value0 = value.split(' ')[0];
    value1 = value.split(' ').slice(1);
  } else {
    value0 = value;
  }

  return <div className="plain-value">
    <h1>{value0}
      <div>{value1}</div>
    </h1>
    <h4>{label}</h4>
  </div>
}

const WithCard = Card(PlainValue, true, '')

export default function PlainValueWrapper(props) {
  const { parent } = props;

  return <Wrapper parent={parent} className={props.className}>
    <WithCard {...props} />
  </Wrapper>
};
