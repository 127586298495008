import firebase from 'firebase';

export async function inviteAdmin({ name, email, type }) {
  try {
    const sendInvitation = firebase.functions().httpsCallable('inviteAdmin');
    await sendInvitation({ name, email, type });
  } catch (err) {
    throw new Error(err);
  }
}

export async function getRegularInvitations() {
  try {
    const getRegularInvitations = firebase.functions().httpsCallable('getRegularInvitations');
    const res = await getRegularInvitations();
    return res?.data
  } catch (err) {
    //throw new Error(err);
  }
}

export async function acceptTerms(userId) {
  try {
    const _acceptTerms = firebase.functions().httpsCallable('acceptTerms');
    const res = await _acceptTerms(userId);
    return res?.data;
  } catch (err) {
    throw new Error(err);
  }

}

export async function validateInvitation(token) {
  try {
    const validateInvitation = firebase.functions().httpsCallable('validateInvitation');
    return await validateInvitation({ token });
  } catch (err) {
    return err;
  }
}

export async function inviteRegularUser({ name, email }) {
  try {
    const sendInvitation = firebase.functions().httpsCallable('inviteRegularUser');
    const res = await sendInvitation({ name, email });
    return res.data;
  } catch (err) {
    throw new Error(err);
  }
}


export const signIn = async (
  email, 
  password, 
) => {
    const userCredential = await firebase.auth().signInWithEmailAndPassword(email, password);
    const displayName = userCredential.user.displayName  ? userCredential.user.displayName : '';
    return displayName;
}

export async function updatePassword(email, password) {
  try {
    const updatePassword = firebase.functions().httpsCallable('updatePassword');
    await updatePassword({ password });
    await signIn(email, password)
  } catch (err) {
    return err;
  }
}

export async function updateName(email, name) {
  try {
    const updateName = firebase.functions().httpsCallable('updateName');
    await updateName({ email, name });
  } catch (err) {
    return err;
  }
}


export async function getInvitationData() {
  try {
    const getInvitationData = firebase.functions().httpsCallable('getInvitationData');
    return await getInvitationData();
  } catch (err) {
    return err;
  }
}


export async function deleteUser(email) {
  try {
    const deleteUser = firebase.functions().httpsCallable('deleteUser');
    return await deleteUser({email});
  } catch (err) {
    return err;
  }
}

export async function restorePassword(token, password) {
  const restorePassword = firebase.functions().httpsCallable('restorePassword');
  return await restorePassword({password, token});
}

export async function sendForgotPasswordEmail(email) {
  try {
    const sendForgotPasswordEmail = firebase.functions().httpsCallable('sendForgotPasswordEmail');
    return await sendForgotPasswordEmail({email});
  } catch (err) {
    return err;
  }
}



