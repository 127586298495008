/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import Header from 'components/header';
import { useLocation } from 'react-router-dom';
import Selected from 'assets/icons/selected.svg';
import logout from 'assets/icons/logout.svg';
import menu from 'assets/icons/menu.svg';
import close from 'assets/icons/close.svg';
import SignOutModal from 'components/sign-out-modal';
import { isFirefox } from 'utils/browser';
import { UserContext } from 'context/user';

function Menu({visible}) {
  const className = visible ? 'visible' : ''
  const [maskClass, setMaskClass] = useState('hidden');
  const props = {visible}

  const { user } = useContext(UserContext);

  useEffect(() => {
    if (visible) setTimeout(() => setMaskClass('visible'), 100)
    else setMaskClass('hidden'); 
  }, [visible])

  return <div className={`header__overlay ${className}`}>
    <div className={`header__hidden-menu-mask ${maskClass}`} />
    <div 
    style={isFirefox ? { backgroundColor: '#06081E'} : {}}
    className='header__hidden-menu'>
      <HiddenButton {...props} url="/me" title="dashboard" i={1}/>
      <HiddenButton {...props} url="/me/journalling" title="journalling" i={2}/>
      <HiddenButton {...props} url="/me/science" title="science" i={3}/>
      {user.type > 0 && <HiddenButton {...props} url="/admin/home" title="admin" i={4}/>}
      <HiddenButton {...props} url="https://treehouse.sofi.health" title="treehouse" i={5}/>
    </div>
  </div>

}

function HiddenButton({visible, title, url, acceptedUrls, i}) {
  const location = useLocation();
  const selected = acceptedUrls ? 
    acceptedUrls.filter(
      (accepted) => location?.pathname.includes(accepted)
    ).length > 0 :
    location?.pathname === url;
  const [classname, setClassname] = useState('hidden');

  useEffect(() => {
    if (visible) setTimeout(() => setClassname('visible'), i * 150)
    else setClassname('hidden')
  }, [visible])
  
  return <a 
    href={url} 
    className={`header__button-hidden ${selected ? 'selected' : ''} ${classname}`}>
      {title}
    </a>
}

function Button({title, url, acceptedUrls}) {
  const location = useLocation();
  const selected = acceptedUrls ? 
    acceptedUrls.filter(
      (accepted) => location?.pathname.includes(accepted)
    ).length > 0 :
    location?.pathname === url;

  return <a 
  href={url} 
  className={`header__button ${selected ? 'selected' : ''}`}>
    <img src={Selected} alt="selected"/>
    {title}
  </a>
}

export default function AdminHeader() {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);

  const { user } = useContext(UserContext);

  const midContent = <div className='header__mid-content'>
    <Button url="/me" title="dashboard"/>
    <Button url="/me/journalling" title="journalling"/>
    <Button url="/me/science" title="science"/>
    <Button url="https://treehouse.sofi.health" title="treehouse"/>
    {user.type > 0 && <Button url="/admin/home" title="admin"/>}
    
  </div>
  
  const endContent = <div className='header__row'>
  <img 
    src={logout} 
    style={{cursor: 'pointer'}} 
    onClick={() => setLogoutModalVisible(true)} alt="logout" />
  <img src={menuVisible ? close : menu}
    className='header__menu'
    alt='menu'
    onClick={() => setMenuVisible(!menuVisible)} />
  </div>

  return <>
  <Menu
    visible={menuVisible}
    setVisible={setMenuVisible}/>
  <SignOutModal 
      visible={logoutModalVisible} 
      setVisible={setLogoutModalVisible} />
  <Header 
    midContent={midContent} 
    endContent={endContent}
    home={'/admin/home'}
  />
  </>

}