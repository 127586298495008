const mapDay = (day, length) => {
    while (day > length - 1) {
        day = mapDay(day - length, length)
    }
    return day;
}


const getRandom = (array) => {
    const index = mapDay(new Date().getDay(), array.length)
    return array[index]
}

export const getScoreMessage = (code) => {

    const results = {
        'result_copy_wait': [
            "patience is a virtue… still trying to figure it out!",
            "not quite there yet... but remember, results can take time! in the meantime, keep up the good work",
            "you're wonderful and marvellously unique! it's going to take some more time to accurately learn about you",
            "we don't have enough data yet, but we can't wait to share results as soon as we have any!"
        ],
        'score_copy_good': [
            "you're amazing, who knows what could happen if you journal more than once per day!",
            "being unique isn't the only amazing thing about you! continue journaling as often as possible to reveal more",
            "it's like you could do this in your sleep! keep up the good work journaling and spraying each day!",
        ],
        'score_copy_j_mean': [
            "you can never have too much of a good thing, if you journal more often your score will naturally increase",
            "you can't have too much of a good thing and journaling more often is just one way to celebrate this!",
            "good things come to those who journal more! making time for it at least once a day is key",
        ],
        'score_copy_j_stdv': [
            "you’re remarkably unique, and the more consistently you journal the better we know you",
            "one size doesn't fit all! your consistent journalling will help us understand you better",
            "you're one of a kind! by making journaling part of your routine you increase your score and help us to understand the unique you",
            "more consistent journaling will increase your score and help us to understand the unique you. you might understand yourself better too!"
        ],
        'score_copy_s_stdv': [
            "complexity is an opportunity in disguise and we know we will make greater discoveries with plants if you spray more consistently",
            "complexity is an opportunity waiting to be unlocked by consistency!",
            "spraying at the same time each day helps us learn what does and does not work uniquely for you! try making sofi part of your nighttime routine",
        ]
    }
    
    return getRandom(results[code]);
}
