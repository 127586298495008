import React, { useContext } from 'react';
import long_arrow from 'assets/icons/long-arrow.svg';
import './style.scss';
import done from 'assets/icons/done-check.svg';
import CardHOC from 'components/card';
import { UserContext } from 'context/user';

const Card = ({
    name,
    description,
    filled,
    url
}) => {

    const { user } = useContext(UserContext);

    return <div className='survey-card'>
        <h1>{name}</h1>
        <p>{description}</p>
        <div style={{flex: 1}} />
        {
            filled 
            ? <div className='survey-card__survey-done'>survey done, thank you!</div>
            : <a className='survey-card__survey-link' href={url + user.email} target='_blank' rel="noreferrer">
                <div>start survey</div>
                <img src={long_arrow} alt='' />
            </a> 
        }
        { filled && <img src={done} className='survey-card__done-check' alt='done' /> }
    </div>

}

const WithCard = CardHOC(Card, false, '');

export default function SurveyCard(props) {

    const {className} = props;

    return <div className={className}>
        <WithCard {...props}/>
    </div>

}

