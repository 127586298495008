import Card from 'components/card';
import OneLineInput from 'components/one-line-input';
import PasswordInput from 'components/one-line-input/password';
import React, { useContext, useState, useEffect } from 'react';
import logout from 'assets/icons/logout.svg';
import './style.scss';
import SignOutModal from 'components/sign-out-modal';
import { UserContext } from 'context/user';
import { uploadProfilePic } from 'utils/storage';
import add from 'assets/icons/add.svg';
import ConfirmButton from 'components/confirm-button';
import { updatePassword, updateName } from 'api/auth';
import { MessageContext } from 'context/message';


function UserData() {

  const { pushError, pushMessage } = useContext(MessageContext);
	const { user, updateProfilePic, forceUpdateUser } = useContext(UserContext);
  
  const [updating, setUpdating] = useState(false);
  const [fullName, setFullName] = useState(user.displayName);
	const [email, setEmail] = useState(user.email);
	const [password, setPassword] = useState('initial_string');
	const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const handleUploadPhoto = async (e) => {
    setUploadingPhoto(true)
    await uploadProfilePic(user.uid, e);
    await updateProfilePic();
    setUploadingPhoto(false);
  }

  useEffect(() => {
    if (user.displayName) {
      setEmail(user.email);
      setFullName(user.displayName);
    }
  }, [user])

  const profilePic = uploadingPhoto ? "" :  user?.profile_pic;
  
  const onFocusPassword = () => {
    if (password === 'initial_string') setPassword('')
  }

  const onBlurPassword = () => {
    if (!password) setPassword('initial_string')
  }

  const updateUser = async () => {
    try {
      setUpdating(true);
      if (password !== 'initial_string') await updatePassword(user.email, password);
      if (fullName !== user.displayName) await updateName(user.email, fullName);
      setUpdating(false);
      setPassword('initial_string');
      pushMessage('user updated!')
      forceUpdateUser();
    } catch (err) {
      pushError('Error updating user')
    }
  }

  return <div className='user-data'>
	  <SignOutModal visible={logoutModalVisible} setVisible={setLogoutModalVisible} />
		<div className='user-data__wrapper'>
			<img className='user-data__signout' onClick={() => setLogoutModalVisible(true)} src={logout} alt='signout' />
				<div className='user-data__photo'>
				{profilePic ?	
					<img className='user-data__profile-pic' alt='profile-pic' src={profilePic} /> :
					<div className='user-data__profile-pic' alt='profile-pic' />
				}
				<label class="user-data__input">
            <img 
            className='user-data__icon' 
            src={add} alt='add userpic' />
    				<input 
            disabled={uploadingPhoto} 
            onInput={handleUploadPhoto} 
            accept="image/jpeg" 
            type="file"/>
				</label>
				</div>
			<h1>{user.displayName}</h1>
			<OneLineInput 
        label='full name' 
        value={fullName} 
        setValue={setFullName} 
      />
			<OneLineInput 
        label='email' 
        value={email} 
        setValue={setEmail} 
        disabled />
			<PasswordInput 
        allowVisiblePassword={password !== 'initial_string'}
        label='password' 
        value={password} 
        onFocus={onFocusPassword}
        onBlur={onBlurPassword}
        placeholder='password'
        setValue={setPassword} />
      { ((fullName !== user.displayName
        && fullName?.length > 1)
        || (password !== 'initial_string'
        && password.length > 7))
        && <ConfirmButton 
        label="Update" 
        loading={updating}
        onClick={updateUser} 
        disabled={updating} /> 
      }
		</div>
  </div>
}

export default Card(UserData, true, '', { height: 'min-content' });
