import React, { useContext }  from 'react';
import PlainValue from 'components/plain-value';
import { DataContext } from 'context/data';


function TotalSprays() {

	const { data } = useContext(DataContext);
	const { sprays } = data;
    

	return <PlainValue
		className="total-sprays"
		label="Sprays in interval" 
		value={sprays.length}
	/>

}

export default TotalSprays

