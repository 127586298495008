/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import Card from 'components/card';
import Table from 'graphs/table';
import PioneerLink from 'components/pioneer-link';
import { color as formulationColor } from 'utils/formulation';

import { DataContext } from 'context/data';



/* {
  name: d.name,
  age: d.age,
  formula: d.currentFormulation + 1,
  location: d.locationName,
  id: d.id,
} */

function LatestJournals(props) {
    const { data } = useContext(DataContext);
    const { journals, lastActive } = data;
    
    
    const columnTitles = [
        "name",
        "age",
        "formulation",
        "location"
    ]

    const sorted_journals = [...journals].sort((a, b) => {
      return b.endDate.getTime() - a.endDate.getTime();
    })

    const included_users = [];

    const journals_per_user = sorted_journals.reduce((prev, journal) => {
      if (included_users.includes(journal.user)) return prev;
      included_users.push(journal.user);
      return [...prev, journal];
    }, [])

    const users = journals_per_user.reduce((prev, journal) => {
      if (prev.length > 4) return prev;
      const user = lastActive.find((u) => u.id === journal.user);
      if (!user?.name) return prev;
      return [...prev, {
        age: user?.age,
        name: user?.name,
        id: user?.id,
        location: user?.locationName,
        formulation: journal?.formulation ? journal?.formulation + 1 : 'baseline',
      }]
    }, []);

    const renderRows = {
      name: (data, id) => <PioneerLink data={{name: data, id: id}} />,
      age: (data) => data + ' years',
      formulation: (data) => <>
        <div className="formula-dot" style={formulationColor(data)}/>
        {data === 'baseline' ? 'baseline' : 'no.' + data}
      </>
    }

    
    if (!data) return <div />

    return <div className='latest-journals' style={{...props, paddingTop: 70}}>
        <Table columnTitles={columnTitles} rows={users} renderRows={renderRows}/>
    </div>
}

export default Card(LatestJournals, false, 'Latest Journals');