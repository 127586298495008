/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import Header from 'components/header';
import { useHistory, useLocation } from 'react-router-dom';
import Selected from 'assets/icons/selected.svg';
import menu from 'assets/icons/menu.svg';
import close from 'assets/icons/close.svg';
import SignOutModal from 'components/sign-out-modal';
import { isFirefox } from 'utils/browser';
import { UserContext } from 'context/user';
import FilterModal from 'components/filter';

function Menu({visible}) {
  const className = visible ? 'visible' : ''
  const [maskClass, setMaskClass] = useState('hidden');
  const props = {visible}

  useEffect(() => {
    if (visible) setTimeout(() => setMaskClass('visible'), 100)
    else setMaskClass('hidden'); 
  }, [visible])

  return <div className={`header__overlay ${className}`}>
    <div className={`header__hidden-menu-mask ${maskClass}`} />
    <div 
    style={isFirefox ? { backgroundColor: '#06081E'} : {}}
    className='header__hidden-menu'>
      <HiddenButton {...props} url="/admin/home" title="dashboard" i={1}/>
      <HiddenButton {...props} url="/admin/journalling" title="journalling" i={2}/>
      <HiddenButton {...props} url="/admin/formulations" title="formulations" i={4}/>
      <HiddenButton {...props} url="/admin/pioneers" title="pioneers" i={3}/>
      <HiddenButton {...props} url="/admin/fulfillment" title="fulfillment" i={3}/>
      <HiddenButton {...props} url="/admin/pebbles" title="pebbles" i={3}/>
      <HiddenButton {...props} url="/me" title="my sofi" i={6}/>
    </div>
  </div>

}

function HiddenButton({visible, title, url, acceptedUrls, i}) {
  const location = useLocation();
  const selected = acceptedUrls ? 
    acceptedUrls.filter(
      (accepted) => location?.pathname.includes(accepted)
    ).length > 0 :
    location?.pathname === url;
  const [classname, setClassname] = useState('hidden');

  useEffect(() => {
    if (visible) setTimeout(() => setClassname('visible'), i * 150)
    else setClassname('hidden')
  }, [visible])
  
  return <a 
    href={url} 
    className={`header__button-hidden ${selected ? 'selected' : ''} ${classname}`}>
      {title}
    </a>
}

function Button({title, url, acceptedUrls}) {
  const location = useLocation();
  const selected = acceptedUrls ? 
    acceptedUrls.filter(
      (accepted) => location?.pathname.includes(accepted)
    ).length > 0 :
    location?.pathname === url;

  return <a 
  href={url} 
  className={`header__button ${selected ? 'selected' : ''}`}>
    <img src={Selected} alt="selected"/>
    {title}
  </a>
}



export default function AdminHeader() {
  const [logoutModalVisible, setLogoutModalVisible] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const history = useHistory();
  const { user } = useContext(UserContext);

  const midContent = <div className='header__mid-content'>
    <Button url="/admin/home" title="dashboard"/>
    <Button url="/admin/journalling" title="journalling"/>
    <Button url="/admin/formulations" title="formulations"/>
    <Button 
      url="/admin/pioneers" 
      acceptedUrls={["/admin/pioneers", "/admin/pioneer"]}
      title="pioneers"
    />
    <Button url="/admin/fulfillment" title="fulfillment"/>
    <Button url="/admin/pebbles" title="pebbles"/>
    <Button url="/me" title="my sofi"/>
  </div>
  
  const Filter = <FilterModal />

  const profileImage = user?.profile_pic 
    ? <img 
    src={user.profile_pic} 
    className='header__profile-pic'
    onClick={() => history.push('/admin/settings')}
    alt='User profile' />
    : <div 
    onClick={() => history.push('/admin/settings')}
    className='header__profile-pic' />

    const endContent = <div className='header__row'>
    {Filter}
    {profileImage} 
  <img src={menuVisible ? close : menu}
    className='header__menu'
    alt='menu'
    onClick={() => setMenuVisible(!menuVisible)} />
  </div>
  

  return <>
  <Menu
    visible={menuVisible}
    setVisible={setMenuVisible}/>
  <SignOutModal 
      visible={logoutModalVisible} 
      setVisible={setLogoutModalVisible} />
  <Header 
    midContent={midContent} 
    endContent={endContent}
    home={'/admin/home'}
  />
  </>

}