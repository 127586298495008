import React from 'react';

export default function Dot({ x, y }) {
    
    return <div style={{position: 'absolute', top: x, left: y, height: 21, width: 21}}>
    <div style={{position: 'absolute', top: '-50%', left: '-50%'}}>
    <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.5 19C15.1944 19 19 15.1944 19 10.5C19 5.80558 15.1944 2 10.5 2C5.80558 2 2 5.80558 2 10.5C2 15.1944 5.80558 19 10.5 19Z" fill="#0A0D2A"/>
        <path d="M11.6235 19.7644C6.69627 20.1955 2.35253 16.5507 1.92146 11.6235C1.49039 6.69629 5.13521 2.35256 10.0624 1.92149C14.9896 1.49041 19.3333 5.13524 19.7644 10.0624C20.1955 14.9896 16.5507 19.3334 11.6235 19.7644Z" stroke="url(#paint0_linear_2951:1525)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_2951:1525" x1="33.132" y1="-26.5737" x2="32.5108" y2="29.3387" gradientUnits="userSpaceOnUse">
        <stop stop-color="#6821FF"/>
        <stop offset="0.494792" stop-color="#248FF1"/>
        <stop offset="1" stop-color="#00F7CA"/>
        </linearGradient>
        </defs>
        </svg>
    </div>
    </div>

    

}

