export const color = (gender) => {
  return ['women','female'].includes(gender) 
  ? 'rgba(104, 33, 255'
  : ['men', 'male'].includes(gender)
  ? 'rgba(0, 246, 202'
  : 'rgba(36, 143, 241'
  
}

export const getGenderString = (gender) => {
  return gender === 1 
  ? 'non-binary'
  : gender === 2
  ? 'male' 
  : 'female'
}

export const getGenderTag = (gender) => {
  return gender === 1 
  ? 'nb'
  : gender === 2
  ? 'male' 
  : 'female'
}
