/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState  } from 'react';
import './style.scss';
import Card from 'components/card';
import BarChart from 'graphs/bars';
import { DataContext } from 'context/data';
import { color, getGenderTag } from 'utils/gender';
import { ageRanges } from 'config/constants';


function GenderByAge() {

  const { data } = useContext(DataContext);
  const { lastActive } = data;
  

  const ageRangeStrings = ageRanges.map((range) => {
    if (range.upper && !range.lower) return '-' + range.upper
    else if (range.lower && !range.upper) return range.lower + '+'
    else return range.lower + '-' + range.upper
  });

  const defaultGenders = {
    'male': 0,
    'female': 0,
    'nb': 0,
  };

  const formattedAgeRanges = lastActive.reduce((prev, user) => {

    if (!user.age) return prev;
    if (user.gender !== 0 && !user.gender) return prev;

    const rangeString = ageRanges.reduce((prev, next, i) => {
      if (!next.lower) {
        return next.upper >= user.age ?
        ageRangeStrings[i] : prev
      } else if (!next.upper) {
        return next.lower <= user.age ?
        ageRangeStrings[i] : prev
      } else {
        return next.upper >= user.age && next.lower <= user.age ?
        ageRangeStrings[i] : prev
      }
    }, null);


    const nextItem = {
      ...prev,
      [rangeString]: {
        ...prev[rangeString],
        [getGenderTag(user.gender)]: prev[rangeString][getGenderTag(user.gender)] + 1,
      }
    }

    return nextItem;
  }, ageRangeStrings.reduce((p, n) => ({...p, [n]:defaultGenders}) , {}))
  

  const arrayAgeRanges = Object.keys(formattedAgeRanges).map((key) => ({
    ages: key,
    values: formattedAgeRanges[key]
  }))

  
  const wrapperRef = useRef(null);

  return <>
  <div ref={wrapperRef} className='gender-by-age'>
    <BarChart wrapper={wrapperRef} total={lastActive.length} data={arrayAgeRanges}/>
  </div>
  </>
}

const Labels = () =>{

  const Label = ({gender, label}) => (
  <div className='gender-by-age__label'>
    <div className='gender-by-age__dot' style={{backgroundColor: color(gender)}} />
    <div style={{opacity: 0.5}}>{label}</div>
  </div>)

  return <div className='gender-by-age__labels'>
    <Label gender='women' label='female' />
    <Label gender='men' label='male' />
    <Label gender='nb' label='non-binary' />
  </div>
}

export default Card(GenderByAge, false, 'Gender By Age', null, <Labels />);