import React from 'react';
import './style.scss'

export default function PioneerLink({ data, style }) {
  const name = data?.name?.split(' ');

  if (!name) return <div />

  const firstName = name.shift();
  let lastName = name.shift();

  return <div className="pioneer-link__wrapper">
    <a
      className="pioneer-link"
      style={style}
      href={`/admin/pioneer/${data.id}/`}>
      {`${firstName} ${lastName ? `${lastName.substr(0, 1).toUpperCase()}.` : ''}`.trim()}
    </a>
    <div className="pioneer-link__underline" />
  </div>
}