/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext }from 'react';
import LineChart from 'graphs/line-chart-locale';
import Card from 'components/card';
import { DataContext } from 'context/data';


const AverageJournalTime= () => {
  
  const { data } = useContext(DataContext);
  const {lastActive, journals} = data;

  const journalData = journals.reduce((prev, journal) => {
    const user = lastActive.filter((u) => u.id === journal.user);
    if (!user[0]) return prev
    if (!journal?.endDate) return prev;
    
    const hourAtUser = new Date(journal.endDate).toLocaleTimeString('en-US', {hour12:false, timeZone: user[0].timezone })
    .substr(0, 2);

    const date = parseInt(hourAtUser) > new Date().getHours() 
    ? new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    : new Date();

    date.setHours(parseInt(hourAtUser));

    return [...prev, {
      date
    }]

  }, [])


  return (
    <div style={{
      width: '100%',
      display: 'flex',
      height: 400,
      alignItems: 'center', 
      justifyContent: 'center'}}>
      <LineChart height={500} data={journalData} />
    </div>
  );
}


export default Card(AverageJournalTime, false, 'Journalling Time - Average');