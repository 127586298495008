import React from 'react';
import './style.scss';

export default function doughnutLabels({data}) {
    return <div className="doughnut__labels">
        {data.map((d, i) => <Label data={d} key={i}/>)}
    </div>
}

function Label({data}) {
    return <div className="doughnut__label">
        <div className="doughnut__label-dot" style={{backgroundColor: data.color}}></div>
        <div className="doughnut__label-title">{data.label}</div>
        <div className="doughnut__label-value">{data.percentage}%</div>
    </div>
}
