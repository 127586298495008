/* eslint-disable react-hooks/exhaustive-deps */
import Doughnut from 'graphs/doughnut';
import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import Card from 'components/card';
import Labels from 'graphs/doughnut/labels';
import { color } from 'utils/formulation';

import { DataContext } from 'context/data';

function CurrentlyInUse(props) {
  const { data } = useContext(DataContext);
  const { sprays, lastActive } = data
  

  const totals = lastActive.reduce((prev, user) => {
    const userSprays = sprays.filter((spray) => 
      spray.user === user.id
    );
    userSprays.sort((a, b) => b.date.getTime() - a.date.getTime());
    const lastSpray = userSprays.length ? userSprays[0] : null

    const formulation = lastSpray ? 
    lastSpray.formulation : 'baseline'
    if (!prev[formulation]) prev[formulation] = 1;
    else prev[formulation] += 1
    return prev;
  }, {})

  const mapped = Object.keys(totals).map((formulation) => ({
    label: formulation === 'baseline' ? 
    'base' : `no. ${parseInt(formulation) + 1}`,
    value: totals[formulation],
    color: color(parseInt(formulation) + 1, 'color').color,
    percentage: ((totals[formulation] / lastActive.length) * 100).toFixed(1)
  }))
 

  return <div className={'currently-in-use'} >
    <Labels data={mapped} />
    <Doughnut height={250} width={250} data={mapped} />
  </div>
}

export default Card(CurrentlyInUse, false, 'Currently in use');