import React, { useRef, useState } from 'react';
import * as d3 from "d3";
import { useEffect } from 'react';
import graphDot from 'assets/images/graph-dot.svg';
import graphLine from 'assets/images/line.svg';
import arrow from 'assets/images/arrow-left.svg';
import dateSign from 'assets/images/date-sign.svg';
import './style.scss';
import { MONTHS } from 'utils/months';


const getHoursBetweenDates = (start, end) => {

    const all_hours = [];
    for (let i = end.getTime(); i > start.getTime(); i -= (60 * 60 * 1000)) {
        all_hours.push(new Date(i));
    }
    return all_hours;

}


export default function ScoreChartInner({ data, width, height }) {


    const [dotCoordinates, setDotCoordinates] = useState([0, 0]);
    const [dotOpacity, setDotOpacity] = useState(0);
    const [score, setScore] = useState(0);
    const [lastSevenDays, setLastSevenDays] = useState(false)
    const [date, setDate] = useState(0);
    const [allData, setAllData] = useState([]);
    const [formatted, setFormatted] = useState([]);

    height = height - 140;
    const padding = width > 500 ? 90 : 70;
    width = width - padding;

    const [tagVisible, setTagVisible] = useState(false);

    const generateHoursBetween = (current_data) => {
        const copy = [...current_data];

        const start = copy[copy.length - 1]?.date;
        const end = copy[0]?.date

        const all_hours = getHoursBetweenDates(start, end);
        const interpolator = d3.interpolateBasis(copy.map((c) => c.score));
        const scores = d3.quantize(interpolator, all_hours.length);

        const result = all_hours.map((date, i) => ({
            date,
            score: scores[i]
        }))

        result.sort((a, b) => a.date.getTime() - b.date.getTime())

        setAllData(result);
        setFormatted(result);
    }


    

    useEffect(() => {

        
        if (!formatted.length) return;

    
        const offset = 0;
        const extent = d3.extent(formatted.map((d) => d.score));
        const mid = (extent[0] + extent[1]) / 2

        const xScale = d3.scaleTime()
            .domain([formatted[0].date, formatted[formatted.length - 1].date])
            .range([0, width - offset]);

        const yScale = d3.scaleLinear()
            .range([height - 90, 30]) //height of data
            .domain([0, 100]);


        const mousemove = (event) => {
            // recover coordinate we need
            const xCursor = d3.pointer(event)[0]
            const xPos = xScale.invert(xCursor);
            const bisector = d3.bisector((a) => a.date).left
            const tagIndex = bisector(formatted, xPos);
            const selected = formatted[tagIndex]

            setScore(selected.score);
            setDate(selected.date.getDate() + ' ' + MONTHS[selected.date.getMonth()])
            setDotOpacity(xScale(selected.date) / width);
            setDotCoordinates([xScale(selected.date) + offset, yScale(selected.score)]);
        }

        const mouseout = (event) => {
            adjustDotCoordinates();
            setTagVisible(false);
        }

        const mouseover = (event) => {
            setTagVisible(true);
        }

        const generateLine = (svg, classNumber = '') => {
            const line = svg.append('path')
                .datum(formatted)
                .attr('fill', 'none')
                .attr('stroke', 'white')
                .attr('stroke-width', 2)
                .attr('class', 'score-chart__line' + classNumber)
                .attr('d',
                    d3.line()
                        .x((d) => xScale(d.date) + offset)
                        .y((d) => yScale(mid))
                        .curve(d3.curveCardinal))

            line
                .transition()
                .duration(1000)
                .attr('d',
                    d3.line()
                        .x((d) => xScale(d.date) + offset)
                        .y((d) => yScale(d.score))
                        .curve(d3.curveCardinal));

            return line
        }

        const generateShadow = (svg) => {

            const line = svg.append('path')
                .datum(formatted)
                .attr('stroke', 'white')
                .attr('stroke-width', 0)
                .attr('fill', 'url(#gradient)')
                .attr('class', 'score-chart__shadow')
                .attr('d',
                    d3.area()
                        .x((d) => xScale(d.date) + offset)
                        .y0((d) => yScale(mid))
                        .y1((d) => yScale(mid))
                        .curve(d3.curveCardinal))

            line
                .transition()
                .duration(1000)
                .attr('d',
                    d3.area()
                        .x((d) => xScale(d.date) + offset)
                        .y0(height)
                        .y1((d) => yScale(d.score))
                        .curve(d3.curveCardinal));

            line.on('mouseover', (event) => mouseover(event))
            line.on('mousemove', (event) => mousemove(event))
            line.on('mouseout', (event) => mouseout(event))

            return line
        }

        const adjustDotCoordinates = () => {
            const last = formatted[formatted.length - 1];

            const x = xScale(last.date) + offset;
            const y = yScale(last.score);
            setDotCoordinates([x, y]);
            setScore(last.score)
            setDate(null);
            setTimeout(() => setDotOpacity(1), 1100)
        }

        const render = () => {
            if (height < 0) return;
            const mask = d3.select("#score-chart-mask-A");
            const svg = d3.select("#score-chart");
            

            cleanSimilar(mask);
            cleanShadow(svg);
            generateLine(mask);
            generateShadow(svg);
            adjustDotCoordinates();
        }

        render();
    }, [height, width, formatted]);

    useEffect(() => {
        generateHoursBetween(data);
    }, [data])

    useEffect(() => {
        if (!allData.length) return;
        if (lastSevenDays) {
            const sevenDaysAgo = new Date(new Date().getTime() - 1000 * 60 * 60 * 24 * 8);
            setFormatted(allData.filter((d) => d.date.getTime() > sevenDaysAgo.getTime()))
        } else {
            setFormatted(allData);
        }
    }, [lastSevenDays])

    const cleanSimilar = (svg) => {
        console.log([...svg.node().children].length);

        while (svg.node()?.firstChild
            && (svg.node()?.firstChild?.nodeName !== 'foreignObject'
                || svg.node()?.firstChild?.nodeName !== 'mask'
                || svg.node()?.firstChild?.nodeName !== 'defs'
                || svg.node()?.firstChild?.nodeName !== 'clipPath')) {
            svg.node().removeChild(svg.node().firstChild);
        }
    }

    const cleanShadow = (svg) => {
        while (svg.node()?.lastChild
            && svg.node()?.lastChild?.nodeName !== 'defs') {
            svg.node().removeChild(svg.node().lastChild);
        }
    }


    return <div className="score-chart" style={{ height }}>
        <svg
            className="score-chart__wrapper"
            height={height} width={width}>
            <defs>
                <mask id='score-chart-mask-A' />
            </defs>
            <foreignObject
                className='score-chart__gradient-wrapper'
                mask='url(#score-chart-mask-A)'
                id='clip-score-chart-A'>
                <div
                    style={{ height, width }}
                    className='score-chart__gradient' />
            </foreignObject>
        </svg>
        <div className="score-chart__wrapper"
            style={{ height, width }}>
            <div style={{
                left: dotCoordinates[0] - 7,
                top: (dotCoordinates[1] - 7) + 'px'
            }}
                className="score-chart__dot" >
                {tagVisible && <div className='score-chart__tag'>
                    <div>{date}</div>
                    <img src={dateSign} alt="" />
                </div>}
                <img
                    style={{ opacity: dotOpacity }}
                    src={graphDot} alt="" />
                <img src={graphLine}
                    style={{
                        opacity: dotOpacity,
                        width: dotCoordinates[0],
                        left: -dotCoordinates[0] + 2
                    }}
                    className="score-chart__line" alt="" />
            </div>
        </div>
        <svg
            height={height} width={width}
            className="score-chart__wrapper"
            id="score-chart">
            <defs>
                <linearGradient id="gradient" rotate="1" x2="0%" x1="100%" y2="100%" y1="0%">
                    <stop offset="0%" stop-color="rgba(255,255,255, 0.1)" />
                    <stop offset="60%" stop-color="rgba(255,255,255,0.0)" />
                </linearGradient>
            </defs>
        </svg>
        <button
            className='score-chart__toggle'
            onClick={() => setLastSevenDays((p) => !p)} style={{ position: 'absolute' }}>
            {lastSevenDays ? 'view all time' : 'view last 7 days'}
        </button>
        <div
            style={{
                height,
                width,
                position: 'absolute'
            }}
            className="score-chart__sidebar-wrapper">
            <div className='score-chart__sidebar' style={{ height }}>
                <div style={{ height: height - dotCoordinates[1] }}
                    className='score-chart__sidebar-inner'>
                    <div className='score-chart__score'>
                        <img src={arrow} alt='' />
                        {score.toFixed(0)}
                    </div>
                </div>
            </div>
        </div>
    </div>;

}
