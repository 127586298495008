import React, { useContext } from 'react';
import Flower from 'components/flower';
import './style.scss'
import Card from 'components/card';
import { DataContext } from 'context/data';

function JournallingStatus() {

    const { data } = useContext(DataContext);
    const { journals, lastActive } = data
  
    const scale = {transform: 'scale(0.70)'}

    const getPetalCount = (formulation) => {

      const qualifiedJournals = journals.filter((j) => !!j.endDate);

      const journalsPerFormulation = qualifiedJournals.filter((j) => {
        return formulation === 'baseline'
          ? !j.formulation
          : j.formulation === formulation
      }
      );

      const journalsPerDay = {}
      journalsPerFormulation.forEach((j) => {
        const date = new Date(j.endDate).toDateString();
        if (!journalsPerDay[j.user]) journalsPerDay[j.user] = {}
        journalsPerDay[j.user][date] = '1';
      });

      const count = Object.values(journalsPerDay).reduce((prev, user) => {
        return prev + Math.min(Object.values(user).length, 10)
      }, 0)/lastActive.length;

      return Math.ceil(count);
    }

    
    return <>
    <div className="journalling-status">
      <div style={scale}>
        <Flower formulation={0} numberPetals={getPetalCount('baseline')} color='baseline' />
      </div>
      <div style={scale}>
        <Flower formulation={1} numberPetals={getPetalCount(0)} color='green' />
      </div>
      <div style={scale}>
        <Flower formulation={2} numberPetals={getPetalCount(1)} color='blue' />
      </div>
      <div style={scale}>
        <Flower formulation={3} numberPetals={getPetalCount(2)} color='red' />
      </div>
      
    </div>
    </>
    
}

export default Card(JournallingStatus, false, 'Number of Petals');

