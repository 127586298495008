/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import Card from 'components/card';
import './style.scss';
import { capitalize } from 'utils/string';
import searchIcon from 'assets/icons/search.svg';
import closeIcon from 'assets/icons/X.svg';
import arrow from 'assets/icons/arrow.svg';
import { 
  getDayOffset, 
  getCleanDate, 
  getCleanHours } from 'utils/time';
import {
  color as colorFormulation } from 'utils/formulation';
import InputWithTimedKeyup from 'components/input/with-timed-keyup';
import LoadingPane from 'components/loading-pane';
import PioneerLink from 'components/pioneer-link';
import { isNull } from 'utils/data';
import { getFunctions } from 'utils/firebase';

const ColumnTitle = ({column, currentSort, setSort, searchActive}) => {
  
  const isCurrentSort = currentSort.key === column.key;
  const title = column.name ? column.name : capitalize(column.key);
  const [isAscending, setIsAscending] = useState(false);

  const _onClick = () => {
    setIsAscending((current) => {
      setSort({
        key: column.key,
        isAscending: !current,
        isString: column.isString
      })
      return !current
    })
  }

  return <button onClick={_onClick}
  disabled={searchActive && column.disabledOnSearch}
  className={`
  unselectable
  all-pioneers__column-title 
  ${isCurrentSort ? 'selected' : ''}`}>
    {title}
    <img 
    src={arrow}
    style={isAscending ? {transform: 'rotate(180deg)'} : {}} 
    alt="change sort"/>
  </button>

}

const Header = ({setQuery}) =>  {
  const [searchActive, setSearchAtive] = useState(false);
  const [value, setValue] = useState('')
  const inputRef = useRef(null);
  const timer = useRef(null);

  const toggleSearch = () => {
    setSearchAtive((v) => {
      if (!v) setTimeout(() => inputRef.current.focus(), 400);
      return !v;
    })
  }

  const onKeyUp = () => setQuery(value);
  
  const onClear = () => {
    clearTimeout(timer?.current);
    setValue('')
    setQuery('')
  }

  
  return <div className="all-pioneers__header">
    <div className="all-pioneers__title">all pioneers</div>
    <div className={`all-pioneers__search-wrapper ${!searchActive && 'disabled'}`}>
      <img 
        onClick={toggleSearch} 
        src={searchIcon} 
        className="all-pioneers__toggle-search" 
        alt="search icon" />
      <InputWithTimedKeyup 
        timer={timer}
        ref={inputRef}
        type="text" 
        value={value} 
        onKeyUp={onKeyUp}
        onChange={(e)=>setValue(e.target.value)} />
      <img 
        onClick={onClear}
        className="all-pioneers__clear-search" 
        src={closeIcon} 
        alt="clear search" />
    </div>
  </div>
}

const hourMapper = (date) => {
  
  if (!date?.toTimeString || isNaN(date.getTime())) return '---';
  
  const hour = date?.toTimeString().substr(0, 5);
  const offset = getDayOffset(date);
  let dateOfMonth;
        
  if (offset > 1) {
    dateOfMonth = offset < 2 ? 
    'yesterday at' : 
    `${getCleanDate(date)} at`
  }

  return <>
    {dateOfMonth} {hour}
  </>
}


function AllPioneers(props) {

  const columns = [
    {
      isString: true,
      key: 'name',
      renderer: (_, data, id) => <PioneerLink data={{...data, id}}/>
    },
    {
      name: 'Program',
      key: 'program',
      isString: true
    },
    {
      key: 'age',
      renderer: (age) => age + ' years'
    },
    {
      key: 'currentFormulation',
      disabledOnSearch: true,
      name: 'Current Pod',
      renderer: (number) => (
        <>
        <div className="all-pioneers__dot" style={colorFormulation(number + 1)} />
        no.{number + 1}
        </>
      ),
    },
    {
      key: 'lastSpray',
      name: 'Last Spray',
      renderer: hourMapper,
      disabledOnSearch: true,
    },
    {
      key: 'lastJournal',
      name: 'Last Journal',
      isString: true,
      renderer: hourMapper,
    },
    {
      name: 'location',
      key: 'locationName',
      isString: true
    }
  ]

  const [results, setResults] = useState([]);
  const [query, setQuery] = useState("");
  const [sort, setSort] = useState({key: 'lastJournal', isAscending: false});
  const [loading, setLoading] = useState(false);
  const [offset, setOffset] = useState(0);


  useEffect(() => {
    if (['currentFormulation', 'lastSpray'].includes(sort.key)
    && !!query) {
      return setSort({key: 'name', isAscending: true });
    }
    setResults([]);
    setOffset(0);
    loadData();
  }, [sort, query]);
  
  useEffect(() => {
    if (offset > 0) loadData(true);
  }, [offset]);

  //SORT AND RANGE

  const fetch = async (shouldKeepData) => {
    try {
      const fetchData = getFunctions().httpsCallable('v2_searchPioneerData');

      console.log('fetch with v2');

      const params = {
        search: query,
        order: sort.key, 
        asc: !!sort.isAscending, 
        offset};

      let r = await fetchData(params);
      let result_data = JSON.parse(r?.data);
      result_data = result_data.map((data) => ({
        ...data,
        lastSpray: new Date(data.lastSpray),
        lastActive: new Date(data.lastActive),
        lastJournal: new Date(data.lastJournal),
        createdAt: new Date(data.createdAt),
      }))

      setResults((prev) => shouldKeepData ? [...prev, ...result_data] : [...result_data]);
    } catch (err) {
      console.log(err)
    }
  }

  const loadData = async (shouldKeepData) => {
    setLoading(true)
    await fetch(shouldKeepData);
    await new Promise((r) => setTimeout(r, 500));
    setLoading(false);
  }

  const renderColumn = (hideContent) => (
    columns.map((column, i) => (
      <div className="all-pioneers__column">
        <ColumnTitle 
          column={column}
          currentSort={sort}
          setSort={setSort}
          searchActive={!!query}
        />
        {!hideContent && <div className="all-pioneers__column-data">
          {renderData(column)}
        </div>}
      </div>
    ))
  )


  const renderData = (column) => {
    if (!column.renderer) column.renderer = (d) => d ? d : '---';
   
    return results.map((d) => {
      return <div className="all-pioneers__data">
        {isNull(d[column.key])
        ? '---'
        :column.renderer(d[column.key], d, d.id)}
      </div>
    })
  }

  const showMore = () => {
    setOffset((prev) => prev + 20);
  };

  
  return <div className="'all-pioneers__wrapper">
    {loading && <LoadingPane />}
    <Header query={query} setQuery={setQuery} />
    <div className="all-pioneers__table" style={loading ? {minHeight: 700} : {}}>
      {renderColumn(!results?.length)}
    </div>
    
    {results.length && 
    <>
    <div className="all-pioneers__line"/>
    <div onClick={showMore} className="all-pioneers__show-more">
      {'load more'}
    </div>
    </>}
    {!results?.length && <h1 className='all-pioneers__no-result'>No results to show</h1>}
  </div>
}


export default Card(AllPioneers, true, 'All Pioneers', { width: '100%'});