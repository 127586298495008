/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import ScssWrapper from 'components/scss-wrapper';
import './style.scss';
import About from 'components/pioneer-data/about';
import MainSurvey from 'components/pioneer-data/main-survey';
import AllSurveys from 'components/pioneer-data/all-surveys';
import HourlyUses from 'components/hourly-uses';
import LoadingUI from 'components/loading';
import { MeContext } from 'context/me';

const Wrapper = (props) => <ScssWrapper parent={props.parent ? props.parent : 'me-journalling'}{...props}/>


function Me() {

  const { 
    journals, 
    loaded, 
    sprays,
    user 
  } = useContext(MeContext);
  

  if (!loaded) return <LoadingUI />;
  
  return <div>
    <div className="me-journalling">
      <Wrapper className="about">
        <About user={user} />
      </Wrapper>
      <Wrapper className="hourly-uses">
        <HourlyUses 
          user={user}
          journals={journals}
          sprays={sprays} />
      </Wrapper>
      <Wrapper className="survey">
        <MainSurvey user={user} />
      </Wrapper> 
    </div>
    <Wrapper parent='me' className="all-surveys">
      <AllSurveys padding={40} journals={journals} />
    </Wrapper>
  </div>  
}

export default Me;
