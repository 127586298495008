import React from 'react';
import './style.scss';

const Border = () => {
        
    return <svg 
      style={{
        height: '100%', 
        width: '100%',
        overflow: 'hidden',
        borderRadius: 16
      }} className="border">
      <defs>
        <linearGradient id="linear" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" stop-color="rgba(95, 149, 255, 0.15)"/>
          <stop offset="100%" stop-color="rgba(255, 255, 255, 0.00)"/>
        </linearGradient>
      </defs>
          

      <rect 
        rx={17} 
        ry={17} 
        x="0" 
        y="0" 
        width='100%'
        height='100%'
        stroke="url(#linear)" 
        strokeWidth={2.5} 
        fill="none"/>
    </svg>
}

export default Border