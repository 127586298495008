import AvgSprayTime from 'components/average-spray-time'
import PioneersMap from 'pages/dashboard/pioneers-map';
import CurrentlyInUse from 'components/currently-in-use';
import GenderByAge from 'components/gender-by-age';
import MostActive from 'components/most-active';
import Wrapper from 'components/scss-wrapper';
import BottleDuration from 'components/plain-value/components/bottle-duration';
import SpraysToday from 'components/plain-value/components/sprays-today';
import TotalSprays from 'components/plain-value/components/total-sprays';
import AvgSpraysPerDay from 'components/plain-value/components/avg-sprays-day';
import AverageAge from 'components/plain-value/components/average-age';
import UsersActiveToday from 'components/plain-value/components/users-active-today';
import ActivePioneers from 'components/plain-value/components/activated-pioneers';
import React from 'react';
import './style.scss';
import { useLoading } from 'utils/hooks';
import TotalJournals from 'components/plain-value/components/total-journals';
import SleepIndicator from 'components/sleep-indicator';

const PlainValueWrapper = ({ Component }) => <Component parent="dashboard" /> 


function Dashboard() {

  const loader = useLoading();
  if (loader) return loader;

  return (<>  
    <div className="dashboard">
      <Wrapper className="avg-spray-time">
        <AvgSprayTime/>
      </Wrapper>
      <Wrapper className="pioneers-map">
        <PioneersMap />
      </Wrapper>
      <Wrapper className="sleep-indicator">
        <SleepIndicator />
      </Wrapper>
       <Wrapper className="currently-in-use">
        <CurrentlyInUse />
      </Wrapper>
      <Wrapper className="gender-by-age">
        <GenderByAge />
      </Wrapper>
      <Wrapper className="most-active">
        <MostActive />
      </Wrapper>
      
      <PlainValueWrapper
        Component={ActivePioneers} />
      <PlainValueWrapper
        Component={UsersActiveToday} />
      <PlainValueWrapper
        Component={AverageAge} />
      <PlainValueWrapper
        Component={TotalJournals} />
      <PlainValueWrapper
        Component={TotalSprays} />
      <PlainValueWrapper 
        Component={SpraysToday} />
      <PlainValueWrapper
        Component={AvgSpraysPerDay} />
      <PlainValueWrapper 
        Component={BottleDuration}/>
  </div>
  </>)
}

export default Dashboard;
