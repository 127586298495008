/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-undef */
import React, { useEffect, useRef } from 'react';
import "./style.scss";
import MarkerClusterer from '@googlemaps/markerclustererplus';
import mapStyle from './mapStyle';
import {GoogleApiWrapper} from 'google-maps-react';
import {Map} from 'google-maps-react';
import Border from 'components/border';
import Card from 'components/card';
import { useContext } from 'react';
import { DataContext } from 'context/data';



function MapContainer(props) {
  let { data } = useContext(DataContext);
  let locations = data?.locations ? data.locations : [] 
  const { height, width } = props;
  const mapRef = useRef(null);
  
  useEffect(() => {
    
    class Overlay extends google.maps.OverlayView {
      bounds;
      div;
      divCount;
      divCountry;
    
      constructor(bounds, map) {
        super();
        this.count = 1
        this.bounds = bounds;
        this.setMap(map);
      }
    
      onAdd() {
        this.div = document.createElement("div");
        this.div.classList.add("pioneers-map__tag");
        this.divCount = document.createElement("div");
        this.divCount.classList.add("pioneers-map__div-count");
        this.divCountry = document.createElement("div");
        this.divCountry.classList.add("pioneers-map__div-country");
        this.div.appendChild(this.divCount);
        this.div.appendChild(this.divCountry);
        const panes = this.getPanes();
        panes.overlayMouseTarget.appendChild(this.div);
      }
    
      onRemove() {
        if (this.div) {
          this.div.parentNode.removeChild(this.div);
          delete this.div;
        }
      }
    
      hide() {
        if (this.div) {
          this.div.style.visibility = "hidden";
        }
      }
    
      show(latlng, translation) {
        const overlayProjection = this.getProjection();
        const coords = overlayProjection.fromLatLngToDivPixel(latlng)
        const transformX = coords.x > 0 ? '-210px' : '40px'
        this.div.style.top = (coords.y + translation)+ 'px'
        this.div.style.left = coords.x + 'px';
        this.div.style.transform = `translate(${transformX}, -45px)`;
        this.div.style.visibility = "visible";
      }

      getCountry(markers) {
        let country = "";
        let same = true;
        markers.forEach((m) => {
          if (!country) country = m.country;
          if (country && country !== m.country) same = false;
        })

        return same || !country ? country : "";
      }
      
      toggle(latlng, markers, translation = 0) {
        if (!this.div) return;
        this.show(latlng, translation);
        const length = markers.length ? markers.length : "1"
        const country = this.getCountry(markers);
        this.divCount.innerHTML = `${length} pioneer${length > 1 ? 's' : ''}`;
        this.divCountry.innerHTML = country ?  `${country}` : '';
        this.divCountry.style.marginTop = country ? '10px' : '0px';
      }
    }
    
    if (!locations.length) return

    const bounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(62.281819, -150.287132),
      new google.maps.LatLng(62.400471, -150.005608)
    );
    
    const overlay = new Overlay(bounds, mapRef.current.map);
    
    let markers = locations.map((location, i) => {


      const thisLocation = {
        lng: location.longitude,
        lat: location.latitude,
      }

      if (!thisLocation.lng | !thisLocation.lat) return null;
      
      let marker = new google.maps.Marker({
        position: thisLocation,
        icon: {
          url: 'https://firebasestorage.googleapis.com/v0/b/open-images/o/dot.svg?alt=media&token=e226ac85-c402-4465-a3a3-e990eb899afd',
          scaledSize: new google.maps.Size(
            25,
            25
          )
        },
        zIndex: 10,
      });
      marker.country = location.country;
      marker.addListener('click', (event) => {
        if (overlay) overlay.toggle(event.latLng, [marker], -20);
      })
      return marker
    });  

    markers = markers.filter((m) => m !== null)

    const calculator = (markers, numStyles) => {
      let index = 0;
      const count = markers.length;
      let total = count;
      while (total !== 0) {
        total = parseInt(total / 5, 10);
        index++;
      }
      index = Math.min(index, numStyles);

      return {
        text: count + " ("+ index + ")",
        index: index
      };
    }

    const style = (size) => MarkerClusterer.withDefaultStyle({
      width: size,
      height: size,
      url: 'https://firebasestorage.googleapis.com/v0/b/open-images/o/dot.svg?alt=media&token=e226ac85-c402-4465-a3a3-e990eb899afd',
      textColor: 'transparent',
      zIndex: 100,
      backgroundPosition: '0 0'
    })

    const clusterer = new MarkerClusterer(mapRef.current.map, markers, {
      title: "",
      calculator,
      optimized: false,
      zoomOnClick: false,
      styles: [
        style(60), style(75), style(90)
      ]
    })

    google.maps.event.addListener(clusterer, 'clusterclick', (cluster) => {
      if (overlay) overlay.toggle(cluster.getCenter(), cluster.getMarkers());
    })

    const hideTag = () => {
      if(overlay) overlay.hide();
    }

    google.maps.event.addListener(mapRef.current.map, 'zoom_changed', hideTag);
    google.maps.event.addListener(mapRef.current.map, 'center_changed', hideTag);

  }, [locations])

  const mapLoaded = (mapProps, map) => {
    map.setOptions({
      disableDefaultUI: true,
      styles: mapStyle
    })
  }

  const wrapperStyle = {
    position: 'relative', 
    height, width, 
    backgroundColor: '#06091d',
    borderRadius: 16, overflow: 'hidden'
  };
  
  return <div className="pioneers-map" style={wrapperStyle}>
    <Map
      ref={mapRef}
      minZoom={2.4}
      maxZoom={6}
      initialCenter={{lat: 28.160206, lng: 10.576359}}
      center={{lat: 18.160206, lng: -4.576359}}
      zoomControl={true} 
      onReady={(mapProps, map) => mapLoaded(mapProps, map)}
      google={props.google} zoom={1} 
      style={{backgroundColor: 'black'}}
    />
    <Border height={height} width={width} />
  </div>


}

function LoadingContainer() {
    return <div></div>;
}

export default Card(GoogleApiWrapper({
    apiKey: ('AIzaSyAgkuQ6WR4ol_zflJQVLEK-a9oGS8xaNF4'),
    LoadingContainer: LoadingContainer,
})(MapContainer), true);
