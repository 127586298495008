/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import Card from 'components/card';
import React, { useState, useEffect  } from 'react';
import Remove from 'assets/icons/remove.svg';
import AddUserIcon from 'assets/icons/add-user.svg';
import './style.scss';
import MessageModal from 'components/message-modal';
import OneLineInput from 'components/one-line-input';
import ConfirmButton from 'components/confirm-button';
import { deleteUser, inviteAdmin } from 'api/auth';
import { useContext } from 'react';
import { MessageContext } from 'context/message';
import { UserContext } from 'context/user';
import { isNull } from 'utils/data';

const columns = {
  name: {
    key: 'name'
  }, 
  email: {
    key: 'email',
    name: 'email'
  }, 
  status: {
    key: 'status',
    name: 'status',
    render: (status, user, invited, reload) => <Status 
      status={status} 
      invited={invited}
      reload={reload}
      allowDeletion={user?.type == '0'} />
  }, 
  image: {
    key: 'img',
    name: 'image',
    render: (src) => (
      <div>
      {src ? <img src={src} alt='profile_pic' className='invite-users__img' /> :
      <div className='invite-users__img' />}
      </div>
    )
  }
};

const Status = ({ status, allowDeletion, invited, reload }) => {

  const [ modalVisible, setModalVisible ] = useState(false);

  const _deleteUser = async () => {
    await deleteUser(invited.email)
    setModalVisible(false);
    reload();
  }

  return <div className='invite-users__status-wrapper'>
    <MessageModal 
    onConfirm={_deleteUser}
    visible={modalVisible}
    setVisible={setModalVisible} 
    prompt='are you sure you want to remove user? this action cannot be undone.' />
    {status === 'pending' ?
    <div className='invite-users__pending'>pending</div> :
    allowDeletion && <img className='invite-users__remove-invitation' onClick={setModalVisible} src={Remove} alt='remove' />}
  </div>
}

const AddUser = ({ setVisible, setReload }) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState('');
  const { user } = useContext(UserContext);
  const { pushMessage } = useContext(MessageContext);
  const [isSubAdmin, setIsSubAdmin] = useState(true);
  const { pushError } = useContext(MessageContext); 

  const invite = async () => {
    try {
      setLoading(true)
      await inviteAdmin({name, email, type: isSubAdmin ? '1' : '0'})
      pushMessage('User invited');
      setVisible(false)
      setReload((r) => !r)
    } catch (err) {
      pushError("We couldn't invite this user. Try with another email or try it later.");
    }
    setLoading(false);
  }

  const toggleSubAdmin = (e) => {
    setIsSubAdmin(!isSubAdmin);
  }

  function isEmailValid(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  return <div className="add-user">
    <h4>Invite new User </h4>
    <OneLineInput label="user's name" value={name} setValue={setName} />
    <OneLineInput label="user's email" value={email} setValue={setEmail} />
    {!isNull(user.type) && user.type == 0 && <div className='add-user__question'>
      Allow this admin to delete other admins?
      <input type='checkbox' value={!isSubAdmin} onChange={toggleSubAdmin} />
    </div>}
    <ConfirmButton 
    label="invite" 
    loading={loading}
    onClick={invite} 
    disabled={name.length < 1 || !isEmailValid(email) || loading} />
  </div>
}


function Column({ columnKey, data, reload }) {

  const { user } = useContext(UserContext);

  return <div className='invite-users__column'>
  {data.map((i) => columns[columnKey]?.render 
  ? columns[columnKey].render(i[columnKey], user, i, reload) 
  : <div className='invite-users__key'>{i[columnKey]}</div>)}
  </div>
}

function InviteUsers() {
  const { user } = useContext(UserContext);
  const sorted_keys = ['image', 'name', 'email', 'status'];
  const [modalVisible, setModalVisible] = useState(false);
  const [invitations, setInvitations] = useState([]);
  const [_reload, setReload] = useState(false);

  useEffect(() => {

  }, [user, _reload]);


  const reload = () => setReload((r) => !r)

  return <div className='invite-users'>
    <MessageModal visible={modalVisible} setVisible={setModalVisible}>
      <AddUser setVisible={setModalVisible} setReload={setReload}/>
    </MessageModal>
    <img src={AddUserIcon} 
      alt='add user' 
      onClick={() => setModalVisible(true)} 
      className='invite-users__new-invite'></img>
    <h1>admin users</h1>
    <div className='invite-users__table'>
      {sorted_keys.map((columnKey) => <Column 
      columnKey={columnKey} 
      data={invitations}
      reload={reload} />)}
    </div>
  </div>
}

export default Card(InviteUsers, true, '', { height: 'min-content' });
