import React, { useEffect, useState } from 'react';
import LoadingUI from 'components/loading';
import { useLoading } from 'utils/hooks';
import styled from 'styled-components';
import firebase from "firebase";
import Card from "components/card/v2";

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.3)
`

const List = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  flex:1;
`;

const PebbleRow = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-between;
`;

const PebbleField = styled.a`
  margin-left: 10px;
  font-family: Gilroy-Regular;
  color: white;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background: 'transparent'
`;

const Title = styled.h3`
  font-family: Gilroy-Bold;
  font-size: 25px;
  line-height: 25px;
  padding-right: 20px;
  color: white;
  margin: 0px;
`

const PebbleWrapper = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  padding: 50px 45px;
  padding-bottom: 0px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255,255,255,0.02);
  border-radius: 15px;
  border: 1px solid rgba(255,255,255,0.1);
  height: 35px;
  margin-right: 10px;
  width: 250px;
  align-self: flex-end;
  padding-left: 15px;
  padding-right: 15px;

  &::focus {
    border: 1px solid rgba(255,255,255,0.2);
  }
`;

const SearchInput = styled.input`
  color: white;
  opacity: 0.8;
  font-family: Gilroy-SemiBold;
  background: transparent;
  outline: none;
  flex: 1;
  border:none;
`;


function Pebbles() {
  const [pebbles, setPebbles] = useState([]);
  const [search, setSearch] = useState("");
  const [filtered, setFiltered] = useState([]);

  const getAllPebbles = async () => {
    const callable = firebase.functions().httpsCallable('getAllPebbles');
    const result = await callable();
    setPebbles(result.data)
  }

  useEffect(() => {
    getAllPebbles()
  }, [])

  useEffect(() => {
    setFiltered(pebbles.filter(pebble => pebble.email.includes(search)))
  }, [pebbles, search])

  if (useLoading()) return <LoadingUI />;

  const list = search ? filtered : pebbles;

  return (
    <Card style={{position: 'relative'}} gridArea="fullfilment-status">
     <PebbleWrapper>
      <Row>
        <Title>Pebbles</Title>
        <SearchWrapper>
         <SearchInput placeholder="Search for email" type='text' value={search} onChange={(value) => setSearch(value.target.value)} />
        </SearchWrapper>
      </Row>

      {pebbles.length && 
        <List>
          {list.map(pebble => <PebbleRow key={pebble.email}>
            <PebbleField href={pebble.url}>{pebble.email}</PebbleField>
            <PebbleField>{new Date(pebble.createdAt).toLocaleString()}</PebbleField>
          </PebbleRow>)}
        </List>
      }
     </PebbleWrapper>
    </Card>
  )
}

export default Pebbles;
