import React, { useEffect, useState } from 'react';
import { capitalize } from 'utils/string'
import './style.scss';

import GreenDefault from 'assets/petals/green/Green 01.svg';
import RedDefault from 'assets/petals/red/Red 01.svg';
import BlueDefault from 'assets/petals/blue/Blue 01.svg';

const defaultImages = {
  green: GreenDefault,
  red: RedDefault,
  blue: BlueDefault
}

export default function Flower({color, formulation, numberPetals}) {

  const [petalsImage, setPetalsImage] = useState(defaultImages[color]);

  if (numberPetals > 10) numberPetals = 10;
  if (numberPetals < 0) numberPetals = 0;

  useEffect(() => {
    const fileNumber = (numberPetals + 1).toString().padStart(2, '0');
    const colorName = capitalize(color);
    import(`assets/petals/${color}/${colorName} ${fileNumber}.svg`).then((file) => {
      setPetalsImage(file.default);
    })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numberPetals])

  return <div className='flower'>
    <img src={petalsImage} alt={`you got ${numberPetals} petal${numberPetals > 1 ? 's' : ''}`} />
    <h1>{formulation === 0 
      ? 'baseline'  
      : `no.${formulation}`}</h1>
  </div>

}
