import React, { useEffect, useState } from 'react';
import done from 'assets/icons/done.svg';
import errorIcon from 'assets/icons/error.svg';
import './style.scss';

export const MessageContext = React.createContext(null);

const Message = ({data, duration, error}) => {
  const transitionDuration = 400;
  const [visible, setVisible] = useState('');

  const transitionStyle = {
    transitionDuration: `${transitionDuration/1000}s`
  }

  useEffect(() => {
    setTimeout(() => setVisible('visible'), 50);
  }, [])

  useEffect(() => {
    if (visible !== null) 
      setTimeout(() => setVisible(''), duration - (transitionDuration * 2));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible])

  const Contents = ({ hidden }) => (
    <div className={`message-context__contents ${hidden ? 'hidden' : ''}`}>
      {!error ? <img src={done} alt='done' /> :
      <img src={errorIcon} alt='error' />}
      <div className='message-context__text'>{data}</div>
    </div>
  )

  return <div style={transitionStyle} className={`message-context__message ${visible}`}>
    <Contents hidden />  
    <div className="message-context__blur">
      <Contents />
    </div>
  </div>

}

function MessageContextWrapper({children}) {
	const [errors, setErrors] = useState([]);
	const [messages, setMessages] = useState([]);
  const duration = 5000;
  
  const pushMessage = (message) => {
    setMessages((existing) => [...existing, message])
    removeAfterTimeout(setMessages, message)
  }

  const pushError = (message) => {
    setErrors((existing) => [...existing, message]);
    removeAfterTimeout(setErrors, message)
  }

  const removeAfterTimeout = (setter, toRemove) => {
    setTimeout(() => {
      setter((existing) => existing.filter((e) => e !== toRemove));
    }, duration)
  }

  return <MessageContext.Provider value={{pushError, pushMessage}}>
    <div className="message-context__wrapper">
      {errors.map((message) => <Message error data={message} duration={duration}/>)}
      {messages.map((message) => <Message data={message} duration={duration}/>)}
    </div>
    {children}
  </MessageContext.Provider>
  
}

export default React.memo(MessageContextWrapper);
