/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import './style.scss';
import eye from 'assets/icons/eye.svg';

export default function PasswordInput(props) {
  const {setValue, value, label = 'label'} = props;
  const [focused, setFocused] = useState(false);
	const [passwordVisible, setPasswordVisible] = useState(false);
  const legendClass = `one-line-input__legend ${focused || value !== '' ? ' focused' : ''}`;

  const onFocus = () => {
    setFocused(true);
    if (props.onFocus) props.onFocus() 
  }

  const onBlur = () => {
    setFocused(false);
    if (props.onBlur) props.onBlur() 
  }

  return <div className="one-line-input">
    <div className="one-line-input__leading"/>
    <div className="one-line-input__notch">
      <div className={legendClass}>{label}</div>
    </div>
    <div className="one-line-input__trailing"/>
    <div className="one-line-input__input-wrapper">
      <input
        {...props}
        type={passwordVisible ? "text" : "password"}
        placeholder={!focused ? label : ''}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        onChange={e => setValue(e.target.value)}
      />
			<img alt="show password" 
        onMouseDown={() => 
				setPasswordVisible(true)} 
				onMouseUp = {() => setPasswordVisible(false)}
				src={eye} className="one-line-input__eye" />
    </div>
  </div>
}