import React, { useContext, useState } from 'react';
import './style.scss';
import b64ToBlob from "b64-to-blob";
import fileSaver from "file-saver";
import Loader from 'react-loading';
import download_icon from 'assets/icons/download.svg';
import { UserContext } from 'context/user';
import { getZipData } from 'api/admin';
import { useLocation } from 'react-router-dom';
import axios from 'axios';

function DownloadButton({param, userId}) {
    
    const [downloading,setDownloading] = useState(false);
    
    const isUser = param === 'user';

    const downloadAllData = async () => {
        try{
            setDownloading(true);
            const response = await getZipData(param);
            var xhr = new XMLHttpRequest();
            xhr.responseType = 'blob';
            xhr.onload = async (event) => {
                var json = xhr.response;
                fileSaver.saveAs(json, `${param}.json`);
                setDownloading(false);
            };
            xhr.open('GET', response.data[0]);
            xhr.send();
        } finally {
            setDownloading(false);
        }
    }

    const downloadUserData = async () => {
        try {
            setDownloading(true)
            const result = await getZipData(param, userId);
            const zipAsBase64 = result.data;
            const blob = b64ToBlob(zipAsBase64, "application/zip");
            fileSaver.saveAs(blob, `${userId}.zip`);
        } finally {
            setDownloading(false)
        }
        
    }
    
    return <button 
    className='download-all-data__button'
    style={{opacity: downloading ? 0.5 : 1}}
    onClick={isUser ? downloadUserData : downloadAllData}>
        {downloading ?
        <Loader type='bubbles' height={15} width={15} /> : param
        }
    </button>
}

export default function DownloadAllData() {

    const { user } = useContext(UserContext);
    const { pathname } = useLocation();
    let userId;

    const params = [
        'users',
        'journals',
        'sprays',
        'surveys',
        'keys',
    ]

    if (pathname.match(/\/admin\/pioneer\//)) {
        params.push('user');
        userId = pathname.split('/')[3];
    }

    
    if (user.type !== 2) return <div />

    return <div className='download-all-data'>
        <img src={download_icon} alt='download' />
        {params.map((p) => <DownloadButton 
            param={p} 
            userId={p === 'user' ? userId : null}
        />)}
    </div>
    
}