/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function AvgSpraysPerDay() {
	const { data } = useContext(DataContext);
	const { sprays, lastActive } = data;

	const counts = sprays.reduce((prev, spray) => {
		const user = lastActive.filter((u) => u.id === spray.user)[0];
		if (!user) return prev; 
		if (!spray?.date?.toLocaleDateString) return prev;

		const thisDate = spray?.date?.toLocaleDateString('en-US', {timeZone: user.timezone});
		if (!prev[thisDate]) prev[thisDate] = 1
		else prev[thisDate] += 1;

		return prev;
	}, {})

	const avg = Object.values(counts).reduce((prev, next) => prev + next, 0)/Object.values(counts).length
	
	return <PlainValue
				className="avg-sprays-per-day"
				label="Average sprays per day" 
				value={isNaN(avg) ? '--' : avg.toFixed(1) }
	/>
};

export default AvgSpraysPerDay;
