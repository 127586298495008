import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function ActivePioneers() {

	const { data } = useContext(DataContext);
    
	return <PlainValue
		className="activated-pioneers"
		label="Activated pioneers" 
		value={data?.userCount}
	/>

}

export default ActivePioneers;
