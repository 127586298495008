import Card from 'components/card';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import React from 'react';
import './style.scss';

const Loading = (props) => {

    return <div className='loading-wrapper' {...props} style={{opacity: 0.2}}>
        <Loader width={40} height={40} color='white' type='Grid' />
    </div>

}

const Inner = React.memo(Card(Loading, true));

const _LoadingCard = ({number}) => {
	return <div style={{gridArea: `g${number}` }}>
		<Inner/>
	</div>
}

const LoadingCard = React.memo(_LoadingCard);

function LoadingUI() {
    return <div className='loading-ui'>
        <LoadingCard number="1" />
        <LoadingCard number="2" />
        <LoadingCard number="3" />
        <LoadingCard number="4" />
        <LoadingCard number="5" />
        <LoadingCard number="6" />
    </div>
}

export default React.memo(LoadingUI);