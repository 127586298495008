import { updatePassword } from 'api/auth';
import ConfirmButton from 'components/confirm-button';
import OneLineInput from 'components/one-line-input/password';
import { MessageContext } from 'context/message';
import { UserContext } from 'context/user';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router';
import './style.scss';

export default function CreatePassword() {

	const [newPassword, setNewPassword] = useState('');
	const { user } = useContext(UserContext);
	const history = useHistory();
	const { pushMessage, pushError } = useContext(MessageContext);
  	const handlePasswordChange = async () => {
		try {
			await updatePassword(user.email, newPassword);
			pushMessage('Password successfully updated!')
		} catch (err) {
			pushError('There was an error changing your password. Please try it later.')
		}
		if (user.type !== 2) history.replace('/admin/home');
		else history.replace('/me');
	};

  	const canSubmitPassword = newPassword.length > 5;

	return <div className='forgot-password'>
		<h1>Create your password</h1>
    	<OneLineInput label="new password" value={newPassword} setValue={setNewPassword} />
		<ConfirmButton label="continue" onClick={handlePasswordChange} disabled={!canSubmitPassword} />
	</div>

}