/* eslint-disable react-hooks/exhaustive-deps */
//////SEPARATE

import Card from 'components/card';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import ladySofi from 'assets/images/lady-sofi.svg';
import ScoreChart from 'graphs/score-chart';
import { getScoreMessage } from './result';

const MIN_DATES = 10;

const getDatesBefore = (days = 10, offset = 0) => {
    const dates = []
    for (let i = offset; i <= days; i++ ) {
        let date = new Date(new Date().getTime() - (i * 24 * 60 * 60 * 1000));
        const formatted_date = date.toLocaleDateString('en-US');
        dates.push({
            date: formatted_date,
            score: 50,
            score_copy: 'result_copy_wait'
        });
    }
    return dates;
}

function MyScore({meData, ...props}) {
    //return <h1 style={{fontFamily: 'Gilroy-Bold', color: 'white'}}>Coming Soon</h1>
    
    const [formatted, setFormatted] = useState([]);
    const ref = useRef(null);
    const sofiScore = meData?.score

    const fillDates = () => {
        const num_dates = sofiScore ? sofiScore?.length : 0;
        const remaining = getDatesBefore(MIN_DATES - num_dates, num_dates);
        const with_remaining_dates = remaining ;
        return sofiScore?.length
        ? sofiScore?.concat(with_remaining_dates)
        : with_remaining_dates;
    }

    const formatter = (dates) => {
        const result = dates.map((data) => {
            const date_string = data.date.split('/');
            const date = new Date(date_string[2], date_string[0] - 1, date_string[1]);
            console.log(date)
            return {
                date, 
                score: parseInt(data.score),
                message: data.score_copy
            }
        })
        const new_arr = [...result]
        new_arr.sort((a, b) => b.date.getTime() - a.date.getTime());
        return new_arr;
    }

    useEffect(() => {
        if (!meData.loaded) return;
        const with_remaining_dates = fillDates()
        setFormatted(formatter(with_remaining_dates))
    }, [sofiScore])

    
    if (!formatted?.length) return <div className='my-score__waiting-wrapper'>
        <h1>Not enough data</h1>
    </div>

    return <div style={{height: '100%', width: '100%'}} ref={ref} className='my-score'>
        <h1>My score</h1>
        <div className='my-score__message'>
            <img src={ladySofi} alt='lady sofi' />
            <p>{getScoreMessage(formatted[0].message)}</p>
        </div>
        <ScoreChart {...props} data={formatted} /> 
    </div>
    
}

export default Card(MyScore, true, '');
