/* eslint-disable react-hooks/exhaustive-deps */
//////SEPARATE

import Card from 'components/card';
import React, { useContext } from 'react';
import './style.scss';
import long_arrow from 'assets/icons/long-arrow.svg';
import { MeContext } from 'context/me';
import ReactLoading from 'react-loading';

function duration(created_at_str) {

    const timeDifference = new Date().getTime() - new Date(created_at_str).getTime();
    const one_day = 24 * 60 * 60 * 1000;
    const one_hour = 60 * 60 * 1000;
    const one_minute = 60 * 1000;
    const units = 
        timeDifference > one_day 
        ? one_day 
        : timeDifference > one_hour 
        ? one_hour
        : one_minute;
    
    const value = (timeDifference / units).toFixed(0);
    const unit_string = 
        timeDifference > one_day
        ? 'd'
        : timeDifference > one_hour
        ? 'h'
        : 'm'

    return value + unit_string;

}

function Topic(props) {
    return <div className='topic'>
        <div className='topic__top'>
            <img src={props?.user?.profile_pic} alt={props.user.username} className='topic__user-pic'/>
            <div>{props?.user?.username}</div>
            <div className='topic__separator'>  · </div>
            <div>{props.reply_count} {props.reply_count === 1 ? 'reply' : 'replies'}</div>
            <div className='topic__separator'>  ·  </div>
            <div>{duration(props.created_at)}</div>
            <div style={{flex: 1}} />
            
            <a href={props.url} target='__blank' className='topic__open'>
                see the conversation
                <img src={long_arrow} alt='' />
            </a>
            
        </div>
        <h2>{props.title}</h2>
    </div>
}

function TreehouseTopics({name, title}) {

    const { treehouseData } = useContext(MeContext);
    
    if (!treehouseData) return <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%'
    }}>
        <ReactLoading type="spinningBubbles" color="white" />
    </div>

    const topics = treehouseData[name].slice(0,3);
    
    return <>
    <h1 div className='treehouse-title'>{title}</h1>
    <div className='treehouse-topics'>
        {topics?.map((topic, i) => <Topic key={i} {...topic} />)}
    </div>
    </>
}

export default Card(TreehouseTopics, true, '');
