import React, { useContext }from 'react';
import './style.scss';
import JournallingTime from 'pages/journalling/journalling-time';
import ScssWrapper from 'components/scss-wrapper';
import EmotionalSlider from 'pages/journalling/emotional-slider-aggregate';
import MoodLastEntry from 'pages/journalling/mood-last-entry';
import JournallingStatus from 'pages/journalling/journalling-status/journalling-status';
import LatestJournals from 'pages/journalling/latest-journals';
import TotalJournals from 'components/plain-value/components/total-journals';
import JournalsToday from 'components/plain-value/components/journals-today';
import AverageJournalTime from 'components/plain-value/components/avg-journal-time';
import AverageJournalDuration from 'components/plain-value/components/avg-journal-duration';
import LoadingUI from 'components/loading';
import { useLoading } from 'utils/hooks';

const PlainValueWrapper = ({ Component }) => <Component parent="pioneers" /> 
const Wrapper = (props) => <ScssWrapper parent="journalling" {...props}/>


export default function Journalling (){
  if (useLoading()) return <LoadingUI />;

  
  return <div className="journalling">
    <Wrapper className='journalling-time'>
      <JournallingTime />
    </Wrapper>*
    <Wrapper className='emotional-slider'>
      <EmotionalSlider />
    </Wrapper>
    <Wrapper className='mood-last-entry'>
      <MoodLastEntry />
    </Wrapper>
    <Wrapper className='journalling-status'>
      <JournallingStatus />
    </Wrapper>
    <Wrapper className='latest-journals'>
      <LatestJournals />
    </Wrapper>
    <PlainValueWrapper Component={TotalJournals} />
    <PlainValueWrapper Component={JournalsToday} />
    <PlainValueWrapper Component={AverageJournalTime} />
<PlainValueWrapper Component={AverageJournalDuration} />
  </div>

};
