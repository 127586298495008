import React, { useRef, useState, useEffect } from 'react';
import Border from 'components/border';
import './style.scss';
import useSize from 'utils/useSize';

export default function Card(
  InnerComponent, 
  paddingNull, 
  title = "Average Spray Time", 
  overrideDimensions,
  headerRight) {

  return function Card(props) {  
    const ref = useRef(null);
    const {width, height} = useSize(ref);
    
    const style = !overrideDimensions ? {height: '100%', width: '100%'} : overrideDimensions;
    const childrenStyle = !overrideDimensions ? {} : { width: '100%' }
    props = {
      ...props,
      width, 
      height
    }
    
    return <div 
      className="card" 
      style={style}
      ref={ref}>
      {!paddingNull && <div className="card__header">
        <div className="card__title">{title}</div>
        {headerRight}
      </div>}  
      <div className="card__children" style={childrenStyle}>
        {<InnerComponent  {...props} />}
      </div>
      <Border height={'100%'} width={'100%'} />
      <div className="card__background" />
      <div className="card__background-gradient" />
    </div>
  }
}
