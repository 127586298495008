import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function AverageJournalDuration() {

	const { data } = useContext(DataContext);
	const { journals } = data
  
	const avgDuration = (journals.reduce((prev , journal) => {
		return prev + journal.endDate.getTime() - journal.startDate.getTime()
	},0)/(journals.length * 1000)).toFixed(1);
  
	return <PlainValue
		className="average-journal-duration"
		label="Average Journal Duration" 
		value={avgDuration + ' s'}
	/>

}

export default AverageJournalDuration;