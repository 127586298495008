import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';
import { isToday } from 'utils/time';

import { DataContext } from 'context/data';

function JournalsToday() {

	const { data } = useContext(DataContext);
	const { lastActive, journals } = data;

	const localDate = new Date().toLocaleDateString('en-US');

	const count = journals.reduce((prev, journal) => {
		const user = lastActive.filter((u) => u.id === journal.user)[0];
		if (!user) return prev; 
		const userDate = journal.endDate.toLocaleDateString('en-US', { timeZone: user.timeZone  });
		return userDate === localDate ? prev + 1 : prev
	}, 0)

	
	return <PlainValue
		className="journals-today"
		label={"Journals on " +  new Date().toLocaleDateString('en-GB')}
		value={count}
	/>
}

export default JournalsToday;
