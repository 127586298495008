import React, {forwardRef} from 'react'

const InputWithTimedKeyup = forwardRef((props, ref) => {
  var typingTimer = props.timer;
  var doneTypingInterval = 1000;
    
  const _onKeyUp = () => {
    clearTimeout(typingTimer?.current);
    typingTimer.current = setTimeout(props.onKeyUp, doneTypingInterval)
  } 

  const sanitized = {};
  Object.keys(props).forEach((key) => {
    if (key !== 'onKeyUp') sanitized[key] = props[key];
  })

  const _onChange = () => {
    if (sanitized.onChange()?.force) _onKeyUp();
  }

  return <input ref={ref} onKeyUp={_onKeyUp} onChange={_onChange} {...sanitized} /> 

})

export default InputWithTimedKeyup;
