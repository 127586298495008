import InviteUsers from 'components/invite-users';
import UserData from 'components/user-data';
import React from 'react';
import './style.scss'

export default function Settings() {
  return <div className='settings'>
    <UserData />
    <InviteUsers />
  </div>
}