/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, useRef } from 'react';
import firebase from 'firebase';
import { useHistory, useLocation } from 'react-router';
import { getProfilePicURL } from 'utils/storage';
import { MessageContext } from '../message';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import "./style.scss";

export const UserContext = React.createContext({});


function UserContextWrapper({children}) {
  
  const history = useHistory();
  const { pathname } = useLocation();
  const { pushMessage } = useContext(MessageContext);
  const [user, setUser] = useState({ loaded: false });
  const [loadingNewUser, setLoadingNewUser] = useState(false);

  const getCustomClaims = async () => {
    const token = await firebase.auth().currentUser.getIdTokenResult();
    return token.claims;
  }

  const getUserData = async (id) => {
    try{
      let db_data = await firebase.firestore().collection('users').doc(id).get();
      return db_data.data();
    } catch (err) {
    }
  }

  const updateProfilePic = async () => {
    const profile_pic = await getProfilePicURL(user.uid);
    setUser((existing) => Object.assign(existing, {profile_pic}));
  }

  const _setUser = async (user) => {
    const claims = await getCustomClaims();
    const data = await getUserData(user.id);
    const userInfo = {
      displayName: user?.displayName,
      email: user?.email,
      uid: user?.uid
    }

    const localUser = {
      type: claims.type,
      accepted_terms: claims.accepted_terms,
      ...userInfo,
      ...data,
      loaded: true,
    } 

    setUser(localUser)
  }
  
  useEffect(() => {
    if (user.loaded) redirects();
    setLoadingNewUser(false);
  }, [user]);


  const showHelloMessage = async (displayName) => {
    const welcome = await sessionStorage.getItem('SOFI_WELCOME_MESSAGE');
    if (!welcome && displayName) {
      pushMessage('Hi ' + displayName?.split(' ').shift() + '!');
      await sessionStorage.setItem('SOFI_WELCOME_MESSAGE', 'sent');
    }
  }

  useEffect(() => {
     const updater = async (incoming) => {
      if (incoming) {
        _setUser(incoming)
      } else if (!incoming){
        setUser({loaded: true})
      } 
    }

    firebase.auth().onIdTokenChanged(updater)
  }, [])

  const forceUpdateUser = async () => {
    await new Promise(r => setTimeout(r, 10))
    await firebase.auth().updateCurrentUser(firebase.auth().currentUser);
    await firebase.auth().currentUser.getIdToken(true);
  }

  useEffect(() => {
    redirects();
  }, [pathname])

  const redirects = async () => {    
    if (!user.loaded) return;

    const unauthenticated_paths = [
      '/sign-in',
      '/forgot-password',
      '/create-password',
    ]

    const admin_path = '/admin';
    const admin_home = '/admin/home';
    const me_path = '/me'

    if (user.loaded 
      && user.uid 
      && !user.accepted_terms
      && (pathname.includes(me_path)
      || pathname.includes(admin_path))) {

      history.replace('/terms')
    }


    if (user.loaded 
      && user.uid 
      && user.accepted_terms
      && pathname.includes('/terms')) {
        showHelloMessage(user?.displayName);
        if (!user.type !== undefined) history.replace(me_path)
        else history.replace(admin_home)
    }

    if (user.loaded 
      && user.uid 
      && !user.type
      && pathname.includes(admin_path)) {
        
      history.replace(me_path)
    }

    if (user.loaded
      && !user.uid
      && pathname.includes(me_path)) {
        history.replace('/sign-in')
    }

    if (user.loaded 
      && user.uid
      && unauthenticated_paths.includes(pathname)) {
        showHelloMessage(user?.displayName);
        history.replace(user.type !== undefined
          ? admin_home
          : me_path)
    }
  }


  return  <UserContext.Provider 
  value={{ 
    user,
    setUser,
    forceUpdateUser,
    loadingNewUser,
    setLoadingNewUser,
    updateProfilePic}}>
    {user.loaded ? children : 
    <div className='user__loader' >
      <Loader width={40} height={40} color='white' type='Grid' />
    </div>}
  </UserContext.Provider>
};

export default React.memo(UserContextWrapper);
