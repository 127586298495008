import React, { useState } from 'react';
import styled from 'styled-components';
import Card from "components/card/v2";
import firebase from 'firebase';
import Dropdown from 'components/dropdown-clean';
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const BASELINE_STATUS = [
  'STARTED_JOURNALLING',
  'FINISHED_JOURNALLING',
  'WAITING_FOR_SOFI_PEBBLE',
  'COMPLETE'
]

const PROGRAM_STATUS = [
  'WAITING_FOR_SHIPMENT',
  'POD_ACTIVE',
  'PROGRAM_FINISHED',
]

const BASELINE_STATUS_OPTIONS = [
  { name: 'STARTED_JOURNALLING' },
  { name: 'FINISHED_JOURNALLING' },
  { name: 'WAITING_FOR_SOFI_PEBBLE' },
  { name: 'COMPLETE'},
]

const PROGRAM_STATUS_OPTIONS = [
  { name: 'WAITING_FOR_SHIPMENT' },
  { name: 'POD_ACTIVE' },
  { name: 'PROGRAM_FINISHED' },
]

const Loading = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  background: rgba(0,0,0,0.6);
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

const InputWrapper = styled.div`
  border-radius: 10px;
  color: white;
  background: transparent;
  border: 1px solid rgba(255,255,255,0.4);
  display: flex;
  align-items: center;
  width: min-content;
  align-self: center;
`;

const Input = styled.input`
  border-radius: 10px;
  color: white;
  background: transparent;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  width: 300px;
  font-family: Gilroy-Regular;
  outline: none;
  border: none;
`;

const Wrapper = styled(Card)`
  display: flex;
  flex-direction: column; 
  padding: 30px;
  min-height: initial;
  margin-bottom: 10px;
  position: relative;
`;

const Button = styled.div`
  background: white;
  color: gray;
  border-radius: 5px;
  margin: 5px;
  font-family: Gilroy-Regular;
  font-size: 13px;
  padding: 7px;
  cursor: pointer
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 200px 2fr 100px 200px;
  grid-auto-rows: 40px;
  grid-auto-flow: row;
  align-items: center;
`;

const Header = styled.div`
  color: rgba(255,255,255,0.5);
  border-bottom: 1px solid rgba(255,255,255,0.3);
  font-family: 'Gilroy-Bold';
  padding-bottom: 10px;
  font-size: 12px;
`;

const Email = styled.h2`
  color: white;
  font-size: 30px;
  color: white;
  font-family: Gilroy-Bold
`;

const Text = styled.h3`
  font-family: Gilroy-Thin;
  color: white;
  font-size: 15px;
  margin-top: 30px;
  color: gray;
`;

const Content = styled.div`
  font-family: Gilroy-Regular;
  color: white;
  font-size: 15px;
`;

const ActiveToggle = styled.div`
  cursor: pointer;
  border-radius: 30px;
  width: 50px;
  height: 20px;
  background: rgba(255,255,255,1);
  border: 1px solid rgba(255,255,255,0.4);
`;

const Toggle = styled(ActiveToggle)`
  background: transparent;
`;

const Checkbox = ({ checked, setSelected, disabled }) => {

  const props = {
    onClick: setSelected,
    disabled
  }

  if (checked) return <ActiveToggle { ...props } />
  else return <Toggle { ...props } />

}

const programs = [
  'S1',
  'S2',
  'S3',
  'S1_V2',
]

function Row({ 
  program, 
  status, 
  active, 
  setActive, 
  approvedForShipment,
  setapprovedForShipment, 
  setProgramStatus,
}) {

  return <>
    <Content>{program}</Content>
    <Dropdown 
      options={PROGRAM_STATUS_OPTIONS} 
      selected={{ name: !isNaN(status) ? PROGRAM_STATUS[status] : 'none'  }}
      setSelected={(s) => {
        const option = PROGRAM_STATUS_OPTIONS.indexOf(s);
        setProgramStatus(option);
      }}
      />
    <Content><Checkbox 
      type='checkbox' 
      checked={active} 
      setSelected={setActive}/>
    </Content>
    <Content><Checkbox 
      type='checkbox' 
      checked={approvedForShipment} 
      setSelected={setapprovedForShipment}/>
    </Content>
  </>
}

function BaselineRow({ status, active, setProgramStatus}) {

  return <>
    <Content>Baseline</Content>
    <Dropdown 
      setSelected={(option) => setProgramStatus(BASELINE_STATUS.indexOf(option.name))}
      options={BASELINE_STATUS_OPTIONS} 
      selected={{ name: !isNaN(status) ? BASELINE_STATUS[status] : 'none' }} />
    <Content><Checkbox disabled={!status} type='checkbox' checked={active}/></Content>
    <Content>{''}</Content>
  </>
}

function User({
  email,
  status,
  program,
  disabled,
  approvedForShipment,
  updateActiveProgram,
  updateapprovedForShipment,
  setProgramStatus,
  loading
}) {

  

  const programStatus = status ? Object.values(status).filter((s) => s.program !== 'baseline') : [];
  const baseline = status ? Object.values(status).find((s) => s.program === 'baseline') : [];

  const setActiveProgram = async (email, p) => {
    if (p === program) return;
    await updateActiveProgram(email, p)
  };

  const setapprovedForShipment = (email, p) => {
    if (!approvedForShipment?.length) approvedForShipment = []

    if (approvedForShipment?.includes(p)) updateapprovedForShipment(email, approvedForShipment.filter((a) => a !== p))
    else updateapprovedForShipment(email, [...approvedForShipment, p])
  } 

  return <Wrapper>
    {loading && <Loading><Loader height={40} width={40} type='Grid' color='white' /></Loading>}
    <Email>{email}</Email>
    <Grid style={{ pointerEvents: disabled ? 'none' : 'initial' }}>
      <Header>Program</Header>
      <Header>Status</Header>
      <Header>Active</Header>
      <Header>Approved for Shipping</Header>
      
      <BaselineRow 
        {...baseline} 
        active={program === 'baseline'} 
        setProgramStatus={(s) => setProgramStatus(email, 'baseline', s)}
      />
      {
        programs.map((p) => <Row 
        {...programStatus.find((s) => s.program === p )} 
        active={program === p}
        setActive={() => setActiveProgram(email, p)}
        setapprovedForShipment={() => setapprovedForShipment(email, p)}
        approvedForShipment={approvedForShipment?.includes(p)}
        setProgramStatus={(s) => {setProgramStatus(email, p, s);
        }}
        program={p} />)
      }
    </Grid>
  </Wrapper>

}

function Status() {

  const [results, setResult] = useState([]);
  const [searching, setSearching] = useState(false);
  const [query, setQuery] = useState('');
  const [loading, setLoading] = useState(false);

  const search = async (forceEmail) => {
    try {
      setSearching(true)
      const fn = firebase.functions().httpsCallable('searchFirestoreStatus');
      const email = forceEmail ? forceEmail : query;
      const res = await fn({ email });
      console.log(res);
      setResult(res.data);
    } catch (err) {
      console.log(err);
    } finally {
      setSearching(false);
    }
  };

  const updateActiveProgram = async (email, program) => {
    setLoading(true)
    const fn = firebase.functions().httpsCallable('updateUser');
    await fn({ email, program });
    await search(email)
    setLoading(false)
  };

  const updateapprovedForShipment = async (email, approvedForShipment) => {
    setLoading(true);
    const fn = firebase.functions().httpsCallable('updateUser');
    await fn({ email, approvedForShipment });
    await search(email);
    setLoading(false)
  }

  const setProgramStatus = async (email, program, status) => {
    setLoading(true);
    console.log(email, program, status)
    const fn = firebase.functions().httpsCallable('updateUser');
    await fn({ email, status: { [program]: {
      status,
      program,
      date: new Date()
    } } })
    await search(email);
    setLoading(false)
  }

  return (<>
    <Wrapper>
      {searching && <Loading><Loader height={40} width={40} type='Grid' color='white' /></Loading>}
      <InputWrapper>
        <Input 
        placeholder='Search for an user email'
        value={query} 
        onChange={e => setQuery(e.target.value)} 
        />
        <Button disabled={searching} onClick={() => search()}>Search</Button>
      </InputWrapper>
      
    </Wrapper>
    {results.map((r, i) => <User 
      {...r} 
      disabled={loading}
      updateActiveProgram={updateActiveProgram}
      updateapprovedForShipment={updateapprovedForShipment}
      setProgramStatus={setProgramStatus}
      loading={loading}
      key={i} />)}
    
  </>)
}

export default Status;
