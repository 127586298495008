import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function AverageJournalTime() {

	const { data } = useContext(DataContext);
	const { journals, lastActive } = data

	const getLocalDate = (journal) => {
		const user = lastActive.find((u) => u.id === journal.user);
		const time = journal.endDate.toLocaleString('en-US', {timeZone: user?.timezone});
		const full_hour = time.split(',')[1].split(':');
		const hour = full_hour[0];
		const minute = full_hour[1];
		return new Date(0, 0, 1, hour, minute, 0, 0);
	}

	const normalizedTime = journals?.reduce((prev, j) => {
		const date = getLocalDate(j);
		return prev + date.getTime();
	}, 0) / journals?.length


	const time = new Date(normalizedTime)
		.toLocaleTimeString('en-GB');

	return <PlainValue
		className="average-journal-time"
		label="Average Journal Time" 
		value={time.substring(0, 5)}
	/>

}

export default AverageJournalTime;
