/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState, useContext } from 'react';
import { UserContext } from 'context/user';
import { useLocation } from 'react-router-dom';
import { groupOptions, programOptions, timeOptions } from 'config/constants';
import { getAdminData } from 'api/admin';
import Dexie from 'dexie';

export const DataContext = React.createContext({});
export const db = new Dexie('sofi');

const userModel = `id, createdAt, age, lastActive, lastJournal, name, program, group, timezone, start_group, current_group, email, gender,locationName, latitude, longitude`

db.version(1).stores({
  journals: 'id, type, startDate, quality, value, values, endDate, hours, formulation, user',
  counts: '++id, userCount, activeTodayCount',
  locations: '++id, latitude, longitude, country',
  lastActive: userModel, 
  mostActive: userModel,
  sprays: 'id, date, formulation, user, sprayCount',
  changes: '++id, date',
});

const filterMapper = (filter) => {
  return filter.map((f) => f.value);
}

const defaultGroup = groupOptions;
const defaultProgram = programOptions;
const defaultTimeframe = [timeOptions[0]];

function DataContextWrapper({ children }) {
  const location = useLocation();
  const [data, setData] = useState({});
  const [group, setGroup] = useState(null);
  const [program, setProgram] = useState(null);
  const [loading, setLoading] = useState(false);
  const [timeFrame, setTimeframe] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { user } = useContext(UserContext);
  
  const canFetchData = () =>
    location.pathname.includes('/admin')
    && !loading
    && user?.uid
    && group
    && program
    && timeFrame

  const fixDateFields = (data, fields) => {
    return data?.map((raw) => {
      fields.forEach((field) => {
        if (!raw[field]) return;
        raw[field] = new Date(raw[field])
      });
      return raw;
    })
  }

  const getMostActiveUsers = (adminData) => {

    const counts = adminData.journals.reduce((prev, journal) => {;
      if (!prev[journal.user]) prev[journal.user] = 0;
      return {
        ...prev,
        [journal.user]: prev[journal.user] + 1,
      }
    },{});

    let userIds = Object.keys(counts);
    userIds.sort((a, b) => counts[b] - counts[a]);
    userIds = userIds.slice(0, Math.ceil(userIds.length * 0.25));
    return adminData.lastActive.filter((u) => userIds.includes(u.id));
  
  }

  const setLocations = (lastActiveUsers) => {
    return lastActiveUsers.map((user) => ({
      latitude: user.latitude,
      longitude: user.longitude,
      country: user.locationName
        ? user.locationName.split(',')[1]
        : ''
    }))
  }

  const getExistingPreferences = async () => {
    const preferences = JSON.parse(await sessionStorage.getItem('SOFI_PREFERENCES'));
    setGroup(preferences ? preferences.group : defaultGroup);
    setProgram(preferences ? preferences.program : defaultProgram);
    setTimeframe(preferences ? preferences.timeFrame : defaultTimeframe);
  }

  const updatePreferences = async () => {
    const preferences = JSON.stringify(JSON.parse(await sessionStorage.getItem('SOFI_PREFERENCES')));
    const newPreferences = JSON.stringify({ group, program, timeFrame });
    
    if (newPreferences !== preferences) {
      await sessionStorage.setItem('SOFI_PREFERENCES', newPreferences);
      return true; //updated;
    } else {
      return false;
    }
  }

  const AGE_LIMIT = 1000 * 60 * 5; //5 min;

  const shouldUpdateCache = async () => {
    const changes = await db.changes.toArray();
    const data_age = changes.pop()?.date;
    if (!data_age) return true;
    const is_data_old = data_age && (new Date().getTime() - new Date(data_age).getTime() > AGE_LIMIT)
    const preferences_changed = await updatePreferences();
    if (is_data_old || preferences_changed) return true;
  };

  const clearDexieData = async () => {
    await db.journals.clear();
    await db.sprays.clear();
    await db.mostActive.clear();
    await db.locations.clear();
    await db.lastActive.clear();
    await db.counts.clear();
    await db.changes.clear();
  }

  const getDexieData = async () => {

    const journals = await db.journals.toArray();
    const sprays = await db.sprays.toArray();
    const mostActive = await db.mostActive.toArray();
    const locations = await db.locations.toArray();
    const lastActive = await db.lastActive.toArray();
    const counts = await db.counts.toArray();

    return {
      journals,
      sprays,
      mostActive,
      locations,
      lastActive,
      ...counts[0]
    }
  }

  const addDataToDexie = async ({
    journals, 
    sprays,
    mostActive,
    locations,
    lastActive,
    counts,
  }) => {
    await db.journals.bulkPut(journals);
    await db.sprays.bulkPut(sprays);
    await db.mostActive.bulkPut(mostActive);
    await db.locations.bulkPut(locations);
    await db.lastActive.bulkPut(lastActive);
    await db.counts.add(counts);
    await db.changes.add({ date: new Date() });
  }

  const fetchData = async (fetchFunction) => {
    if (!canFetchData()) return;
    
    setLoading(true);
    await checkNewSession();
    
    /*
    const shouldUpdate = await shouldUpdateCache();

    if (!shouldUpdate) {
      //const dexieData = await getDexieData();
      //setData(filter(dexieData));
      setLoaded(true);
      setLoading(false);
      return;
    };
    */
    
    await clearDexieData(); //for safety, delete before attempt to fetch
    let adminData = await fetchFunction();

    adminData = {
      ...adminData,
      journals: fixDateFields(adminData.journals, ['endDate', 'startDate']).filter((j) => !!j.endDate),
      sprays: fixDateFields(adminData.sprays, ['date']),
      locations: setLocations(adminData.lastActive),
      lastActive: fixDateFields(adminData.lastActive, ['createdAt', 'lastActive', 'lastJournal']),
    }

    adminData.mostActive = getMostActiveUsers(adminData)

    setData(adminData);
    setLoaded(true);
    setLoading(false);
    
  }

  const checkNewSession = async () => {
    const session = await sessionStorage.getItem('SOFI_SESSION');
    if (!session) clearDexieData();
    await sessionStorage.setItem('SOFI_SESSION', 'true');
  }

  const fetchAdminData = async () => {
    try {
      setLoaded(false);
      setData({});

      const changes = await db.changes.toArray();
      const data_age = changes.pop()?.date;
      const startDate = data_age ? new Date(data_age) : new Date().getTime() - timeFrame[0].value

      return await getAdminData(
        new Date(startDate).toISOString(),
        filterMapper(program),
        filterMapper(group),
      );
    } catch (err) {
    }
  }

  useEffect(() => {
    if (user?.type > 0) {
      console.log('cons', user.type);
      fetchData(fetchAdminData)
    };
  }, [
    location, 
    user, 
    group, 
    program, 
    timeFrame
  ]);

  useEffect(() => {
    getExistingPreferences();
  }, [])

  useEffect(() => {

  }, [timeFrame])



  


  return <DataContext.Provider value={{
    data,
    loaded,
    setTimeframe,
    loading,
    timeFrame,
    setData,
    setLoaded,
    setProgram,
    program,
    setGroup,
    group,
    clearData: clearDexieData
  }}>
    {children}
  </DataContext.Provider>
};


export default React.memo(DataContextWrapper);
