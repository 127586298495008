/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import './App.scss';
import { BrowserRouter as Router } from "react-router-dom";
import Footer from 'components/footer';
import Background from 'components/background';
import MessageContext from 'context/message';
import DataContextWrapper from 'context/data';
import firebase from 'firebase';
import Routes from './routes';
import UserContextWrapper from 'context/user';
import WebFont from 'webfontloader';
import MeContextWrapper from 'context/me';
import { getApp } from 'utils/firebase';

function App() {

  const [defaultState, setDefaultState] = useState(false);

  useEffect(() => {
    setDefaultState(true);
  }, [])

  getApp();

  WebFont.load({
    custom: {
      families: ['Gilroy-Thin', 'Gilroy-Bold', 'Gilroy-Semibold', 'Gilroy-Light', 'Gilroy-Regular', 'Gilroy-Extrabold'],
      urls: ['./app.scss']
    },
  });
  
  return (<>
  <Background />
  <MessageContext>
    <Router>  
    <UserContextWrapper>
      <DataContextWrapper>
        <MeContextWrapper>
          {defaultState && <Routes />}
          {defaultState && <Footer />}
          </MeContextWrapper>
        </DataContextWrapper>
      </UserContextWrapper>
    </Router>
  </MessageContext>
  </>);
}

export default App;
