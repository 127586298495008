import React, { useEffect, useRef } from 'react';
import * as THREE from 'three';
import './style.scss';
import pt from 'assets/particles/1.png';

function Background() {

  const ref = useRef(null);

  useEffect(() => {

    if (!ref.current) return;
    const canvas = ref.current;
    const scene = new THREE.Scene();
    const textureLoader = new THREE.TextureLoader();
    const particleTexture = textureLoader.load(pt);
    

    const particlesGeometry = new THREE.BufferGeometry();
    const count = 800;

    const positions = new Float32Array(count * 3);

    for(let i = 0; i < count * 3; i++) {
      positions[i] = (Math.random() - 0.5) * 40  
    }

    particlesGeometry.setAttribute(
      'position',
      new THREE.BufferAttribute(positions, 3));
    
    
    const particlesMaterial = new THREE.PointsMaterial();
    particlesMaterial.size = 0.5;
    particlesMaterial.sizeAttenuation = true;
    particlesMaterial.color = new THREE.Color('#1E0CFF');

    particlesMaterial.transparent = true;
    particlesMaterial.alphaMap = particleTexture;
    particlesMaterial.depthWrite = false;
    particlesMaterial.blending = THREE.AdditiveBlending;
    
    
    const particles = new THREE.Points(particlesGeometry, particlesMaterial);
    particles.position.y = -10;

    scene.add(particles);

    const sizes = {
        width: window.innerWidth,
        height: window.innerHeight
    }


    window.addEventListener('resize', () =>
    {
        // Update sizes
        sizes.width = window.innerWidth
        sizes.height = window.innerHeight
        renderer.setSize(sizes.width, sizes.height)
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
    });


    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
    camera.position.z = 0;

    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias:true,
      alpha:true
    });
  
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
  
    scene.add(camera);
    
    
    const clock = new THREE.Clock()

    const cursor = {
        x: 0,
        y: 0
    } 

    window.addEventListener('mousemove', (event) => {
      cursor.x = event.clientX / sizes.width - 0.5;
      cursor.y = event.clientY / sizes.height - 0.5;
    })


    let t = 0 
    const tick = () => {
      const elapsedTime = clock.getElapsedTime()

      t = document.body.getBoundingClientRect().top;
      particles.position.y = -12;
      particles.position.y = -12 + elapsedTime * 0.2 - t * 0.0004;
      particles.rotation.y = elapsedTime * 0.009;
      
      camera.rotation.y = cursor.x * 0.099;
      renderer.render(scene, camera)
      window.requestAnimationFrame(tick);
    }
    
    tick();

    
  }, [])

  return <>
    <div className="webgla__mask"/>
    <canvas ref={ref} className="webgla" />
  </>

}

export default React.memo(Background);
