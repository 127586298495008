/* eslint-disable react-hooks/exhaustive-deps */
//////SEPARATE

import Card from 'components/card';
import React, { useEffect, useRef, useState } from 'react';
import night from 'assets/icons/night.svg';
import day from 'assets/icons/day.svg';
import sunrise from 'assets/icons/sunrise.svg';
import sunset from 'assets/icons/sunset.svg';
import './style.scss';
import PetalsDisplay from 'components/petals-display';
import { color } from 'utils/formulation';
import { getLocaleTime } from 'utils/time';

const ContentCell = ({ events }) => {
  const cell_color = color(events[0].formulation + 1)
  const time = new Date(events[0].date);
  const hour = time.toTimeString().split(':')[0];
  const time_display = getLocaleTime(time).split(' ').join('').toLocaleLowerCase();
  const sprays = events.reduce((prev, next) => prev + next.sprayCount, 0);
  const [visible, setVisible] = useState(false);

  const Sign = () => {
    return <div className='hourly-uses__sign'
      style={{
        display: visible ? 'flex' : 'none',
        bottom: parseInt(hour) < 4 ? -55 : 10,
      }}>
      <h3>{time_display}</h3>
      <h4>{sprays === 1 ? `${sprays} spray` : `${sprays} sprays`}</h4>
    </div>
  }

  return <div
    style={{
      position: 'relative',
      background: 'gray',
      borderRadius: '20px',
      ...cell_color,
    }}>
    <Sign />
    <div
      onClick={() => setVisible((c) => !c)}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}

      style={{
        position: 'absolute',
        top: -5,
        left: -2,
        bottom: -5,
        right: -2,
      }}
    />
  </div>

}


const XAxisTick = ({ date }) => {
  const label = date.getDate()
  return <div className='hourly-uses__x-axis-tick'>{label}</div>
}

const HourlyUses = ({ user, sprays, journals, ...props }) => {

  const mapped_sprays = sprays.map((j) => ({
    ...j,
    date: new Date(j.date)
  }))


  const checkExistingEvent = (date, hour) => {
    return mapped_sprays.filter((spray) =>
      spray.date.toDateString() === date.toDateString()
      && spray.date.getHours() === hour
    );
  }


  const YAxisTick = ({ hour, icon }) => (
    <div className="hourly-uses__y-axis-tick">
      <img src={icon} alt="hour" />
      <div className="hourly-uses__tick-hour">{hour}</div>
    </div>
  );

  const rowCount = 24

  const userCreateDate = user.createdAt.seconds ?
    user.createdAt.seconds : user.createdAt.getTime() / 1000;

  const columnCount = Math.max(20, Math.ceil(((new Date().getTime() / 1000)
    - userCreateDate) / (24 * 60 * 60)))

  const rowHeight = '2px';

  const columnWidth = '30px';

  let cells = []

  const mapDataToGrid = (_, day) => {
    const date = new Date((new Date().getTime() - (day * 24 * 60 * 60 * 1000)))
    const row = []
    for (let hour = 0; hour < 25; hour++) {
      const existingEvents = checkExistingEvent(date, hour);
      row.push(
        hour === 24 ?
          <XAxisTick date={date} />
          : existingEvents.length
            ? <ContentCell events={existingEvents} />
            : <div />)
    };
    cells = [row, ...cells]
  }

  Array.from(Array(columnCount)).forEach(mapDataToGrid)

  const yAxisElements = Array.from(Array(rowCount + 1)).map((_, hour) => {
    let element;

    const amPm = ['AM', 'PM']
      .includes(new Date()
        .toLocaleTimeString('pt-BR')
        .split(' ')
        .pop()
      );

    switch (hour) {
      case 0:
        element = <div style={{ marginTop: -2 }}>
          <YAxisTick hour={!amPm ? "0:00" : "12:00am"} icon={night} />
        </div>
        break;
      case 6:
        element = <YAxisTick hour={!amPm ? "6:00" : "6:00am"} icon={sunrise} />
        break;
      case 12:
        element = <YAxisTick hour={!amPm ? "12:00" : "12:00pm"} icon={day} />
        break;
      case 18:
        element = <YAxisTick hour={!amPm ? "18:00" : "6:00pm"} icon={sunset} />
        break;
      case 24:
        element = <div style={{ marginBottom: 0 }}>
          <YAxisTick hour={!amPm ? "0:00" : "12:00am"} icon={night} />
        </div>
        break;
      default:
        element = <div />
    }
    return element;


  })

  const hourlyUsesGrid = {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 20,
    width: 'min-content',
    marginRight: 40,
    marginLeft: 40,
    gridTemplateRows: `repeat(${rowCount}, ${rowHeight}) 100px`,
    gridTemplateColumns: `repeat(${columnCount}, ${columnWidth})`,
  }

  const yAxis = {
    display: 'grid',
    gridAutoFlow: 'column',
    gap: 20,
    gridTemplateRows: `repeat(${rowCount}, ${rowHeight}) 50px`,
    gridTemplateColumns: '80px',
    marginTop: 3,
    paddingRight: 30,
  }

  const YAxis = () => <div className='hourly-uses__y-axis' style={yAxis}>
    {yAxisElements}
  </div>

  const wrapper = useRef(null)

  useEffect(() => {
    if (wrapper.current) wrapper.current.scrollTo(10000, 0)
  }, [wrapper]);


  return <div className='over-wrapper'>
    <div style={{ display: 'flex', flexDirection: 'column', width: '100%', }}>
      <h1 className='hourly-uses__title'>spray journal</h1>
      <div style={{ display: 'flex', overflowY: 'visible', }}>
        <YAxis />
        <div className='hourly-uses__container' ref={wrapper} style={{ flex: 1, overflowX: 'scroll' }}>
          <div style={hourlyUsesGrid}>
            {cells}
          </div>
        </div>
      </div>
      <PetalsDisplay journals={journals} />
    </div>
  </div>
}

export default Card(HourlyUses, true, '');
