import React from 'react';
import './style.scss';
import Anxiety from 'assets/emotions/Anticipation.svg';
import Anger from 'assets/emotions/Anger.svg';
import Joy from 'assets/emotions/Joy.svg';
import Confident from 'assets/emotions/Confident.svg';
import Sadness from 'assets/emotions/Sadness.svg';
import Excitement from 'assets/emotions/Surprise.svg';
import Refreshed from 'assets/emotions/Refreshed.svg';
import Tired from 'assets/emotions/Tired.svg';

const moodProps = {
    anxiety: {
        img: Anxiety,
        color: 'rgba(212, 84, 135, 1)'
    },
    anger: {
        img: Anger,
        color: 'rgba(243, 101, 96, 1)'
    },
    joy: {
        img: Joy,
        color: 'rgba(240, 183, 91, 1)'
    },
    confident: {
        img: Confident,
        color: '#7AB74F'
    },
    sadness: {
        img: Sadness,
        color: 'rgba(72, 99, 223, 1)'
    },
    excitement: {
        img: Excitement,
        color: 'rgba(50, 193, 230, 1)'
    },
    refreshed: {
        img: Refreshed,
        color: '#41B9A5'
    },
    tired: {
        img: Tired,
        color: 'rgba(153, 106, 200, 1)'
    },
}

export default function Mood({mood, percentage}) {
    
    if (!moodProps[mood]) return <div>Invalid Mood</div>
    
    const { color, img } = moodProps[mood];

    return <div class="mood-wrapper">
        <img className="mood-wrapper__img" src={img} alt="mood" />
        <div className="mood-wrapper__percentage">{percentage.toFixed(1)}<b>%</b></div>
        <div className="mood-wrapper__title" style={{color}}>{mood}</div>
    </div>

}