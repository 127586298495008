import React from 'react';
import './style.scss';

export default function Table({
    columnTitles,
    showColumnTitle,
    rows,
    renderRows = {},
}) {

  const defaultRenderRows = (row) => row;

  const renderColumnItems = (rows, column) => {
    return rows.map((row, i) => {
      if (!renderRows[column]) renderRows[column] = defaultRenderRows;
      return <div className="table__item" key={i}>
        {renderRows[column](row[column], row.id)}
      </div>
    })
  }

  const renderColumn = (columnTitles) => {
    return columnTitles.map((column, i) => (
      <React.Fragment key={i}>
      {showColumnTitle && <div className="table__column-title">{column}</div>}
      <div className="table__column">
        {renderColumnItems(rows, column)}
      </div>
      </React.Fragment>));
  }

  return <div className="table">
    {renderColumn(columnTitles)}
  </div>

}