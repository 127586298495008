/* eslint-disable react-hooks/exhaustive-deps */
import Card from 'components/card';
import SleepCircle from 'components/sleep-circle';
import { DataContext } from 'context/data';
import React, { useContext } from 'react';
import './style.scss';

function SleepIndicator() {
  
  const { data, timeFrame } = useContext(DataContext);
  const { journals } = data;
  
  const time = (offset) => new Date(new Date().getTime() - (60 * 60 * 1000 * offset));
  const oneDayAgo = time(24);
  const twoDaysAgo = time(48);

  const props = (j , hourEnd = new Date(), hourStart = new Date(100) ) => {
    const result = j.reduce((prev, journal) => {
      const date = new Date(journal.endDate).getTime();
      
      
      if (
        journal.type === 7
        && date > hourStart.getTime() 
        && date < hourEnd.getTime())
      return {
          sleepQuality: prev.sleepQuality + journal.quality,
          sleepHours: prev.sleepHours + journal.hours,
          total: prev.total + 1
        }
      else return prev;
    }, {sleepQuality: 0, sleepHours: 0, total: 0})

    return {
      sleepQuality: result.sleepQuality / result.total,
      sleepHours: result.sleepHours/ result.total,
      visible: result.total > 0
    }
  }

  return <div className='sleep-indicator'>
    <SleepCircle topText={timeFrame[0].name} {...props(journals)}/>
    <SleepCircle topText='last night' {...props(journals, new Date(), oneDayAgo)}/>
    <SleepCircle topText='2 nights ago' {...props(journals, oneDayAgo, twoDaysAgo)}/>  
  </div>
}

export default Card(SleepIndicator, true, '');
