/* eslint-disable react-hooks/exhaustive-deps */
import * as d3 from "d3";
import React, { useEffect, useRef, useState } from 'react';
import "./style.scss"
import Night from 'assets/icons/night.js';
import Sunrise from 'assets/icons/sunrise.js';
import Day from 'assets/icons/day.js';

function LineChart({data, width, height, number}) {
  const ref = useRef(null)


  useEffect(() => {
    if (ref.current) {
      const card = ref.current.parentNode.parentNode;
      card.style.overflow = "visible"
    }
  }, [])

  const [clusters, setClusters] = useState([]);
  
  const xScale = d3.scaleLinear()
  .domain([0, data.length * 60])
  .range([0, width]);
  
  const yScale = d3.scaleLinear()
  .range([300, 100 ]) //height of data
  .domain([-1, 1]);

  const clusterData = () => {
    let _clusters = {};
    if (!data) return;
    var minutes = Array(data.length * 60).fill('0');
    const cluster_interpolator = d3.interpolateBasis(data);
    const values = d3.quantize(cluster_interpolator,  minutes.length);
    _clusters = minutes.map((d, i) => ({ minute: i, value: values[i] }))
    setClusters(_clusters);
  };

  const generateCurve = (svg) => {    
    
    if (!clusters.length) return;
    const fakeClusters = [...clusters]
    fakeClusters[0].value = yScale.domain()[0];
    fakeClusters[clusters.length - 1].value = yScale.domain()[0];

    const initialCoordinates = d3.line()
    .x((d) => xScale(d.minute))
    .y((d) => yScale(0.5))
    .curve(d3.curveLinear);
    
    const pathCoordinates = d3.line()
    .x((d) => xScale(d.minute))
    .y((d) => yScale(d.value))
    .curve(d3.curveLinear)

    
    const line = svg.append('path')
    .datum(clusters)
    .attr('fill', 'url(#gr_gradient)')
    .attr('d', initialCoordinates)
    .style('transform', 'translateY(-100px)')

    line
    .transition()
    .duration(1000)
    .attr('d', pathCoordinates)


    return line
  }


  useEffect(() => {
    if (height < 0) return;

    const render = () => {
      
      const svg = d3.select("#line-chart-with-fill" + '-' + number);
      
      while (svg.node().firstChild 
        && svg.node().firstChild?.tagName !== 'defs') {
        svg.node().removeChild(svg.node().firstChild);
      }

      const axisGenerator = d3
      .axisBottom(xScale)
      .tickSize(0)
      .tickPadding(3)
      .tickFormat((d, i) => {
        return Math.round(d / 60) + 7
      })

      svg.style("overflow", "visible");

      svg.append('g').call(axisGenerator).attr('transform', `translate(0,${height - 60})`);
      
      svg.selectAll(".tick text")
      .nodes()
      .reverse()
      .forEach(function(t, i){
        if (!(i % 2)) t.innerHTML = ""
      })

      const hoursWithIcon = ["9", "15", "22"]
      

      svg.selectAll(".tick")
      .nodes()
      .forEach((t, i) => {
        switch(t.lastChild.innerHTML) {
          case hoursWithIcon[0]:
            t.innerHTML = t.innerHTML + Sunrise;
            break;
          case hoursWithIcon[1]:
            t.innerHTML = t.innerHTML + Day;
            break;
          case hoursWithIcon[2]:
            t.innerHTML = t.innerHTML + Night;
            break;
          default:
            break;
        }
      })

      svg.selectAll(".tick svg")
      .attr("y", "40")
      .attr("x",  "-9.5")

      svg.selectAll(".tick text")
      .attr("class","line-chart-with-fill__text");

      svg.selectAll("path.domain")
      .attr("class","line-chart-with-fill__line");

      generateCurve(svg)
      
    }

    render();
  },[clusters, height, width]);




  useEffect(() => {
    clusterData();
  }, [])

  if (height < 0) return <div />



  return (
    <svg ref={ref}
    className="line-chart-with-fill__wrapper" 
    id={`line-chart-with-fill-${number}`} height={height} width={width}>
      <defs>
        <linearGradient id="gr_gradient" x2="0%" y2="100%" >
          <stop offset="0%" stop-color="rgba(255,255,255, 0.5)" />
          <stop offset="80%" stop-color="rgba(255,255,255, 0.1)" />
          <stop offset="99%" stop-color="rgba(255,255,255,0.0)" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default LineChart;
