/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';
import { getGenderString } from 'utils/gender';

function MostActiveGender() {
	
	const { data } = useContext(DataContext);
	const { mostActive } = data;
	const [percentage, setPercentage] = useState(0)
	const [gender, setGender] = useState(null);

	useEffect(() => {
		const totals = mostActive.reduce((prev, user) => (
			{ ...prev, 
				[user?.gender]: prev[user?.gender] + 1}
		), { 0: 0, 1: 0, 2: 0});

		const mostActiveGender = Object.keys(totals).reduce((a, b) => totals[a] > totals[b] ? a : b);
		setGender(mostActiveGender)
		setPercentage(totals[mostActiveGender] / Object.values(totals).reduce((a, b) => a + b, 0))

	}, [mostActive])


	return <PlainValue
		className="gender"
		label={getGenderString(parseInt(gender))} 
		value={(percentage * 100).toFixed(0) + '%'}
	/>
}

export default MostActiveGender;