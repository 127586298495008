import Card from "components/card/v2";
import Dropdown from "components/dropdown";
import React, { useEffect, useState } from "react";
import firebase from "firebase";
import styled from "styled-components";
import Loader from 'react-loading';

const RefillToggleWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
`

const options = [
    {
      name: 'Refills Available'  
    },
    {
        name: 'Refills Unavailable'  
    }
]

const RefillToggle = () => {

    const [selected, setSelected] = useState(options[0]);
    const [loading, setLoading] = useState(true);
    const [disabled, setDisabled] = useState(false);

    const getData =  async () => {
        try {
            const callable = firebase.functions().httpsCallable('areRefillsAvailable');
            const result = await callable();
            setLoading(false);

            setSelected(result.data ? options[0] : options[1]);
        } catch (err) {
            console.log(err)
        }
    }

    const updateToggle = async () => {
        try {
            setDisabled(true);
            const callable = firebase.functions().httpsCallable('toggleRefillsAvailable');
            const result = await callable();
            setSelected(result.data ? options[0] : options[1]);
            setDisabled(false);
        } catch (err) {
            setDisabled(false);

        }

    }

    useEffect(() => {
        getData()
    }, [])

    return <Card style={{ marginTop: 20 }} gridArea="refill-toggle">
            <RefillToggleWrapper>
            {loading ? <Loader type="cylon" /> : 
            <Dropdown   
                width={300}
                selected={selected}
                disabled={disabled}
                setSelected={updateToggle}
                options={options}
            ></Dropdown>}
            </RefillToggleWrapper>
    </Card>


}

export default RefillToggle;