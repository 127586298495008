import React from 'react';
import Flower from 'components/flower';
import './style.scss'

export default function PetalsDisplay({ journals, ...props }) {

  const getPetalCount = (formulation) => {

    const finishedJournals = journals.filter((j) => !!j.endDate);

    const journalsPerFormulation = finishedJournals.filter((j) => {
      return formulation === 'baseline'
        ? !j.formulation
        : j.formulation === formulation
    }
    );

    const journalsPerDay = {}
  
    journalsPerFormulation.forEach((j) => {
      const date = new Date(j.endDate).toDateString();
      journalsPerDay[date] = j;
    });
    return Object.values(journalsPerDay).length
  }

  const scale = { transform: 'scale(0.8)' };

  return <>
    <div className="petals-display">
      <div style={scale}>
        <Flower formulation={0} numberPetals={getPetalCount('baseline')} color='baseline' />
      </div>
      <div style={scale}>
        <Flower formulation={1} numberPetals={getPetalCount(0)} color='green' />
      </div>
      <div style={scale}>
        <Flower formulation={2} numberPetals={getPetalCount(1)} color='blue' />
      </div>
      <div style={scale}>
        <Flower formulation={3} numberPetals={getPetalCount(2)} color='red' />
      </div>
    </div>
  </>
}
