/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
//////SEPARATE

import Card from 'components/card';
import React, { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import glassPanel from 'assets/images/glass-panel.svg';
import anger from 'assets/emotions/Anger.svg';
import anxiety from 'assets/emotions/Anticipation.svg';
import refreshed from 'assets/emotions/Refreshed.svg';
import tired from 'assets/emotions/Tired.svg';
import joy from 'assets/emotions/Joy.svg';
import sadness from 'assets/emotions/Sadness.svg';
import excitement from 'assets/emotions/Surprise.svg';
import confident from 'assets/emotions/Confident.svg';
import deltaArrow from 'assets/icons/delta-arrow.svg';
import MessageBackground from 'assets/images/message-background.js';
import { MeContext } from 'context/me';
import { isFirefox } from 'utils/browser';
import { getLocaleTime } from 'utils/time';


const Message = ({ date, location, feeling = 'feelingless' }) => {
    if (!date) return <div />;
    const hour = getLocaleTime(new Date(date))
    const locationName = location?.split(',')[0]
    const locationNode =
        locationName &&
            locationName !== 'undefined' ?
            <p>from <b>{locationName}</b></p> : <></>

    return <div className='emotional-thermometer__message-wrapper'>
        <div className='emotional-thermometer__message'>
            <MessageBackground />
            <div className='emotional-thermometer__message-contents' style={{ color: isFirefox ? 'black' : 'white' }}>
                {locationNode}
                <p>{hour.replace(':', '.')}</p>
                <p>{feeling}</p>
            </div>
        </div>
    </div>
}

const Character = ({
    mood,
    intensity,
    date,
    locationName,
}) => {

    let Char;
    let feeling;
    const reference = useRef(null);
    const [signVisible, setSignVisible] = useState(false)

    useEffect(() => {
        const onMouseover = () => {
            setSignVisible(true)
        }

        const onMouseOut = () => {
            setSignVisible(false)
        }

        reference?.current?.addEventListener('mouseout', onMouseOut)
        reference?.current?.addEventListener('mouseover', onMouseover)
        return () => {
            reference?.current?.removeEventListener('mouseover', onMouseover)
            reference?.current?.removeEventListener('mouseout', onMouseOut)
        }
    }, [reference.current]);

    switch (mood) {
        case 0:
            Char = anxiety;
            feeling = 'anxious';
            break;
        case 1:
            Char = tired;
            feeling = 'tired';
            break;
        case 2:
            Char = refreshed;
            feeling = 'refreshed';
            break;
        case 3:
            Char = excitement;
            feeling = 'excited';
            break;
        case 4:
            Char = sadness;
            feeling = 'sad';
            break;
        case 5:
            Char = confident;
            feeling = 'confident';
            break;
        case 6:
            Char = joy;
            feeling = 'joyful';
            break;
        case 7:
            Char = anger;
            feeling = 'angry';
            break;
        default:
            break;
    }

    const opacity = !intensity || intensity?.toFixed(1) == 0.5
        ? 0.75
        : intensity?.toFixed(1) > 0.5
            ? 1
            : 0.4

    return <div style={{ position: 'relative' }}>
        {signVisible && mood != null && <Message intensity={intensity} date={date} location={locationName} feeling={feeling} />}
        <div style={{ opacity }} ref={reference} className='emotional-thermometer__character'>
            <img alt='' className='emotional-thermometer__glass' src={glassPanel} />
            {Char && <div className='emotional-thermometer__sofiji-wrapper'>
                <img alt={feeling} className='emotional-thermometer__sofiji' src={Char} />
            </div>}
        </div>
    </div>
}


function Grid() {
    const { last60 } = useContext(MeContext);
    const grid = useRef(null);
    const [characters, setCharacters] = useState([]);

    useEffect(() => {
        if (!last60) return;
        const quarter_hour = 15 * 60 * 1000;
        const result = [];
        last60.forEach((char, i) => {
            if (i === last60.length - 1) return;
            if (char.date > new Date().getTime()) return;
            if (Math.abs(char.date - last60[i + 1].date) > quarter_hour) {
                result.push({ date: '' })
            }
            result.push(char);
        })
        setCharacters(result);
    }, [last60])

    return <div ref={grid} className='emotional-thermometer__grid'>
        {characters?.map((mood) => {
            return <Character {...mood} />
        })}
    </div>

}

function EmotionalThermometer() {

    return <div className='emotional-thermometer'>
        <div className='emotional-thermometer__header'>
            <h1>treehouse community</h1>
            <EmotionalIntensity />
        </div>
        <Grid />
    </div>

}

const EmotionalIntensity = () => {

    const { yday_intensity, today_intensity } = useContext(MeContext);
    const delta = (today_intensity - yday_intensity).toFixed(4);
    const isDecreasing = delta < 0;
    const deltaArrowStyle = isDecreasing ? { transform: 'rotate(180deg)' } : {};

    return <div className='emotional-intensity'>
        <div className='emotional-intensity__title'>emotional intensity</div>
        <div className='emotional-intensity__value'>{(today_intensity * 100).toFixed(0)}%
            <div className='emotional-intensity__delta'>
                <img style={deltaArrowStyle} src={deltaArrow} alt='increasing' />
                {(delta * 100).toFixed(1)}%</div>
        </div>
    </div>
}

export default Card(EmotionalThermometer, true, '');
