import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function AverageAge() {
	const { data } = useContext(DataContext);
  const { lastActive } = data;


  let totalUsers = 0;
	const avgAge = lastActive.reduce((prev , user) => {
    if (user.age) {
      totalUsers += 1;
      return prev + user.age;
    } else return prev
  },0);

  const avg = (avgAge/totalUsers).toFixed(0);

	return <PlainValue
		className="average-age"
		label="Average age" 
		value={isNaN(avg) ? '--' : avg}
	/>
}

export default AverageAge;
