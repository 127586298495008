import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';
import { DataContext } from 'context/data';


function UsersActiveToday() {

	const { data } = useContext(DataContext);
	const { lastActive } = data;

	const today = new Date().toLocaleDateString('en-US');
	const count = lastActive.reduce((prev, user) => {
		const date = new Date(user.lastActive).toLocaleDateString('en-US', {timeZone: user.timezone});
		return date === today ? prev += 1 : prev;
	}, 0)	

  	return <PlainValue
		className="active-today"
		label="Active today" 
		value={count}
	/>
};

export default UsersActiveToday;