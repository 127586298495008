/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/card/v2";
import Dropdown from "components/dropdown";
import React, { useEffect, useRef, useState } from "react";
import firebase from "firebase";
import styled from "styled-components";
import Loader from 'react-loading';

const STATUS = [
  { name: 'BASELINE_INCOMPLETE' },
  { name: 'BASELINE_HALFWAY' },
  { name: 'BASELINE_COMPLETE' },
  { name: 'WAITING_FOR_SOFI_PEBBLE' },
  { name: 'ADDRESS_VERIFICATION_PENDING' },
  { name: 'ADDRESS_VERIFICATION_FAILED' },
  { name: 'WAITING_FOR_NEXT_PROGRAM' }, 
  { name: 'WAITING_FOR_SOFI_BOX' }, 
  { name: 'POD_ACTIVE' }, 
  { name: 'PODS_ENDING_SOON' }, 
  { name: 'PROGRAM_FINISHED' }, 
  { name: 'WAITING_FOR_REFILL' },
  { name: 'PARTICIPATION_PAUSED' },
  { name: 'PROGRAM_FINISHED_ALT' },
];

const PROGRAMS = [
  { name: 'S1' },
  { name: 'S2' },
  { name: 'S3' },
];

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 20px;
  border-bottom: 1px solid rgba(255,255,255,0.3)
`

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
`;

const Warning = styled.p`
  align-text: center;
  color: white;
  font-family: gilroy-bold;
  font-size: 20px;
`

const UserRow = styled.div`
  display: flex;
  padding: 10px;
  align-items: center;
`;

const ToggleFill = styled.div`
  background: rgba(255,255,255,0.5);
  border-radius: 2px;
  height: 10px;
  width: 10px;
`

const Toggle = styled(ToggleFill)`
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255,255,255,0.5);
  
`

const Title = styled.h3`
  font-family: Gilroy-Bold;
  font-size: 25px;
  line-height: 25px;
  padding-right: 20px;
  color: white;
  margin: 0px;
`

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(255,255,255,0.02);
  border-radius: 15px;
  border: 1px solid rgba(255,255,255,0.1);
  height: 35px;
  margin-right: 10px;
  width: 250px;
  align-self: flex-end;
  padding-left: 15px;
  padding-right: 15px;

  &::focus {
    border: 1px solid rgba(255,255,255,0.2);
  }
`;

const SearchInput = styled.input`
  color: white;
  opacity: 0.8;
  font-family: Gilroy-SemiBold;
  background: transparent;
  outline: none;
  flex: 1;
  border:none;
`;

const Shade = styled.div`
  background: linear-gradient(0deg,rgba(10,16,51,255) 0%,rgba(10,16,51,0) 100%);
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 200px;
  pointer-events: none;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
`

const List = styled.div`
  padding-top: 30px;
  display: flex;
  flex-direction: column;
  overflow: scroll;
  flex:1;
`;

const UserField = styled.div`
  margin-left: 10px;
  font-family: Gilroy-Regular;
  color: white;
  max-width: 400px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

const FullfillmentStatusWrapper = styled.div`
  height: 900px;
  display: flex;
  flex-direction: column;
  padding: 50px 45px;
  padding-bottom: 0px;
`;

const Search = ({ updateQuery, loading, setLoading }) => {

  const [value, setValue] = useState('');
  const timeout = useRef(null);

  const changeHandler = (e) => {
    setLoading(true);
    setValue(e.target.value);
    clearTimeout(timeout.current);
    timeout.current = setTimeout(() => {
      updateQuery(e.target.value);
    }, 2000)
  };

  return <SearchWrapper>
    <SearchInput placeholder="Search for name or email" type='text' value={value} onChange={changeHandler} />
    {loading && <Loader height={20} width={20} type='spin' />}
  </SearchWrapper>
}

const User = ({ 
  name, 
  email, 
  id, 
  setStatus, 
  selected, 
  setSelected,
  batchChange,
  batchLoading,
  ...props
}) => {
  
  const { status, program } = props;

  const [disabled, setDisabled] = useState(false);

  const handleSingleChange = async (newData, field) => {
    try {
      const callable = firebase.functions().httpsCallable('changeUserStatus');
      setDisabled(true);
      const payload = {
        status,
        program,
        [field]: newData,
      };
      await callable({ uid: id, payload });
      setStatus(id, payload);
      setDisabled(false);
    } catch (err) {
      console.log(err);
    }
  }


  const handleChange = async (newData, field) => {
    try {
      if (!selected.length) handleSingleChange(newData, field)
      else batchChange({
        [field]: newData
      })
    } catch (err) {
      console.log(err);
    }
  };


  return <UserRow>
    <Toggle onClick={() => setSelected(id)}>
      {selected.includes(id) && <ToggleFill />}
    </Toggle>
    <UserField><b>{name}</b> - {email}</UserField>
    <div style={{flex: 1, display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end'}}>
      <Dropdown
        options={STATUS}
        selected={STATUS[status]}
        setSelected={(newData) => handleChange(STATUS.indexOf(newData), 'status')}
        disabled={disabled 
          || batchLoading
          || (selected.length > 0 && !selected.includes(id))}
        width={300}
        />

      <div style={{width: 10}} />

      <Dropdown
        options={PROGRAMS}
        selected={{name: program}}
        setSelected={(newData) => handleChange(newData.name, 'program')}
        disabled={disabled 
          || batchLoading
          || (selected.length > 0 && !selected.includes(id))}
        width={50}
        />
        
    </div>
  </UserRow>


}

const FullfillmentStatus = () => {

  const [query, setQuery] = useState('');
  const [skip, setSkip] = useState(0);
  const [selected, setSelected] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [batchLoading, setBatchLoading] = useState(false);

  const _setUsers = (_users, override) => {
    const result = {};
    _users.forEach((user) => result[user.id] = user);
    if (override) {
      setUsers(result);
    } else {
      setUsers((existing) => ({
        ...existing,
        ...result
      }));
    }
  }

  const getUserStatus = async () => {
    const callable = firebase.functions().httpsCallable('getAllUserStatus');
    const result = await callable({ query, skip: 0 });

    const callable2 = firebase.functions().httpsCallable('fetchBrainData');
    console.log(await callable2());

    
    await new Promise((r) => setTimeout(r, 1000));
    setLoading(false);
    setLoadingList(false);
    setSkip(0);
    _setUsers(result.data, true);
  }

  const loadMore = async () => {
    setLoadingMore(true)
    const callable = firebase.functions().httpsCallable('getAllUserStatus');
    const result = await callable({ query, skip });
    await new Promise((r) => setTimeout(r, 500));
    setLoadingMore(false);   
    _setUsers(result.data);
  }

  useEffect(() => {
    getUserStatus();
  }, [query]);

  useEffect(() => {
    if (skip > 0) loadMore();
  }, [skip])

  const updateUsers = (uid, status) => {
    setUsers((s) => ({
      ...s,
      [uid]: {
        ...s[uid],
        ...status
      }
    }))
  };

  const batchUpdateUsers = (payload) => {    
    setUsers((existing) => {
      const changes = {};
      selected.forEach((id) => {
        changes[id] = {
          ...existing[id],
          ...payload
        }
      })

      return {
        ...existing,
        ...changes
      }
    })
  };

  const batchChange = async (payload) => {
    setBatchLoading(true);
    
    if (window.confirm( `Do you want to update these ${selected.length} users?`)) {
      const callable = firebase.functions().httpsCallable('batchChangeUserStatus');
      await callable({ users: selected, payload });
      batchUpdateUsers(payload);
    }

    setBatchLoading(false);
  }

  const _setSelected = (userId) => {
    setSelected((existing) => {
      if (existing.includes(userId)) return existing.filter(v => v !== userId);
      else return [...existing, userId];
    })
  }

  const sharedProps = { 
    setStatus: updateUsers,
    selected,
    setSelected: _setSelected,
    batchChange: batchChange,
    batchLoading,
  }

  const handleScroll = (e) => {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom) {
      setSkip((_skip) => _skip + 20);
    }
  }

  return <Card style={{position: 'relative'}} gridArea="fullfilment-status">
    <FullfillmentStatusWrapper>
      <Row>
        <Title>User status</Title>
        <Search 
          setLoading={setLoading} 
          loading={loading} 
          updateQuery={setQuery} 
        />
      </Row>
      
      {loadingList && <EmptyWrapper>
        <Loader height={80} width={80} type='cylon' />
       </EmptyWrapper>}
      
      {(!loadingList && !!Object.values(users).length) && 
      <List
        onScroll={handleScroll}
      >
       {Object.values(users).map((u, i) => 
          <User {...sharedProps} {...u} key={i} />
        )}
      </List>}

      {!Object.values(users).length && <EmptyWrapper>
        <Warning>No results were found</Warning>
      </EmptyWrapper>}

    </FullfillmentStatusWrapper>
    <Shade>
      {loadingMore && <Loader height={30} width={30} type='spin' />}
    </Shade>
  </Card>


}

export default FullfillmentStatus;