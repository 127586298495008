export function color(number, propName = 'backgroundColor') {
  let color;
	switch (number) {
		case 1:
			color = '#47C479';
      break;
		case 2:
			color = '#2289FE';
      break;
		case 3:
			color = '#FF1B60';
      break;
		default: 
			color = 'gray';
			break;
	}

  const returnValue = {};
  returnValue[propName] = color;
  return returnValue;
}



