/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function MostActiveAge() {

	const { data } = useContext(DataContext);
	const { mostActive } = data;

	const age = mostActive.reduce((prev, next) => {
		return prev + next.age
	}, 0) / mostActive.length

    
	return <PlainValue
		className="average-age-most-active"
		label="Average age" 
		value={age.toFixed(0)}
	/>
}

export default MostActiveAge;
