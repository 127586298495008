export const ADMIN_ENDPOINT = '/streamAdminData';
export const BASE_URL = process.env.NODE_ENV === 'development' ? 
'http://0.0.0.0:5001' : 'https://api.sofi.health';

/*
export const PROJECT_URL =  process.env.REACT_APP_DEVELOPMENT ?
    '/sofi-326000/us-central1':
    '/sofi-326000/europe-west2';
*/

export const PROJECT_URL = '/sofi-326000/us-central1';