export function getYesterday() {
	return new Date((new Date()).valueOf() - 1000*60*60*24);
}

const processLocaleTime = (time, locale, timeZone ) => {
	const str = time.toLocaleTimeString(locale, { timeZone }).split(' ');
    const hour = str.shift().split(':').slice(0, 2).join(':');
    let marker = str.shift();
	marker = marker ? marker : '';
	return hour + ' ' + marker;
}

export const getLocaleTime = (time, timeZone) => {
	try {
		if (!time?.toLocaleTimeString) return '---';
		const locale = navigator.language || navigator.userLanguage; 
		return processLocaleTime(time, locale, timeZone);
	} catch (err) {
		return processLocaleTime(time, 'en-GB', timeZone);
	}
}

export const getHour = (date) => {
    date = date?.toDate ? date.toDate() : date;
    let minutes = date?.getMinutes();
    let hours = date?.getHours();
    minutes = minutes?.toString().padStart('2', 0);
    hours = hours?.toString().padStart('2', 0);
    return hours ? hours + ':' + minutes : null;
}

export const changeFromUTCToLocal = (data) => {
    if (!data) return;
    var offset = -new Date().getTimezoneOffset() / 60;
    const mapped = Object.keys(data).reduce((prev, key) => {
      let hour = Number(key) + offset;
      if (hour < 0) hour = 24 + hour;
      prev[hour] = data[key]
      return prev;
    }, {});
    return mapped;
  }

export function getNiceYesterday() {
	const hour = new Date((new Date()).valueOf() - 1000*60*60*24)
	hour.setHours(hour.getHours());
	hour.setMilliseconds(0);
	hour.setMinutes(0);
	hour.setSeconds(0);
	return hour
}

export function getNiceNow() {
	const hour = new Date((new Date()));
	hour.setHours(hour.getHours());
	hour.setMilliseconds(0);
	hour.setMinutes(0);
	hour.setSeconds(0);
	return hour
}

function addMinutes(date, step = 10) {
	return new Date(date.getTime() + step * 60000);
}

function addHours(date, step = 1) {
	return new Date(date.getTime() + step * 60000 * 60);
}

const withPad = v => v ? v?.toString().padStart(2, '0') : null;

export function getCleanHours(date) {
	if (!date) return ''
	const hour = withPad(date?.getHours());
	const minute = withPad(date?.getMinutes());
	return hour ? hour + ':' + minute : null;
}

export function getCleanDate(date) {
	const day = withPad(date?.getDate());
	const month = withPad(date?.getMonth() + 1);
	return day ? day + '/' + month : null;
}

const monthNames = ["January", "February", "March", "April", "May", "June",
"July", "August", "September", "October", "November", "December"
];

export function getCleanFullDate(date) {
	const day = withPad(date.getDate());
	const month = date.getMonth();
	const monthName = monthNames[month].substr(0, 3)
	const year = date.getFullYear();
	return day + '. ' + monthName.toLocaleLowerCase() + '. ' + year;
}

export function getMinutesBetweenDates(startDate, stopDate) {
	var dateArray = [];
	var currentDate = startDate;
	while (currentDate <= stopDate) {
			dateArray.push(new Date(currentDate));
			currentDate = addMinutes(currentDate);
	}
	return dateArray;
}

export function getHoursBetweenInterval(start, end) {
	var dateArray = [];
	var currentDate = start;
	while (currentDate <= end) {
		dateArray.push(new Date(currentDate));
		currentDate = addHours(currentDate);
	}
	return dateArray;
}

export function getDayOffset(date) {
	const now = new Date().getTime();
	const day = 24 * 60 * 60 * 1000;
	return date ? Math.abs(now - date?.getTime())/day : null;
}


export function isToday(someDate){
	if (!someDate?.getDate) return false;
    const today = new Date();
    return someDate.getDate() === today.getDate() &&
      someDate.getMonth() === today.getMonth() &&
      someDate.getFullYear() === today.getFullYear()
}

export function isSameDay(dayA, dayB) {
	if (!dayA?.getDate || !dayB?.getDate) return false;
	return dayA.getDate() === dayB.getDate() 
		&& dayA.getMonth() === dayB.getMonth()
		&& dayA.getYear() === dayB.getYear();
}

