/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
//////SEPARATE

import Card from 'components/card';
import React, { useContext, useState } from 'react';
import ladySofi from 'assets/images/lady-sofi.svg';
import bgSuccess from 'assets/images/bg-result-success.svg';
import bgWaiting from 'assets/images/bg-result-waiting.svg';
import positiveIcon from 'assets/icons/happy.svg';
import negativeIcon from 'assets/icons/sad.svg';
import triangle from 'assets/icons/delta-arrow.svg';
import './style.scss';
import { DataContext } from 'context/data';
import { getSleepDelay, getMoodDelay, getMoodResults, getSleepHoursResult, getSleepQualityResult } from './result';
import { MeContext } from 'context/me';

function TopIcon({index, selected, setSelected, children}) {
    const style = (index) => index === selected ? '' : 'unselected';

    return <h1 
        onClick={() => setSelected(index)} 
        className={style(index)}>{children}</h1>
}

function Contents({selected, headlineA, headlineB, description, index}) {

    const style = (index) => index === selected ? '' : 'unselected';
    return <div className={'my-result__contents ' + style(index)}>
        <div className='my-result__headline'>
        <h2>{headlineA}</h2>
        {headlineB && <h2>{headlineB}</h2>}
        </div>
        <div className='my-result__description'>
            <img 
                className='my-result__lady-sofi' 
                src={ladySofi} 
                alt='lady sofi'/>
            <p>{description}</p>
        </div>
        <img alt='' className='my-result__background' src={headlineA ? bgSuccess : bgWaiting} />
    </div>

}


function MoodContents({selected, positive, negative, description, index}) {

    const style = (index) => index === selected ? '' : 'unselected';
    return <div className={'my-result__contents ' + style(index)}>
        {positive !== null && <div className='my-result__mood-headline'>
            <div>
                <img src={positiveIcon} alt='positive feelings'/>
                <img src={triangle} 
                    style={{transform: positive < 0 ? 'rotate(180deg)' : 'rotate(0deg)'}} 
                    className='my-result__arrow' />
                <h1>{Math.abs(positive) * 100}%</h1>
            </div>
            <div>
                <img src={negativeIcon} alt='negative feelings'/>
                <img src={triangle} 
                    style={{transform: negative < 0 ? 'rotate(180deg)' : 'rotate(0deg)'}} 
                    className='my-result__arrow' />
                <h1>{Math.abs(negative)* 100}%</h1>
            </div>
        </div>}
        <div className='my-result__description'>
            <img 
                className='my-result__lady-sofi' 
                src={ladySofi} 
                alt='lady sofi'/>
            <p>{description}</p>
        </div>
        <img alt='' className='my-result__background' src={positive !== null ? bgSuccess : bgWaiting} />
    </div>

}

const getDifference = (results, param) => {
    const length = results?.length;
    if (!length || length < 2) return null;
    const value = (results[length -1 ][param] - results[length - 2][param]);
    return !value ? null : value.toFixed(1)
    
}

function MyResult({meData, ...props}) {

    const [selected, setSelected] = useState(0);
    const mood = meData?.mood?.length ?  meData?.mood[meData.mood.length - 1] : null;
    const sleep = meData?.mood?.length ?  meData?.sleep[meData.sleep.length - 1] : null;

    if (!mood || !meData.loaded || !sleep) return <div className='my-result__waiting-wrapper'>
        <h1>Not enough data</h1>
    </div>

    const sleep_quality_difference = getDifference(meData.sleep, 'sleep_q_result')
    const sleep_hours_difference = getDifference(meData.sleep, 'sleep_h_result')
    const positive_feelings_difference = getDifference(meData.mood, 'mood_p_result')
    const negative_feelings_difference = getDifference(meData.mood, 'mood_q_result')

    const mood__final_result = getMoodResults(mood?.mood_p_result, mood?.mood_q_result)
    const delay_mood = getMoodDelay(mood?.mood_p_result, mood?.mood_q_result, mood?.max_signif_delay_m)
    const sleep_hours_result = getSleepHoursResult(sleep?.sleep_h_result)
    const delay_sleep_hours = getSleepDelay(sleep?.sleep_h_result, sleep?.max_signif_delay_h)
    const sleep_quality_result = getSleepQualityResult(sleep?.sleep_q_result)
    const delay_sleep_quality = getSleepDelay(sleep?.sleep_q_result, sleep?.max_signif_delay_q)

    const iconProps = {selected, setSelected}

    const sleep_quality_headline = sleep_quality_difference !== null
        ? Math.abs(sleep_quality_difference).toString() + `%  ${sleep_hours_difference > 0 ? ' better' : 'worse'}` : ''
    const sleep_hours_headline =   sleep_hours_difference !== null
        ? sleep_hours_difference.toString() + ' hours' : ''
    
        

return <>
        <div style={{width: '100%', height: '100%'}} className='my-result'>
            <div class='my-result__nav-bar'>
                <TopIcon index={0} {...iconProps}>sleep duration</TopIcon>
                <TopIcon index={1} {...iconProps}>sleep quality</TopIcon>
                <TopIcon index={2} {...iconProps}>mood</TopIcon>
            </div>
            <div style={{width: '100%'}} class='my-result__contents-wrapper'>
                <Contents headlineA={sleep_hours_headline} description={getText(sleep_hours_result, delay_sleep_hours)} index={0} {...iconProps} />
                <Contents headlineA={sleep_quality_headline} description={getText(sleep_quality_result, delay_sleep_quality)}  index={1} {...iconProps} />
                <MoodContents 
                    positive={positive_feelings_difference} 
                    negative={negative_feelings_difference} 
                    description={getText(mood__final_result, delay_mood)} 
                    index={2} {...iconProps} 
                />
            </div>
        </div>
    </>
}

const getText = (result, delay) => {
    return result + (delay ? '; ' + delay : '')
}

export default Card(MyResult, true, '');
