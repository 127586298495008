import React, { useContext, useEffect, useState } from 'react';
import filter_on from 'assets/icons/filter_on.svg';
import filter_off from 'assets/icons/filter_off.svg';
import checkbox_on from 'assets/icons/checkbox_on.svg';
import checkbox_off from 'assets/icons/checkbox_off.svg';
import './style.scss';
import { timeOptions, programOptions, groupOptions } from 'config/constants';
import { DataContext } from 'context/data';


function TabButton({ selectedTab, setSelectedTab, name }) {

    const isSelected = selectedTab === name;
    const classname = `tab-button ${isSelected ? ' selected' : ''}`
    return <button onClick={() => setSelectedTab(name)} className={classname}>
        {name}
    </button>

}

function TabOption({ option, selected, selector, onlyOne }) {
    const { loading } = useContext(DataContext);


    const isSelected = selected.map((s) => s.value).includes(option.value);

    const onClick = () => {
        return selector((prev) => {
            if (prev.length === 1 && isSelected) return prev;

            return isSelected
                ? prev.filter((o) => o.value !== option.value)
                : onlyOne ?
                    [option] :
                    [...prev, option]
        })

    }

    return <div style={loading ? { pointerEvents: 'none', opacity: 0.5 } : {}} onClick={onClick} className='tab-option'>
        <img src={isSelected ? checkbox_on : checkbox_off} className='tab-option__checkbox' alt='toggle' />
        <div className='tab-option__option'>{option?.name}</div>
    </div>
}

function Tab({ options, ...props }) {
    return <div className='tab'>
        {options?.map((option) => <TabOption option={option} {...props} />)}
    </div>
}

export default function FilterModal({
    disabled }) {


    const { timeFrame, setTimeframe, group, setGroup, program, setProgram } = useContext(DataContext);
    const [selectedTab, setSelectedTab] = useState('timeframe')
    const [modalVisible, setModalVisible] = useState(false);

    const filters = [
        {
            name: 'timeframe',
            options: timeOptions,
            selector: setTimeframe,
            selected: timeFrame,
            onlyOne: true,
        },
        {
            name: 'program',
            options: programOptions,
            selector: setProgram,
            selected: program
        },
        {
            name: 'group',
            options: groupOptions,
            selector: setGroup,
            selected: group,
        }
    ]



    const disabledStyle = disabled ? {
        opacity: 0.5,
        pointerEvents: 'none'
    } : {}

    return <div className='filter-modal' style={disabledStyle}>
        <div onClick={() => setModalVisible(true)} className='filter-modal__icon'>
            <img src={modalVisible ? filter_on : filter_off} alt='open filter' />
        </div>
        {modalVisible && <div className='filter-modal__modal'>
            <div className='filter-modal__tabs'>
                {filters.map((f) => <TabButton {...{ selectedTab, setSelectedTab }} {...f} />)}
                <div className='filter-modal__separator' />
                <Tab {...filters.filter((f) => f.name === selectedTab)[0]} />
            </div>
        </div>}
        {modalVisible && <div onClick={() => setModalVisible(false)} className='filter-modal__mask' />}
    </div>
}