/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PlainValue from 'components/plain-value';
import ScssWrapper from 'components/scss-wrapper';
import './style.scss';
import LoadingUI from 'components/loading';
import MyResult from 'components/my-result';
import MyScore from 'components/my-score';
import UserSleepIndicator from 'components/user-sleep-indicator';
import About from 'components/pioneer-data/about';
import MainSurvey from 'components/pioneer-data/main-survey';
import AllSurveys from 'components/pioneer-data/all-surveys';
import HourlyUses from 'components/hourly-uses';
import firebase from 'firebase';
import { useHistory, useLocation } from 'react-router-dom';
import long_arrow from 'assets/icons/long-arrow.svg';


const PlainValueWrapper = (props) => <PlainValue parent="pioneer" {...props} /> 
const Wrapper = (props) => <ScssWrapper parent="pioneer" {...props}/>

function Pioneer() {

  const [data, setData] = useState({});
  const [loaded, setLoaded] = useState(false);
  const history = useHistory()

  let { sprays, journals, user } = data;
  
  const meData = data;
  const location = useLocation();

  user = {
    ...user,
    createdAt: user ? new Date(user?.createdAt) : null,
  }

  const fetchData = async () => {
      try{
          const user = location.pathname.split('/').reverse()[1];
          const func = firebase.functions().httpsCallable('getPioneerData');
          const results = await func(user);
          if (results?.data) {
            results.data = JSON.parse(results.data);
          }
          setData({ ...results?.data, loaded: true });
      } catch (err) {
      }
  }

  useEffect(() => {
    if (!!Object.keys(data).length) setLoaded(true);
  }, [data])

  useEffect(() => {
    if (location.pathname) fetchData();
  }, [location])

  if (!loaded) return <LoadingUI />;

  const getTime = (time) => {
    const str = time.toLocaleTimeString('en-US').split(':');
    return str[0] + ':' + str[1]
  }

  const lastSprayDate = sprays?.length 
  ? getTime(new Date(sprays[0].date))
  : '--'

  const lastJournalDate = journals?.length 
  ? getTime(new Date(journals[0].endDate))
  : '--'

  const mappedJournals = journals?.map((j) => ({
    ...j,
    endDate: new Date(j.endDate)
  }))

  const lastSprayFormulation = sprays?.length 
  ? 'no.' + (sprays[0].formulation + 1).toString().substring(0,1)
  : '--'
  
  return <>
    <div className='pioneer__header'>
      <button onClick={() => history.goBack()}>
        <img src={long_arrow} alt='back' />
        BACK TO ALL PIONEERS
      </button>
      <h1>{user?.name}</h1>
      <div style={{flex: 1}}></div>
    </div>
    <div className="pioneer">
      <PlainValueWrapper 
        className="active-formulation" 
        label="Active Formulation" value={lastSprayFormulation}/>
      <PlainValueWrapper 
        className="total-sprays" 
        label="Total Sprays" value={sprays?.length ? sprays.length : '0'}/>
      <PlainValueWrapper 
        className="last-spray" 
        label="Last Spray" value={lastSprayDate}/>
      <PlainValueWrapper 
        className="last-journal" 
        label="Last Journal" value={lastJournalDate}/>
      <Wrapper className="sleep-quality">
        <UserSleepIndicator journals={mappedJournals}/>
      </Wrapper>
      <Wrapper className="my-result">
        <MyResult meData={meData} />
      </Wrapper>
      <Wrapper className="my-score">
        <MyScore meData={meData}/>
      </Wrapper>
      <Wrapper className="about">
        <About user={user} />
      </Wrapper>
      <Wrapper className="hourly-uses">
        <HourlyUses 
          user={user}
          journals={journals}
          sprays={sprays} />
      </Wrapper>
      <Wrapper className="survey">
        <MainSurvey user={user} />
      </Wrapper> 
    </div>
    <Wrapper parent='me' className="all-surveys">
      <AllSurveys padding={40} journals={journals} />
    </Wrapper>
  </>
}




export default Pioneer;
