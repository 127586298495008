/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import './style.scss';

export default function OneLineInput(props) {
  const {setValue, value, label = 'label', type} = props;
  const [focused, setFocused] = useState(false);
  const legendClass = `one-line-input__legend ${focused || value !== '' ? ' focused' : ''}`;


  return <div className="one-line-input">
    <div className="one-line-input__leading"/>
    <div className="one-line-input__notch">
      <div className={legendClass}>{label}</div>
    </div>
    <div className="one-line-input__trailing"/>
    <div className="one-line-input__input-wrapper">
      <input
        {...props}
        type={type}
        placeholder={!focused ? label : ''}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        value={value}
        style={{opacity: props?.disabled ? 0.65 : 1}}
        onChange={e => setValue(e.target.value)}
      />
    </div>
  </div>
}