import ConfirmButton from 'components/confirm-button';
import OneLineInput from 'components/one-line-input';
import { MessageContext } from 'context/message';
import React, { useState,  useContext } from 'react';
import firebase from 'firebase';
import { useHistory } from 'react-router';
import './style.scss';

const EnterEmail = () => {
  const history = useHistory();
  const { pushMessage } = useContext(MessageContext);
  const [email, setEmail] = useState('');
  const canSubmitEmail = email.length > 5;

  const handleEmailVerification = async () => {
    try {
      pushMessage(`If ${email} is registered you should have received a message with instructions to change your password.`)
      await firebase.auth().sendPasswordResetEmail(email);
      history.push('/sign-in');
    } catch (err) {
      
    }
  }

  return (<>
  	<h1>Please enter your email</h1>
    <OneLineInput label='email' value={email} setValue={setEmail} />
    <ConfirmButton label="continue" onClick={handleEmailVerification} disabled={!canSubmitEmail} />
  </>)
};


export default function ForgotPassword() {
	return <div className='forgot-password'>
    <EnterEmail />
	</div>

}