import React from 'react';
import Loading from 'react-loading';
import './style.scss';

export default function ConfirmButton(props) {

  const {onClick, disabled,  label, loading} = props;
  const className = `confirm-button ${disabled ? 'disabled' : ''}`;

  return <button {...props} className={className} onClick={onClick} disabled={disabled}>
    {label}
    {loading && <div style={{marginLeft: 10}}>
      <Loading height={20} width={20} color='white' type='spin' />
    </div>}
  </button>

}