/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import './style.scss';
import Card from 'components/card';
import Table from 'graphs/table';
import { color as formulationColor } from 'utils/formulation';
import PioneerLink from 'components/pioneer-link';
import { DataContext } from 'context/data';


function MostActive(props) {

  const { data } = useContext(DataContext)
  const { mostActive } = data

  let formatted_users = mostActive.slice(0, 5).map((user) => {
    return {
      name: user.name,
      age: user.age,
      formulation: user.currentFormulation ?
        user.currentFormulation + 1 : 'baseline',
      location: user.locationName,
      id: user.id
    }
  });

  
  const columnTitles = [
    "name",
    "age",
    "formulation",
    "location"
  ];

  const renderRows = {
      name: (data, id) => <PioneerLink data={{name: data, id: id}} />,
      age: (data) => data + ' years',
      formulation: (data) => <>
        <div className="formula-dot" style={formulationColor(data)}/>
        {data}
      </>
  }

  return <div className='most-active' style={{...props, paddingTop: 70}}>
      <Table columnTitles={columnTitles} rows={formatted_users} renderRows={renderRows}/>
  </div>
}

export default Card(MostActive, false, 'The Most Active Pioneers');