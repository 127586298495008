/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useContext, useEffect, useState } from "react";
import firebase from 'firebase';
import { UserContext } from "./user";
import { useHistory, useLocation } from "react-router-dom";

export const MeContext = createContext();

export default function MeContextWrapper({children}) {

    const [loaded, setLoaded] = useState(false);
    const [loading, setLoading] = useState(false);
    const [scienceData, setScienceData] = useState(null);
    const [treehouseData, setTreehouseData] = useState(false);
    const [data, setData] = useState({});
    const { user } = useContext(UserContext);
    const history = useHistory();
    const location = useLocation();
    const pathname = history.location.pathname;
    const db = firebase.firestore();

    const setCacheData = async (data, path='ME_DATA') => {
        await sessionStorage.setItem(path, JSON.stringify(data));
    }

    const getCachedData = async (path='ME_DATA') => {
        const string = await sessionStorage.getItem(path);
        return JSON.parse(string);
    }

    const fetchTreehouseData = async () => {
        try{
            const existing = await getCachedData('TREEHOUSE_DATA');
            if (existing) return setTreehouseData(existing);
            const func = firebase.functions().httpsCallable('getTreehouseData');
            const results = await func();
            setCacheData(results.data, 'TREEHOUSE_DATA');
            setTreehouseData(results.data);
        } catch (err) {
        }
    }

    const fetchScienceData = async () => {
        try {
          const func = firebase.functions().httpsCallable('getScienceData');
          const results = await func();
          setScienceData(results?.data);
        } catch (err) {
          console.log(err);
        }
      }
    

    const fetchMeData = async () => {
        try{
            const func = firebase.functions().httpsCallable('getMeData');
            const results = await func();
            const parsed = JSON.parse(results?.data);
            parsed['journals'] = parsed['journals'].filter((j) => !!j.endDate);
            return parsed;
        } catch (err) {
        }
    }

    const fetchBrainData = async () => {
        try{
            const func = firebase.functions().httpsCallable('fetchBrainData');
            const results = await func();
            return results?.data;
        } catch (err) {
        }
    }


    const fetchUser = async () => {
        const this_user = await db.collection('users').doc(user.uid).get();
        
        return {user: {
            ...this_user.data(),
            uid: user.uid,
        }};
    }


    const loadUserData = async () => {
        if (!user) return;

        const functions =  [
            fetchUser,
            fetchMeData,
        ]

        const promises = functions.map(async (f, i) => {
            await setTimeout((r) => r, i * 200);
            const r = await f();
            return r;
        });

        let results = await Promise.all(promises);

        
        results = results.reduce((prev, next) => {
            prev = {...prev, ...next}
            return prev;
        }, {})

        
        setData(results);
        setLoaded(true);
        setLoading(false);
    }

    useEffect(() => {
        if (user.loaded 
            && user.uid 
            && !loading
            && pathname.includes('/me')) {
                setLoading(true)
                loadUserData();        
        } else if (user.loaded && !user.uid) {
            setData({})
            setLoaded(false)
            setLoading(false)
        };
    }, [user, location]);


    return <MeContext.Provider value={{
        ...data, 
        loaded, 
        setData, 
        treehouseData,
        fetchTreehouseData,
        fetchScienceData,
        scienceData,
        setLoaded}}>
        {children}
    </MeContext.Provider>


}