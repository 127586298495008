
const answerMapper = (answer, options) => {
    let result = "";
    options.forEach((option, i) => {
        if (answer === i) result = option;
    });
    return result;
}

export const gender = (response) => answerMapper(response, [
    'female',
    'non-binary',
    'male'
]);

export const diet = (response) => answerMapper(response, [
    'vegan',
    'vegetarian',
    'rawist',
    'omnivore',
]);

export const sleeplessness = (response) => answerMapper(response, [
    'less than once a month',
    'about once a month',
    'two or four times a month',
    'many times a week',
    'daily'
]);

export const screenTime = (response) => answerMapper(response, [
    'less than 3 hours',
    '3-5 hours',
    '5-8 hours',
    '8-12 hours',
    'all the time'
]);

export const lookingFor = (response) => answerMapper(response, [
    'better sleep',
    'manage anxiety',
    'unwind',
    'boost energy',
]);

export const podRating = (response) => answerMapper(response, [
    "love it",
    "like it",
    "it's ok",
    "don't like it",
    "can't stand it",
])

export const reuseChance = (response) => answerMapper(response, [
    'for sure',
    'most likely',
    'maybe',
    'probably not',
    'definetely no'
])

export const largeFormulationSurveyEntry = (survey) => {
    
    return {
        name: 'Large Formulation Survey',
        date: survey.date,
        formulation: survey.formulation,
        results: [
            { 
                question: 'I rate my sofi pod...',
                answer: podRating(survey.rating)
            },
            {
                question: 'has the taste changed over the past week?',
                answer: survey.tasteChange,
            },
            {
                question: 'I would improve the experience of my sofi pod by...',
                answer: survey.suggestion
            },
            {
                question: 'am I going to use this sofi pod again?',
                answer: reuseChance(survey.reuseChance)
            },
            {
                question: 'my thoughts on sofi overall...',
                answer: survey.generalThoughts
            },
            {
                question: 'I feel better for using this sofi pod...',
                answer: survey.didImprove ? 'yes' : 'no'
            }
        ]
	}
};

export const formulationSurveyEntry = (survey) => {
    return {
        name: 'Short Formulation Survey',
        date: survey.date,
        formulation: survey.formulation,
        results: [
            { 
                question: survey.isFirstReaction 
                ? 'what was my first reaction when I initially sprayed the sofi pod?'
                : 'how would I describe the flavour of the sofi pod now?',
                answer: survey.reaction
            },
            { 
                question: 'what did I like about the taste?',
                answer: survey.liked
            },
            { 
                question: 'what didn’t I like or thought could be improved?',
                answer: survey.disliked
            },
            { 
                question: 'how would I evaluate this sofi pod based on taste, texture and smell?',
                answer: podRating(survey.ratingTaste),
            },
        ]
	}
}

export const freeFormEntry = (survey) => {
    return {
        name: 'Free Form',
        date: survey.date,
        formulation: survey.formulation,
        results: [
            { 
                question: 'An entry about my day',
                answer: survey.value
            },
        ]
	}
}

export const emotionalSliderEntry = (survey) => {
    return {
        name: 'Emotional Slider Entry',
        date: survey.date,
        results: survey.values,
        isEmotionalSliderEntry: true,
        formulation: survey.formulation,
	}
}

export const shortSurveyEntry = (survey) => {
    const result =  {
        name: 'Short Survey',
        date: survey.date,
        formulation: survey.formulation,
        results: [
            { 
                question: 'on how I feel now...',
                answer: survey.feelingNow
            },
            { 
                question: 'on sleep in the last week...',
                answer: survey.sleepLastWeek
            },
            { 
                question: 'on how I felt over the last week...',
                answer: survey.feelingLastWeek
            },
        ]
	}

    if (survey.hasChanged !== null && survey.hasChanged !== undefined) result.results.push({ 
        question: 'do I still feel the same or has anything changed?',
        answer: survey.hasChanged === true ? 'something new' : 'nothing has changed'
    })

    return result;
}
