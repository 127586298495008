/* eslint-disable react-hooks/exhaustive-deps */
import LoadingUI from 'components/loading';
import { DataContext } from 'context/data';
import { MeContext } from 'context/me';
import { useContext, useEffect, useState, useRef }  from 'react'
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';


export const useVisible = (componentRef, extraOffset = 500) => {

	const [ visible, setVisible ] = useState(false);

	const onScroll = () => {
		const offset = window.pageYOffset;
		const y= componentRef.current.getBoundingClientRect().y;
		if (offset + extraOffset > y) setVisible(true);
	};
	
	const isVisibleFromStart = () => {
		const y= componentRef.current.getBoundingClientRect().y;
		if (y < window.innerHeight) setVisible(true);
		return y < window.innerHeight;
	}

	useEffect(() => {
		if (!componentRef.current) return;
		if (isVisibleFromStart()) return;
		onScroll();
		window.addEventListener('scroll', onScroll)
		return () => window.removeEventListener('scroll', onScroll);
	}, [componentRef.current])

	useEffect(() => {
		if (visible) window.removeEventListener('scroll', onScroll);
	}, [visible])
  
	return visible;
}

export const useWrapperSize = (reference) => {
	let _height = useRef(0)
  	let _width = useRef(0)

	useEffect(() => {
		if (!reference.current) return;

		const listener = () => {
		_height.current = reference.current?.getBoundingClientRect().height;
		_width.current = reference.current?.getBoundingClientRect().width;
		}
		window.addEventListener('resize', listener)
		listener();
		return () => window.removeEventListener('resize', listener)
	}, [reference.current])

	let height = _height.current;
	let width = _width.current;

	return { height, width }

}

export const useLoading = () => {

	const {loaded} = useContext(DataContext);
	const me = useContext(MeContext);
	const meLoaded = me.loaded;
	
	const history = useHistory();
	const pathname = history.location.pathname;

	const did_load = pathname.includes('/me') ? meLoaded : loaded

	if (!did_load) return <LoadingUI />;
}

export const useScrollToTop = () => {
	const { pathname } = useLocation();
  
	useEffect(() => {
	  window.scrollTo(0, 0);
	}, [pathname]);
  
	return null;
  }