/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useContext, useEffect } from 'react';
import './style.scss';
import LoadingUI from 'components/loading';
import SurveyCard from 'components/survey-card';
import PaperCard from 'components/paper-card';
import { MeContext } from 'context/me';

function Science() {

  const {
    loaded, 
    setLoaded, 
    scienceData,
    fetchScienceData } = useContext(MeContext);

  const [surveys, setSurveys] = useState([]);
  const [papers, setPapers] = useState([]);

  const loadData = async () => {
    setLoaded(false);
    await fetchScienceData();
    setLoaded(true)
  }

  const parseUIComponents = (component) => {
    return scienceData
    .UIContents
    .filter((f) => f.component === component)
    .map(({texts, ref}) => ({
      name: texts[0],
      description: texts[1],
      url: texts[2],
      filled: scienceData.userSurveys.some((d) => d?.type === ref)
    }))
  }

  
  useEffect(() => {
    loadData();
  },[])

  useEffect(() => {
    if (scienceData) {
      setSurveys(parseUIComponents('survey'));
      setPapers(parseUIComponents('paper'));
    }
  }, [scienceData])

  if (!loaded || !scienceData) return <LoadingUI />;

  return <div className="science">
      <h1>surveys</h1>
      <div className='science__cards'>
        {surveys.map((surveys, i) => <SurveyCard key={i} {...surveys} />)}
      </div>
      <h1>papers</h1>
      <div className='science__cards'>
        {papers.map((surveys, i) => <PaperCard key={i} {...surveys} />)}
      </div>
  </div>
  
}

export default Science;
