/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react';
import Card from 'components/card';
import { color as formulationColor } from 'utils/formulation';
import './style.scss';

import like from 'assets/likes/likes.svg';
import dislike from 'assets/likes/dislike.svg';
import maybe from 'assets/likes/maybe.svg';
import nofilter from 'assets/likes/nofilter.svg';

import likeUnselected from 'assets/likes/likes-unselected.svg';
import dislikeUnselected from 'assets/likes/dislike-unselected.svg';
import maybeUnselected from 'assets/likes/maybe-unselected.svg';
import nofilterUnselected from 'assets/likes/nofilter-unselected.svg';
import PioneerLink from 'components/pioneer-link';
import { DataContext } from 'context/data';


const images = {
  like: {
    selected: like,
    unselected: likeUnselected
  },
  dislike: {
    selected: dislike,
    unselected: dislikeUnselected
  },
  nofilter: {
    selected: nofilter,
    unselected: nofilterUnselected
  },
  maybe: {
    selected: maybe,
    unselected: maybeUnselected
  },
}

const TableItem = ({ filter, name, rating, id }) => {
  
  return <div className="currently-in-use-table__link-wrapper">
    <PioneerLink
      style={{ opacity: filter?.length && !filter?.includes(rating) ? 0.3 : 1 }}
      data={{ name, id }} />
  </div>
}

const FormulationTable = ({ data, formulation, filter = [] }) => {
  const filtered = filter.length ? data.filter((user) => filter.includes(user.rating)) : data;
  const column_users = filtered.filter((user) => user?.formulation === formulation);
  const percentage = ((column_users.length / filtered.length) * 100).toFixed(1);
  const tableName = !isNaN(formulation) ? `no. ${formulation + 1}` : 'baseline';
  
  return <div className="formulation-table">
    <div className="formulation-table__header">
      <div className="formulation-table__dot" style={formulationColor(formulation + 1)} />
      {!isNaN(percentage) ? `${tableName} · ${percentage}%` : 'No users'}
    </div>
    <div className="formulation-table__inner">
      {column_users.map((user) => <TableItem filter={filter} {...user} />)}
    </div>
  </div>

}

const FilterButton = ({
  values,
  img,
  title,
  filter,
  setFilter }) => {

  const isSelected = !values?.length ? !filter?.length : filter?.includes(values[0])

  return <button
    onClick={() => setFilter(values)}
    className={`filter-button ${isSelected ? 'selected' : ''}`}>
    <img src={images[img][isSelected ? 'selected' : 'unselected']} alt="filter" />
    <div className="filter-button__tag-wrapper">
      <div className="filter-button__tag">{title}</div>
    </div>
  </button>
}

const Header = (props) => {
  return <div className="currently-in-use-table__header">
    <h1>currently in use</h1>
    <div className="currently-in-use-table__filters">
      <FilterButton img="nofilter" values={[]} title="all users" {...props} />
      <FilterButton img="like" values={[4]} title="like it" {...props} />
      <FilterButton img="maybe" values={[1, 2, 3]} title="neutral" {...props} />
      <FilterButton img="dislike" values={[0]} title="dislike" {...props} />
    </div>
  </div>
}

function CurrentlyInUseTable(props) {

  const { data } = useContext(DataContext)
  const [filter, setFilter] = useState([]);
  const { lastActive, sprays, journals } = data;

  const users = lastActive?.reduce((prev, user) => {
    const userSprays = sprays.filter((spray) =>
      spray.user === user.id
    );

    userSprays.sort((a, b) => b.date.getTime() - a.date.getTime());
    const lastSpray = userSprays?.length ? userSprays[0] : null

    const formulationJournals = journals.filter((journal) => (
      [4, 5].includes(journal.type)
      && journal.user === user.id
      && journal.formulation === lastSpray?.formulation
    ))
    formulationJournals.sort((a, b) => b.endDate.getTime() - a.endDate.getTime())
    const formulationJournal = formulationJournals.length ? formulationJournals[0] : null;

    return [...prev, {
      name: user.name,
      id: user.id,
      formulation: lastSpray?.formulation,
      rating: formulationJournal?.ratingTaste
        ? formulationJournal.ratingTaste
        : formulationJournal?.rating
          ? formulationJournal.rating
          : 2
    }]
  }, [])

  const params = {
    data: users.filter((u) => !!u.name),
    filter,
    setFilter
  }

  return <div className='currently-in-use-table__wrapper' style={{ display: 'flex', flexDirection: 'column' }}>
    <Header {...params} />
    <div className="currently-in-use-table" >
      <FormulationTable
        backgroundColor="rgba(71, 196, 121, 1)"
        formulation={0}
        {...params}
      />
      <FormulationTable
        backgroundColor="rgba(34, 137, 254, 1)"
        formulation={1}
        {...params}
      />
      <FormulationTable
        backgroundColor="rgba(255, 27, 96, 1)"
        formulation={2}
        {...params}

      />
      <FormulationTable
        backgroundColor="gray"
        formulation={undefined}
        {...params}

      />
    </div>
  </div>
}

export default Card(CurrentlyInUseTable, true, 'Currently In Use Table');