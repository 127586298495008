import Portal from 'components/portal';
import React, { useEffect } from 'react';
import './style.scss';

const modalWrapper = document.getElementById('modal-wrapper');
const defaultPrompt = "are you sure you want to remove user? this action cannot be undone. ";

function DefaultMessage({prompt, onConfirm, onDecline}) {
	return <div className="default-message">
		<h4>{prompt}</h4>
		<div className="default-message__yes">
			<button onClick={onConfirm} className="default-message__button">yes</button>
		</div>
		<div className="default-message__no">
			<button onClick={onDecline} className="default-message__button">no</button>
		</div>
	</div>
}

export default function MessageModal({
	onConfirm = () => {},
	prompt = defaultPrompt,
	visible, 
	setVisible, 
	children
}){

	useEffect(() => {
		if (visible) document.body.style.overflow = 'hidden';
		else document.body.style.overflow = 'scroll';
	}, [visible])

  return <Portal wrapper={modalWrapper}>
		{visible && <div className="message-modal">
			<div onClick={() => setVisible(false)} className='message-modal__background' />
			<div className="message-modal__contents">
				{children ? children : 
				<DefaultMessage 
					prompt={prompt} 
					onDecline={() => setVisible(false)} 
					onConfirm={onConfirm} />}
			</div>
			</div>}
	</Portal>
};
