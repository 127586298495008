import axios from 'axios';
import { queryBuilder } from 'utils/query';
import { ADMIN_ENDPOINT, BASE_URL, PROJECT_URL } from './config';
import firebase from 'firebase';
const baseUrl = BASE_URL + PROJECT_URL;


export const getAdminData = async (startDate, program, group) => {
    
    const token = await firebase.auth().currentUser.getIdToken();
    
    axios.defaults.headers = {
        'authorization': 'Bearer ' + token,
    };

    const query = {
        startDate,
        program,
        group
    }
    
    const response = await axios.get(baseUrl + ADMIN_ENDPOINT + queryBuilder(query));

    return {
        ...response.data,
        ...response.data.counts,
    }
}

export const getZipData = async (param, user) => {
    
    const token = await firebase.auth().currentUser.getIdToken();
    axios.defaults.headers = {
        'authorization': 'Bearer ' + token,
    };

    const query = {
        startDate: new Date(2020,1, 1,1),
        zip: true,
        param,
    }

    if (param === 'user') query.user = user;
    
    const result = await axios.get(baseUrl + ADMIN_ENDPOINT + queryBuilder(query));
    return result;
}