import React, { useState } from 'react';
import LoadingUI from 'components/loading';
import { useLoading } from 'utils/hooks';
import styled from 'styled-components';
import RefillToggle from './refill-toggle';
import FullfillmentStatus from './fulfillment-status';
import Card from "components/card/v2";
import Status from './status';

const Base = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  margin-right: 80px;
`;

const Button = styled.button`
  color: white;
  background: transparent;
  flex: 1;
  border-radius: 20px;
  border: none;
  cursor: pointer;           
  font-family: Gilroy-Regular;
`;

const Selected = styled(Button)`
  background: rgba(255,255,255,0.2);
`;

const ToggleWrapper = styled(Card)`
  display: flex;
  min-height: initial;
  height: 40px;
  margin-bottom: 20px;
`;

const Toggle = ({ selected, setSelected }) => {

  const opts = ['Status V1', 'Status V2'];

  return <ToggleWrapper>
    {opts.map((option) => (selected === option) ? <Selected onClick={() => setSelected(option)}>{option}</Selected>
      : <Button onClick={() => setSelected(option)}>{option}</Button>
    )}
  </ToggleWrapper>


}

function Fulfillment() {

  const [selectedTab, setSelectedTab] = useState('Status V2');

  if (useLoading()) return <LoadingUI />;
  
  return (<>
    <Base>
      <Toggle selected={selectedTab} setSelected={setSelectedTab} />
      {selectedTab === 'Status V1' ? 
      <>
        <RefillToggle />
        <FullfillmentStatus />
      </>:
      <Status />}
      
    </Base>
  </>)
}

export default Fulfillment;
