import React from 'react';
import './style.scss';
import styled from 'styled-components';
import Border from 'components/border';

const Wrapper = styled.div`
  position: relative;  
  height: min-content;
  border-radius: 16px;
  min-height: 100px;
  backdrop-filter: blur(4px);
  background: linear-gradient(
    0deg, rgba(255,255,255,0.02) 
    0%, rgba(255,255,255,0.1) 100%
  );  
`;

const Card = ({children, gridArea, style = {}, ...props}) => {
  return <Wrapper {...props} style={{gridArea, ...style}}>
    <Border />
    {children}
  </Wrapper>
} 

export default Card
