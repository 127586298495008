import { useEffect, useState } from 'react';

export default function useSize(reference) {

    const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    useEffect(() => {
        if (!reference?.current) return;
        const onResize = () => {
            setHeight(reference?.current?.clientHeight)
            setWidth(reference?.current?.clientWidth)
        }
        onResize();
        window.addEventListener('resize', onResize);
        return () => window.removeEventListener('resize', onResize);
    }, [reference])


    return {
        height, width
    }
}