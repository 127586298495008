import React from 'react';
import ScssWrapper from 'components/scss-wrapper';
import CurrentlyInUse from 'components/currently-in-use';
import AverageSprayTime from 'components/average-spray-time';
import CurrentlyInUseTable from 'pages/formulations/currently-in-use-table'
import TotalSprays from 'components/plain-value/components/total-sprays';
import SpraysToday from 'components/plain-value/components/sprays-today';
import AvgSpraysPerDay from 'components/plain-value/components/avg-sprays-day';
import BottleDuration from 'components/plain-value/components/bottle-duration';
import LoadingUI from 'components/loading';
import { useLoading } from 'utils/hooks';
import './style.scss';


const PlainValueWrapper = ({ Component }) => <Component parent="dashboard" /> 
const Wrapper = (props) => <ScssWrapper parent="formulations" {...props}/>


function Formulations() {
  const loader = useLoading();
  if (loader) return <LoadingUI />;
  
  
  return (<>
    <div className="formulations">
      <Wrapper className="average-spray-time">
        <AverageSprayTime />
      </Wrapper>
      <Wrapper className="currently-in-use">
        <CurrentlyInUse />
      </Wrapper>
      <Wrapper className="currently-in-use-table">
        <CurrentlyInUseTable />
      </Wrapper>
      
      <PlainValueWrapper
        Component={TotalSprays} />
      <PlainValueWrapper 
        Component={SpraysToday} />
      <PlainValueWrapper
        Component={AvgSpraysPerDay} />
      <PlainValueWrapper 
        Component={BottleDuration}/>
    </div>
  </>)
}

export default Formulations;
