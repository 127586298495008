import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';

import { DataContext } from 'context/data';

function TotalJournals() {

	const { data } = useContext(DataContext);
	const { journals } = data;
    
	return <PlainValue
		className="total-journals"
		label="Journals on interval" 
		value={journals.length}
	/>

}

export default TotalJournals;


