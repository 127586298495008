/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from 'react';
import './style.scss';
import deltaArrow from 'assets/icons/arrow.svg';

const mockOptions = [{
    name: 'option 1'
}, {
    name:'option 2'
}]

export default function Dropdown({
    options = mockOptions, 
    disabled,
    width = 100,
    selected = mockOptions[0], 
    setSelected = () => {},
}) {

    const [dropdownVisible, setDropdownVisible] = useState(false);

    const _setSelected = (option) => {
        setDropdownVisible(false)
        setSelected(option)
    }   

    const disabledStyle = disabled ? {
        opacity: 0.5,
        pointerEvents: 'none'
    } : {}

    return <div className='dropdown' style={disabledStyle}>
        <div onClick={() => setDropdownVisible(true)} style={{width, display: 'flex'}} className='dropdown__box'>
            <div style={{ flex: 1 }}>{selected.name}</div>
            <img 
                src={deltaArrow} 
                style={dropdownVisible ? {transform: 'rotate(180deg)'} : {}}/>
        </div>
        {dropdownVisible && <div style={{width}} className='dropdown__options'>
            {options.map((option) => 
                <div 
                    onClick={() => _setSelected(option)} 
                    className='dropdown__option'>
                        {option.name}
                </div>)}
        </div>}
        {dropdownVisible && <div onClick={() => setDropdownVisible(false)} className='dropdown__mask'/>}
    </div>
}