/* eslint-disable react-hooks/exhaustive-deps */
import Card from 'components/card';
import React from 'react';
import { diet, gender, screenTime, sleeplessness } from 'utils/survey';
import { getLocaleTime } from 'utils/time';
import './style.scss';

function About({ user }) {
  
  const Field = ({field, data}) => (
    <div className="main-pioneer-data__about-field">
    <div className="main-pioneer-data__field-name">{field}</div>
    <div className="main-pioneer-data__field-data">{data ? data : <i style={{opacity: 0.3}}>not set</i>}</div>
    </div>
  )

  return <div className='main-pioneer-data'>
    <h1>about</h1>
    <Field field={'email'} data={user.email}/>
    <Field field={'location'} data={user?.locationName}/>
    <Field field={'local time'} data={getLocaleTime(new Date(), user.timeZone)}/>
    <Field field={'gender'} data={gender(user?.gender)}/>
    <Field field={'age'} data={user?.age}/>
    <Field field={'diet type'} data={diet(user?.survey?.diet)}/>
    <Field field={'sleeplessness'} data={sleeplessness(user?.survey?.difficultyToSleep)}/>
    <Field field={'screen time'} data={screenTime(user?.survey?.screenTime)}/>
  </div>
}

export default Card(About, true, '');
