/* eslint-disable react-hooks/exhaustive-deps */
import Card from 'components/card';
import SleepCircle from 'components/sleep-circle';
import React from 'react';
import { isSameDay } from 'utils/time';
import './style.scss';

function UserSleepIndicator(props) {
  
  const { journals } = props;

  if (!journals?.length) return <div>Journal more to see your Sleep status</div>


  const getJournal = (date) => journals.filter((journal) => {
      if (journal?.type !== 7) return false; 
      const journalDate = journal.endDate.toDate ? journal.endDate.toDate() : journal.endDate;
      return isSameDay(journalDate, date) 
  })

  const averages = (() => {
    
    const totals = journals.reduce((prev, journal) => {
      if (journal?.type !== 7) return prev;
      return {
        quality: prev.quality + journal.quality,
        hours: prev.hours + journal.hours,
        length: prev.length + 1,
      }
    }, { quality: 0, hours: 0, length: 0});
    return {
      quality: totals.quality / totals.length,
      hours: totals.hours / totals.length,
      length: totals.length
    }
  })();

  let twoNightsAgo = new Date(new Date().getTime() - (24 * 60 * 60 * 1000));
  let lastNight = new Date();

  twoNightsAgo = getJournal(twoNightsAgo).pop();
  lastNight = getJournal(lastNight).pop();

  return <div className='user-sleep-indicator' style={{height: '100%', width: '100%'}}>
    {!!twoNightsAgo && <SleepCircle 
      visible={!!twoNightsAgo}
      topText='two nights ago' 
      sleepHours={twoNightsAgo?.hours} 
      sleepQuality={twoNightsAgo?.quality} />}
    {!!lastNight && <SleepCircle 
      visible={!!lastNight}
      topText='last night' 
      sleepHours={lastNight?.hours} 
      sleepQuality={lastNight?.quality} />}
    {averages.length && <SleepCircle
      visible={!!averages}
      topText='average'
      sleepHours={averages.hours}
      sleepQuality={averages.quality}
    />}
  </div>
    
}

export default Card(UserSleepIndicator, true, '');
