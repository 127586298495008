import React, { useContext } from 'react';
import PlainValue from 'components/plain-value';
import ScssWrapper from 'components/scss-wrapper';
import GenderByAge from 'components/gender-by-age';
import MostActive from 'components/most-active';
import AllPioneers from 'pages/pioneers/all-pioneers';
import './style.scss';
import ActivePioneers from 'components/plain-value/components/activated-pioneers';
import UsersActiveToday from 'components/plain-value/components/users-active-today';
import AverageAge from 'components/plain-value/components/average-age';
import MostActiveGender from 'components/plain-value/components/most-active-gender';
import MostActiveAge from 'components/plain-value/components/most-active-age';
import MostActiveSpraysPerDay from 'components/plain-value/components/most-active-avg-sprays-day';
import { DataContext } from 'context/data';
import LoadingUI from 'components/loading';
import { useLoading } from 'utils/hooks';
import MostActiveIntervalJournals from 'components/plain-value/components/most-active-interval-journals';
import TotalJournals from 'components/plain-value/components/total-journals';


const PlainValueWrapper = ({ Component }) => <Component parent="pioneers" /> 

const Wrapper = (props) => <ScssWrapper parent="pioneers" {...props}/>

function Pioneers() {

  if (useLoading()) return <LoadingUI />;
  
  return (<>
    <div className="pioneers">
      <Wrapper className="gender-by-age">
        <GenderByAge />
      </Wrapper>
      <Wrapper className="most-active">
        <MostActive />
      </Wrapper>
      <Wrapper className="all-pioneers">
        <AllPioneers />
      </Wrapper>
      
      <PlainValueWrapper
        Component={ActivePioneers}/>
      <PlainValueWrapper
        Component={UsersActiveToday} />
      <PlainValueWrapper
        Component={AverageAge}/>
      <PlainValueWrapper
        Component={TotalJournals}/>
      
      <div className="pioneers__section-title">Profile of most active pioneers</div>
      
      <PlainValueWrapper
        Component={MostActiveGender} />
      <PlainValueWrapper
        Component={MostActiveAge} />
      <PlainValueWrapper
          Component={MostActiveSpraysPerDay} />
      <PlainValueWrapper
          Component={MostActiveIntervalJournals} />
    </div>
  </>)
}

export default Pioneers;
