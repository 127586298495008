import Card from 'components/card';
import ConfirmButton from 'components/confirm-button';
import sofiFlowers from 'assets/icons/flowers.svg'
import sofiMoon from 'assets/icons/sofi_moon.svg';
import './style.scss';


function Invite(props) {

    const daysToReward = 10;

    const generateLink = () => {
        let id;
        //id= createInvitation();
        return `sofi://CreateAccount?inviteId=${id}`;
    }

    const friends = [
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend', 
            createdAt: new Date().getTime() - 24 * 60 * 60 * 1000,
        },
        {
            name: 'Friend B', 
            createdAt: new Date().getTime()- 24 * 60 * 60 * 1000,
            rewardCollected: true
        },
        {
            name: 'Friend C', 
            createdAt: new Date().getTime() - 10000000000,
            rewardCollected: false
        },
    ]

    const InvitePrompt = () => {
        return <div className='invite__prompt'>
            <div style={{flex: 1}}>
                <img src={sofiMoon} className='invite__moon' alt='' />
            </div>
            <div style={{flex: 2}}>
                <h1>Invite a Friend</h1>
                <h3>and receive £10 in cash for you, and £10 in sofi credit for your friend* </h3>
                <ConfirmButton label='copy invite link'/>
                <p>*your friend needs to be active at least 30 days for you both to receive the reward.</p>
            </div>
        </div>
    }

    const InvitedFriends = () => {

        const mapped_friends = friends.map((friend) => {
            const activeDays = Math.floor((new Date().getTime() - friend.createdAt)/ (24 * 60 * 60 * 1000))
            const redeemReward = <div>redeem reward</div>

            const rewardColumn = friend.rewardCollected 
            ? 'reward collected'
            : activeDays > daysToReward
            ? redeemReward
            : `reward in ${daysToReward - activeDays} days`

            return {
            ...friend,
            activeSince: `${activeDays} ${activeDays === 1 ? ' day' : 'days'} active`,
            rewardColumn
            }
        })
        
        const Friend = ({name, activeSince, rewardColumn}) => (
            <div className='invite__friend'>
                <div className='invite__name'>{name}</div>
                <div className='invite__active'>{activeSince}</div>
                <div className='invite__reward'>{rewardColumn}</div>
            </div>
        )

        return <div className='invite__friends'>
            <h2>friends invited</h2>
            {mapped_friends.map((f) => <Friend {...f} />)}
        </div>
    }
    
    return <div className='invite'>
        <InvitePrompt />
        <InvitedFriends />
        <img    
            style={friends.length > 0 ? {opacity: 0.1} : {}}
            src={sofiFlowers} 
            alt='' 
            className='invite__background' />
    </div>

}

export default Card(Invite, true, '');
